import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import ImportTable from "components/CollapsedTable/ImportTable.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Alert from "@material-ui/lab/Alert";

import { app, baseAPI } from "base.js";
import Heading from "components/Heading/Heading";
import { Link } from "react-router-dom";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function Import(props) {
  const classes = useStyles();

  const [importObj, setImportObj] = useState(null);
  const [importArr, setImportArr] = useState(null);
  const [importCount, setImportCount] = useState(0);

  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = useState(null);

  const {
    firebaseUser,
    defaultBook,
    currentUser,
    history,
    googleAlert,
    hideAlert,
    loadingAlert,
    warningAlert,
    successAlert,
    errorAlert,
  } = props;

  const id = firebaseUser.defaultBook;

  useEffect(() => {
    const recipesRef = app.database().ref(`books/${id}/recipes`);
    const importProgressRef = app.database().ref(`books/${id}/importProgress`);

    if (!defaultBook.importing) {
      setLoading(true);
      setProgress(null);
      importProgressRef.off();

      recipesRef
        .orderByChild("import")
        .equalTo(true)
        .on("value", (snapshot) => {
          setImportCount(snapshot.numChildren());
          let obj = {};

          snapshot.forEach((snapshotChild) => {
            const {
              chapter,
              title,
              name,
              email,
              lastEdited,
            } = snapshotChild.val();

            const chapterName = defaultBook.chapters[chapter].name;

            const recipe = {
              title,
              name,
              email,
              lastEdited,
              key: snapshotChild.key,
            };

            if (obj[chapterName]) {
              obj[chapterName].count++;
              obj[chapterName].recipes.push(recipe);
            } else {
              obj[chapterName] = {
                count: 1,
                recipes: [recipe],
              };
            }
          });

          setImportObj(obj);
        });
    } else {
      recipesRef.off();

      importProgressRef.on("value", (snapshot) => {
        setProgress(snapshot.val());
      });
    }

    return () => {
      recipesRef.off();
      importProgressRef.off();
      hideAlert();
    };
  }, [defaultBook]);

  useEffect(() => {
    if (importObj) {
      const arr = Object.keys(importObj)
        .sort()
        .map((chapter) => {
          const { count, recipes } = importObj[chapter];
          return { chapter, count, recipes };
        });

      setImportArr(arr);
      setLoading(false);
    }
  }, [importObj]);

  const googleImportAlert = () => {
    googleAlert({
      text: "In order to import recipes you must attach your Google Account.",
      successText:
        "Your Google Account has been successfully linked and you can now import recipes!",
      successConfirmText: "Generate flipbook",
      nextFn: startImport,
    });
  };

  const startImport = () => {
    if (!firebaseUser.googleRefresh) {
      googleImportAlert();
      return;
    }

    warningAlert(
      "Are you sure you want to import?",
      "Recipes can only be edited inside the template after import. Press OK to continue.",
      importRecipes
    );
  };

  const importRecipes = async () => {
    loadingAlert();

    const header = new Headers();

    header.append(
      "Authorization",
      `Bearer ${await currentUser.getIdToken(true)}`
    );

    fetch(`${baseAPI}/import/start-import/${firebaseUser.defaultBook}`, {
      headers: header,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          errorAlert(
            "Something went wrong...",
            `Your recipes are NOT being imported.`
          );
        }
      })
      .then((result) => {
        if (result) {
          if (result.google_refresh_error) {
            googleImportAlert();
          } else {
            successAlert(
              "Importing!",
              "Note: Larger imports may take a few minutes."
            );
          }
        }
      })
      .catch((err) => {
        const msg = err.message;
        errorAlert("There was an error...", `Error message: ${msg}`);
      });
  };

  return (
    <div>
      <GridContainer justify="center">
        {defaultBook.importError ? (
          <Alert severity="error">
            Something went wrong importing your recipes. Please try again. If
            you keep seeing this please contact our support team.
          </Alert>
        ) : null}
        <GridItem xs={12} sm={6} md={6}>
          <Heading
            textAlign="center"
            title="Import recipes"
            category={
              <a
                href="javascript:void(0)"
                onClick={() =>
                  window.Beacon("article", "633d15d99f7c1931ee006950", {
                    type: "sidebar",
                  })
                }
              >
                You can import one, a few, or all your recipes at once into your
                template.
              </a>
            }
          />
          {loading ? (
            <Card plain>
              <CardBody>
                <CustomLinearProgress color="info" />
              </CardBody>
            </Card>
          ) : (
            <div>
              <Card
                style={{
                  maxWidth: "20rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "15px",
                }}
              >
                {!defaultBook.importing ? (
                  <CardHeader style={{ textAlign: "center" }}>
                    <h4 className={classes.cardTitle}>
                      {importCount > 0 ? (
                        <>
                          {importCount} recipe
                          {importCount !== 1 ? "s" : ""} pending import
                        </>
                      ) : (
                        "No recipes pending import"
                      )}
                    </h4>
                    {defaultBook.lastImported ? (
                      <p className={classes.cardCategory}>
                        Last imported -{" "}
                        {defaultBook.lastImported.toDate().toLocaleString()}
                      </p>
                    ) : null}
                  </CardHeader>
                ) : null}
                {defaultBook.importing ? (
                  <CardBody style={{ textAlign: "center" }}>
                    <h6>Importing recipes</h6>
                    {progress && progress.count && progress.total ? (
                      <>
                        <h3>
                          {Math.floor((progress.count / progress.total) * 100)}%
                        </h3>
                        <CustomLinearProgress
                          variant="determinate"
                          color="info"
                          value={Math.floor(
                            (progress.count / progress.total) * 100
                          )}
                        />
                      </>
                    ) : (
                      <CustomLinearProgress color="info" />
                    )}

                    <p>{progress && progress.status ? progress.status : ""}</p>
                  </CardBody>
                ) : null}

                <CardFooter style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <Button
                    color="info"
                    size="lg"
                    onClick={
                      !defaultBook.lastImported && importCount === 0
                        ? () => history.push("/recipes/library")
                        : startImport
                    }
                    disabled={
                      (importCount === 0 && defaultBook.lastImported) ||
                      defaultBook.importing ||
                      !!progress
                    }
                  >
                    {defaultBook.lastImported && importCount === 0
                      ? "Finished import"
                      : importCount === 0
                      ? "Select recipes to import"
                      : "Start import"}
                  </Button>
                </CardFooter>
              </Card>
            </div>
          )}
          {importCount === 0 ? (
            <p
              style={{
                margin: "0 auto",
                textAlign: "center",
                maxWidth: "500px",
              }}
            >
              <span>
                To import recipes into your template, go to your{" "}
                <Link to="/recipes/library">Recipe library</Link> and toggle the
                switch to YES for each recipe under the column{" "}
                <i>Ready to import</i>.
              </span>
            </p>
          ) : null}
          <div style={{ marginTop: "2rem" }}>
            <Heading
              textAlign="center"
              title="Need help with first stage layout?"
              category={
                <div>
                  <p>
                    Our team can do the placing of recipes and extra pages as
                    requested ready for you to the final touches.
                  </p>
                  <p>
                    <b>
                      <Link>How do my placing works</Link>
                    </b>
                  </p>
                  <p>
                    <b>Community – Free</b>
                    <br />
                    <b>Premium – $39 USD</b>
                    <br />
                    <b>Starter – $59 USD</b>
                    <br />
                  </p>

                  <Button
                    color="info"
                    onClick={() => {
                      window.open(
                        "https://www.createcookbooks.com/domycookbook",
                        "_blank"
                      );
                      //history.push("/do-my-cookbook")
                    }}
                  >
                    Request do my placing
                  </Button>
                </div>
              }
            />
          </div>
        </GridItem>
        {!defaultBook.importing &&
        !loading &&
        importArr &&
        importArr.length > 0 ? (
          <GridItem xs={10} sm={5} md={5}>
            <Heading
              textAlign="center"
              title="Recipes ready for import"
              category="A count of all your recipes that have been flagged as 'Ready for Import' in your recipe library"
            />
            <ImportTable rows={importArr} />
          </GridItem>
        ) : null}
      </GridContainer>
    </div>
  );
}
