import React, { useEffect, useState } from "react";

// @material-ui/core components
import { lighten, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import LaunchIcon from "@material-ui/icons/Launch";
import AddBoxIcon from "@material-ui/icons/AddBox";

import ChaptersMenu from "components/Menus/ChaptersMenu.js";

import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import CircularProgress from "@material-ui/core/CircularProgress";

import Heading from "components/Heading/Heading.js";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import SwapVertIcon from "@material-ui/icons/SwapVert";

import Toolbar from "@material-ui/core/Toolbar";

import Button from "components/CustomButtons/Button.js";

import { Link } from "react-router-dom";

import { app, baseAPI } from "base.js";

const useStyles = makeStyles(styles);

const chapterMoveRenameEnabled = true;

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    moveEnabled,
    onMove,
    onAccept,
    onReject,
    disabledAccept,
    disabled,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: moveEnabled,
      })}
    >
      {moveEnabled ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Change chapter order
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Chapters list
        </Typography>
      )}

      {moveEnabled ? (
        <>
          <Tooltip title="Accept new chapter order">
            <IconButton
              aria-label="accept"
              onClick={onAccept}
              disabled={disabledAccept}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset chapter order">
            <IconButton aria-label="reject" onClick={onReject}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Move chapter order">
          <IconButton
            aria-label="move chapters"
            onClick={onMove}
            disabled={disabled}
          >
            <SwapVertIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function Chapters(props) {
  const {
    firebaseUser,
    defaultBook,
    currentUser,
    history,
    hideAlert,
    loadingAlert,
    successAlert,
    errorAlert,
    warningAlert,
    googleAlert,
    openWindowInside,
  } = props;

  const classes = useStyles();

  const [chapterCount, setChapterCount] = useState(null);

  const [processLoading, setProcessLoading] = useState(false);
  const [addChapterError, setAddChapterError] = useState(false);
  const [addChapterText, setAddChapterText] = useState("");

  const [move, setMove] = useState(false);

  const [chapters, setChapters] = useState(null);

  const [chaptersArray, setChaptersArray] = useState(
    defaultBook.chaptersArray || null
  );

  useEffect(() => {
    setAddChapterText("");
    setAddChapterError(false);
    setChapters(defaultBook.chapters);
  }, [defaultBook.chapters]);

  useEffect(() => {
    setChaptersArray(defaultBook.chaptersArray);
  }, [defaultBook.chaptersArray]);

  useEffect(() => {
    const ref = app.database().ref(`books/${firebaseUser.defaultBook}/recipes`);

    ref.once("value").then((snapshot) => {
      const count = {};
      snapshot.forEach((snapshotChild) => {
        const chapter = snapshotChild.val().chapter;
        if (count[chapter]) {
          count[chapter]++;
        } else {
          count[chapter] = 1;
        }
      });
      setChapterCount(count);
    });

    return () => {
      ref.off();
      hideAlert();
    };
  }, [firebaseUser.defaultBook]);

  const addChapter = (chapterText, confirm) => {
    const googleAddAlert = () =>
      googleAlert({
        text:
          "You must link your Google Account in order to add chapters into your template.",
        nextFn: () => addChapter(chapterText, confirm),
      });

    if (defaultBook.hasTemplate && !firebaseUser.googleRefresh) {
      googleAddAlert();
      return;
    }

    if (chapterText) {
      if (!confirm && defaultBook.hasTemplate) {
        warningAlert(
          "Confirm chapter add",
          `You are adding ${chapterText} as a chapter to your book. Please note this process may take a while. Are you sure you want to continue?`,
          () => addChapter(chapterText, true)
        );
        return;
      }

      loadingAlert();
      setProcessLoading(true);

      currentUser.getIdToken(true).then((token) => {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        fetch(
          `${baseAPI}/chapters/add-chapter${
            defaultBook.hasTemplate ? "-template" : ""
          }/${firebaseUser.defaultBook}/${encodeURIComponent(chapterText)}`,
          { headers }
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.google_refresh_error) {
              googleAddAlert();
            } else if (result.importing) {
              successAlert(
                "Chapter is now being added!",
                `This may take some time as it is being added to your book. 
              This process counts as an import and therefore some features may be unavailable while this process is taking place.`
              );
            } else {
              //setChapters({ ...chapters, chapterId: true });
              successAlert(
                "Chapter is added!",
                `Your chapter ${chapterText} was successfully added!`
              );
            }

            setProcessLoading(false);
          })
          .catch((err) => {
            errorAlert("There was an error", `Error message: ${err.message}`);
            setAddChapterError(true);
            setProcessLoading(false);
          });
      });
    } else {
      setAddChapterError(true);
    }
  };

  const removeChapter = (chapterId, confirm) => {
    const googleRemoveAlert = () =>
      googleAlert({
        text:
          "You must link your Google Account in order to remove chapters from your template.",
        nextFn: () => removeChapter(chapterId, confirm),
      });

    if (defaultBook.chaptersArray.length <= 1) {
      errorAlert(
        "Cannot remove chapter",
        `You must have at least 1 chapter inside your book. If you wish to remove ${chapterId} please create another chapter and then remove it.`
      );
      return;
    }

    if (!firebaseUser.googleRefresh && defaultBook.hasTemplate) {
      googleRemoveAlert();
      return;
    }

    if (!confirm) {
      warningAlert(
        "Are you sure?",
        `Are you sure you want to delete ${defaultBook.chapters[chapterId]?.name}?`,
        () => removeChapter(chapterId, true)
      );
      return;
    }

    loadingAlert();
    setProcessLoading(true);
    currentUser.getIdToken(true).then((token) => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);

      fetch(
        `${baseAPI}/chapters/remove${
          defaultBook.hasTemplate ? "-template" : ""
        }-chapter/${firebaseUser.defaultBook}/${encodeURIComponent(chapterId)}`,
        { headers }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((result) => {
          if (result.google_refresh_error) {
            googleRemoveAlert();
          } else if (result.recipesWithChapter) {
            warningAlert(
              "CANNOT DELETE CHAPTER",
              <p>
                You have{" "}
                <b>
                  {result.recipeCount} recipe{result.recipeCount > 1 ? "s" : ""}
                </b>{" "}
                assigned to the <b>{defaultBook.chapters[chapterId]?.name}</b>{" "}
                chapter. In order to delete, first go to{" "}
                <Link to="/recipes/library">
                  <b>VIEW RECIPES</b>
                </Link>
                , click the drop down arrow next to the{" "}
                <b>{defaultBook.chapters[chapterId]?.name}</b> chapter and
                select a new chapter for all recipes.
              </p>,
              () =>
                history.push({
                  pathname: "/recipes/library",
                  state: { chapter: chapterId },
                }),
              {
                cancelText: "Maybe later",
                confirmText: "View Recipes",
              }
            );
          } else {
            const { [chapterId]: oldChapter, ...restChapters } = chapters;
            setChapters(restChapters);

            successAlert(
              "Successfully deleted!",
              `The chapter ${defaultBook.chapters[chapterId]?.name} was successfully deleted!`
            );
          }
        })
        .catch((err) => {
          errorAlert("Something went wrong...", `Error: ${err.message}`);
          console.log(err);
        })
        .finally(() => setProcessLoading(false));
    });
  };

  const changeChapterOrder = async (changeType) => {
    if (!changeType && defaultBook.hasTemplate) {
      console.log("test");
      return warningAlert(
        "Are you sure you want to change your chapter order?",
        <>
          <p>
            There are two options when changing chapter order with a template:
          </p>
          <ul>
            <li>Automatically rearrange pages inside template</li>
            <li>Manually arrange pages inside template</li>
          </ul>
          <p>
            Please note:{" "}
            <i>
              Automatically rearranging pages inside your template may have
              unintended consequences.
            </i>
          </p>
          <p>
            <b>Are you sure you want to continue?</b>
          </p>
          <Link
            onClick={() => {
              resetChapterOrder();
              hideAlert();
            }}
          >
            No, cancel.
          </Link>

          <h6 style={{ marginTop: "1.5rem" }}>
            Choose template arrangement type
          </h6>
        </>,
        () => changeChapterOrder("automatic"),
        {
          cancelText: "Manual",
          confirmText: "Automatic",
          closeOnOutside: false,
          closeOnEscape: false,
          focusCancel: false,
          onCancel: () => {
            changeChapterOrder("manual");
          },
        }
      );
    }

    loadingAlert();

    const token = await currentUser.getIdToken(true);
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Content-Type", "application/json");

    const response = await fetch(
      `${baseAPI}/chapters/change-chapter-order/${changeType || "manual"}`,
      {
        headers,
        method: "POST",
        body: JSON.stringify({
          chaptersArray,
          changeType,
          bookId: firebaseUser.defaultBook,
        }),
      }
    );

    try {
      if (response.status === 200) {
        const result = await response.json();

        setChaptersArray(result.chaptersArray);
        setMove(false);

        successAlert(
          "Successfully moved chapters!",
          result.changedTemplate
            ? "Please check your inside pages to ensure recipes and chapters moved correctly inside your cookbook."
            : changeType
            ? "You can now move your chapters inside your cookbook layout manually."
            : "",
          hideAlert,
          { disableCancel: true }
        );
      } else {
        throw new Error("Error status: " + response.status);
      }
    } catch (e) {
      console.error(e);
      console.error("Something went wrong");
      errorAlert(
        "Could not change chapter order",
        "Please try again or contact support@createcookbooks.com"
      );
      resetChapterOrder();
    }
  };

  const resetChapterOrder = () => {
    setChaptersArray(defaultBook.chaptersArray);
    setMove(false);
  };

  const moveChapter = (oldIndex, newIndex) => {
    setChaptersArray(
      chaptersArray.map((chapterId, index) =>
        index === newIndex
          ? chaptersArray[oldIndex]
          : index === oldIndex
          ? chaptersArray[newIndex]
          : chapterId
      )
    );
  };

  const renameChapter = (chapterId, accept) => {
    if (!accept && defaultBook.hasTemplate) {
      return warningAlert(
        "Are you sure want to rename your chapter?",
        "Please note: All chapter renames are dashboard only. You will also need to rename your chapter inside your cookbook manually. Are you sure you want to continue?",
        () => {
          renameChapter(chapterId, true);
        }
      );
    }

    warningAlert(
      "Rename your chapter",
      <>
        <p>
          <b>Old chapter:</b> {defaultBook.chapters[chapterId]?.name}
        </p>
      </>,
      (e) => renameChapterFetch(chapterId, e),
      { input: true }
    );
  };

  const renameChapterFetch = async (chapterId, chapterName) => {
    loadingAlert();

    const headers = new Headers();

    const token = await currentUser.getIdToken(true);

    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Content-Type", "application/json");

    try {
      const response = await fetch(
        `${baseAPI}/chapters/rename-chapter/${firebaseUser.defaultBook}`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({ chapterId, chapterName }),
        }
      );

      if (response.status === 200) {
        const result = await response.json();

        if (defaultBook.hasTemplate) {
          warningAlert(
            "Successfully renamed chapter!",
            "Your chapter has been renamed inside your dashboard, however you will still need to rename your chapter inside your cookbook. Click the 'Open Chapter' button to go directly to the chapter or go into your inside pages.",
            () => {
              openWindowInside(
                chapterName,
                defaultBook.chapters[chapterId].slideId
              );
              hideAlert();
            },
            {
              confirmText: "Open Chapter",
              cancelText: "Not now",
            }
          );
        } else {
          successAlert("Successfully renamed chapter!");
        }
      } else {
        throw new Error("Server error");
      }
    } catch (e) {
      console.error(e);
      console.error("Could not rename chapter");
      errorAlert(
        "Could not rename chapter",
        "Please try again. If this error continues to persist please contact support@createcookbooks.com"
      );
    }
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={9} md={8} lg={6}>
          <Heading
            textAlign="center"
            title="Chapters"
            category={
              <Link
                to={{
                  pathname: "/guide",
                  state: {
                    scrollTo: "chapters",
                  },
                }}
              >
                Add or remove chapters.
              </Link>
            }
          />
          {defaultBook.userBookRole === "owner" ? (
            <>
              <FormControl
                variant="filled"
                fullWidth={true}
                error={addChapterError}
              >
                <InputLabel htmlFor="filled-chapter-adornment">
                  Add new chapter title
                </InputLabel>
                <FilledInput
                  id="filled-chapter-adornment"
                  type="text"
                  aria-describedby="helper-text"
                  onChange={(e) => {
                    if (addChapterError) setAddChapterError(false);
                    setAddChapterText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      //addChapter(e.target.value);
                      addChapter(addChapterText);
                    }
                  }}
                  value={addChapterText}
                  inputProps={{
                    disabled:
                      defaultBook.importing ||
                      defaultBook.templateProcessing ||
                      processLoading ||
                      firebaseUser.busy,
                  }}
                  disableUnderline={defaultBook.importing}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        aria-label="add new chapter"
                        onClick={() => addChapter(addChapterText)}
                      >
                        <AddBoxIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {defaultBook.importing ? (
                  <CustomLinearProgress
                    color="info"
                    style={{
                      height: "2px",
                      transform: "scaleX(1)",
                      marginTop: "-2px",
                    }}
                  />
                ) : null}
              </FormControl>
            </>
          ) : null}
          {defaultBook.chapters && defaultBook.chaptersArray ? (
            <div
              style={{
                //textAlign: "center",
                marginBottom: "1rem",
                marginTop: "2rem",
              }}
            >
              <EnhancedTableToolbar
                moveEnabled={move}
                onMove={() => setMove(true)}
                onAccept={() => changeChapterOrder()}
                onReject={resetChapterOrder}
                disabled={
                  !chapterMoveRenameEnabled ||
                  (defaultBook.userBookRole !== "owner" &&
                    defaultBook.userBookRole !== "designer")
                }
                disabledAccept={defaultBook.chaptersArray.every(
                  (chapterId, index) => chaptersArray[index] === chapterId
                )}
              />
              <TableContainer component={Paper}>
                <Table aria-label="chapters table">
                  <TableHead>
                    <TableRow>
                      {move ? (
                        <>
                          <TableCell></TableCell>
                          <TableCell>Order</TableCell>{" "}
                        </>
                      ) : null}
                      <TableCell>Chapters</TableCell>
                      <TableCell align="center">Recipes</TableCell>
                      {(defaultBook.userBookRole === "owner" ||
                        defaultBook.userBookRole === "designer") &&
                      defaultBook.hasTemplate ? (
                        <TableCell align="right">Link to chapter</TableCell>
                      ) : null}
                      {defaultBook.userBookRole === "owner" ? (
                        <TableCell align="right">Options</TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chaptersArray.map((chapterId, index) => (
                      <TableRow key={chapterId}>
                        {move ? (
                          <>
                            <TableCell>
                              <IconButton
                                type="submit"
                                size="small"
                                className={classes.iconButton}
                                aria-label={`move ${defaultBook.chapters[chapterId]?.name} up`}
                                onClick={() => moveChapter(index, index - 1)}
                                disabled={index === 0}
                              >
                                <KeyboardArrowUpIcon />
                              </IconButton>
                              <IconButton
                                type="submit"
                                size="small"
                                className={classes.iconButton}
                                aria-label={`move ${defaultBook.chapters[chapterId]?.name} down`}
                                onClick={() => moveChapter(index, index + 1)}
                                disabled={index === chaptersArray.length - 1}
                              >
                                <KeyboardArrowDownIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <h4>{index + 1}.</h4>
                            </TableCell>
                          </>
                        ) : null}
                        <TableCell component="th" scope="row">
                          <h4>{defaultBook.chapters[chapterId]?.name}</h4>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          {chapterCount ? (
                            chapterCount[chapterId] ? (
                              <Button
                                color="info"
                                size="sm"
                                onClick={() =>
                                  history.push({
                                    pathname: "/recipes/library",
                                    state: {
                                      chapter: chapterId,
                                    },
                                  })
                                }
                              >
                                {chapterCount[chapterId]} recipe
                                {chapterCount[chapterId] > 1 ? "s" : null}
                              </Button>
                            ) : (
                              "No recipes"
                            )
                          ) : (
                            <CircularProgress
                              size={25}
                              disableShrink={true}
                              style={{ margin: "0 20px" }}
                            />
                          )}
                        </TableCell>
                        {(defaultBook.userBookRole === "owner" ||
                          defaultBook.userBookRole === "designer") &&
                        defaultBook.hasTemplate ? (
                          <TableCell align="right">
                            <IconButton
                              disabled={
                                !!(
                                  defaultBook.importing ||
                                  defaultBook.templateProcessing
                                )
                              }
                              onClick={() =>
                                openWindowInside(
                                  defaultBook.chapters[chapterId]?.name,
                                  defaultBook.chapters[chapterId].slideId
                                )
                              }
                            >
                              <LaunchIcon />
                            </IconButton>
                          </TableCell>
                        ) : null}
                        {defaultBook.userBookRole === "owner" ? (
                          <TableCell align="right">
                            <ChaptersMenu
                              onRemove={removeChapter}
                              onRename={renameChapter}
                              chapterId={chapterId}
                              importing={
                                !!(
                                  defaultBook.importing ||
                                  defaultBook.templateProcessing
                                ) || processLoading
                              }
                              disabled={!!move}
                              enableRename={chapterMoveRenameEnabled}
                            />
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : null}
        </GridItem>
      </GridContainer>
    </div>
  );
}
