import React, { useState, useEffect } from "react";

import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import registerStyles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

import { emailProvider } from "base.js";

const useStyles = makeStyles(registerStyles);

export default function EmailConfirm(props) {
  const classes = useStyles();

  const { currentUser, errorAlert, nextFn, onLoading, hocLoading } = props;

  const [email, setEmail] = useState(currentUser.email);
  const [password, setPassword] = useState(null);

  const [emailErr, setEmailErr] = useState(null);
  const [passErr, setPassErr] = useState(null);

  const [loading, setLoading] = useState(null);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const onLoginSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      if (!email) setEmailErr("Email address is required.");
      if (!password) setPassErr("Password is required");

      return;
    }

    setLoading(true);

    const credential = emailProvider.credential(email, password);

    currentUser
      .reauthenticateWithCredential(credential)
      .then((userCred) => {
        setLoading(false);

        nextFn();
      })
      .catch((error) => {
        setLoading(false);

        if (error.code === "auth/invalid-email") {
          setEmailErr("Email address is invalid.");
        } else if (error.code === "auth/weak-password") {
          setPassErr("Password is too weak (less than 6 characters).");
        } else if (error.code === "auth/wrong-password") {
          setPassErr("Incorrect password was given.");
        } else if (error.code === "auth/user-mismatch") {
          setEmailErr(
            "The login provided does not correspond to the currently logged in user."
          );
        } else if (error.code === "auth/user-not-found") {
          setEmailErr("User does not exist.");
        } else {
          errorAlert(
            "Something went wrong",
            "There was an error in reauthentication, please try again or contact info@createacookbook.com.au for help."
          );
          console.error(error);
        }
      });
  };

  return (
    <form className={classes.form} onSubmit={onLoginSubmit}>
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Email className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          placeholder: "Email...",
          defaultValue: currentUser.email,
          onChange: (e) => {
            if (emailErr) setEmailErr(null);
            setEmail(e.target.value);
          },
          disabled: loading || hocLoading,
        }}
        error={!!emailErr}
        helperText={emailErr}
      />
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
            </InputAdornment>
          ),
          placeholder: "Password...",
          onChange: (e) => {
            if (passErr) setPassErr(null);
            setPassword(e.target.value);
          },
          type: "password",
          disabled: loading || hocLoading,
        }}
        error={!!passErr}
        helperText={passErr}
      />

      <div className={classes.center} style={{ marginTop: "3vh" }}>
        <Button color="info" type="submit" disabled={loading || hocLoading}>
          Login
        </Button>
      </div>
    </form>
  );
}
