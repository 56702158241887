import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {},
};

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  function CustomToolbar(props) {
    const { classes, toggleDelete, addRecipe, deleteView } = props;

    return (
      <React.Fragment>
        <Tooltip title={"Add a new recipe"}>
          <IconButton className={classes.iconButton} onClick={addRecipe}>
            <AddIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={"Delete View"}>
          <IconButton
            color={deleteView ? "primary" : "default"}
            className={classes.iconButton}
            onClick={toggleDelete}
          >
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
);
