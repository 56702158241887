import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";

import Paperform from "components/Paperform.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Heading from "components/Heading/Heading.js";

import BookIcon from "@material-ui/icons/Book";
import SettingsIcon from "@material-ui/icons/Settings";
import Close from "@material-ui/icons/Close";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import { baseAPI } from "base.js";

import styles from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle.js";

const useStyles = makeStyles(styles);

const fieldVals = {
  name: "Submitted by",
  about: "About this recipe",
  prep: "Prep time",
  dietType: "Dietary type",
  servesMakes: "Serves/Makes",
  cookTime: "Cook time",
  difficulty: "Difficulty",
  tips: "Tips and suggestions",
  imageUpload: "Image upload",
  customFields: "Custom fields",
};

export default function BookSettings(props) {
  const classes = useStyles();

  const {
    currentUser,
    firebaseUser,
    defaultBook,
    loadingAlert,
    successAlert,
    errorAlert,
    warningAlert,
    hideAlert,
    history,
  } = props;

  // const [loading, setLoading] = useState(false);
  const [prefill, setPrefill] = useState(null);

  const [paperformSubmitted, setPaperformSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);

  const [deleteState, setDelete] = useState(false);

  useEffect(() => {
    if (deleteState === "template" && !defaultBook.hasTemplate) {
      history.push("/dashboard");
    }
  }, [defaultBook.hasTemplate, defaultBook.importing, deleteState]);

  useEffect(() => {
    getPrefill();
  }, []);

  const paperformReset = () => {
    setPrefill(null);
    setPaperformSubmitted(false);
    getPrefill();
  };

  const getPrefill = () => {
    const customFields = defaultBook.formSettings.customFields;

    setPrefill(
      `role=${firebaseUser.role}&bookID=${firebaseUser.defaultBook}&title=${
        defaultBook.title
      }&description=${
        defaultBook.description || ""
      }${defaultBook.formSettings.showFields
        .map((key) => "&showFields=" + encodeURIComponent(fieldVals[key]))
        .join("")}${
        customFields
          ? Object.keys(customFields)
              .filter((customField) => customFields[customField] !== null)
              .map(
                (customField) =>
                  `&${customField}=${customFields[customField].label}`
              )
              .join("")
          : ""
      }`
    );
  };

  const handleSubmission = async (submission) => {
    try {
      loadingAlert();
      setLoading(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const response = await fetch(`${baseAPI}/books/update`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        setLoading(false);
        hideAlert();
        setPaperformSubmitted(true);
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setPrefill(null);
      setLoading(false);
      errorAlert(
        "There was a problem submitting...",
        "We have saved your recipe. Please try submitting again. Error ID: " +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${encodeURIComponent(value)}`;
        })
        .join("&")}`
    );
  };

  const removeCurrentTemplate = async (e) => {
    const compareString = (str) =>
      str
        .split(" ")
        .map((word) => word.trim())
        .join("");

    if (compareString(e) !== compareString(defaultBook.title)) {
      errorAlert(
        "Incorrect confirmation",
        "The entered book title was incorrect, please try again."
      );
      return;
    }

    loadingAlert();

    const headers = new Headers();

    headers.append(
      "Authorization",
      `Bearer ${await currentUser.getIdToken(true)}`
    );

    try {
      const response = await fetch(
        `${baseAPI}/books/delete/${firebaseUser.defaultBook}?templateOnly=1`,
        { headers }
      );

      if (response.status === 200) {
        const result = await response.json();

        if (result.delete) {
          successAlert("Template removal requirest confirmed!");
          setDelete("template");
        } else {
          console.log(result);
          errorAlert(
            "Something went wrong...",
            "We could not remove your template, please try again."
          );
        }
      } else {
        console.log(response);
        errorAlert(
          "Something went wrong...",
          "We could not remove your template, please try again."
        );
      }
    } catch (e) {
      errorAlert("Something went wrong...", e.message);
      //setLoading(false);
      console.log(e);
    }
  };

  const removeCurrentBook = async (e) => {
    const compareString = (str) =>
      str
        .split(" ")
        .map((word) => word.trim())
        .join("");

    if (compareString(e) !== compareString(defaultBook.title)) {
      errorAlert(
        "Incorrect confirmation",
        "The entered book title was incorrect, please try again."
      );
      return;
    }

    loadingAlert();

    const headers = new Headers();

    headers.append(
      "Authorization",
      `Bearer ${await currentUser.getIdToken(true)}`
    );

    try {
      const response = await fetch(
        `${baseAPI}/books/delete/${firebaseUser.defaultBook}`,
        { headers }
      );

      if (response.status === 200) {
        const result = await response.json();

        if (result.delete) {
          successAlert(
            "Delete request confirmed!",
            "Your book is now in the process of being deleted and you will be safely redirected to all books once this is complete."
          );
          setDelete("book");
        } else {
          console.log(result);
          errorAlert(
            "Something went wrong...",
            "Couldn't delete your book, please try again."
          );
        }
      } else {
        console.log(response);
        errorAlert(
          "Something went wrong...",
          "Couldn't delete your book, please try again."
        );
      }
    } catch (e) {
      errorAlert("Something went wrong...", e.message);
      //setLoading(false);
      console.log(e);
    }
  };

  const reset = (value) => {
    if (value === 0) {
      getPrefill();
    } else {
      paperformReset();
    }
  };

  return (
    <div>
      <CustomTabs
        headerColor="info"
        //plainTabs={true}
        value={0}
        fullWidth={true}
        onChange={reset}
        tabs={[
          {
            tabName: "Basic",
            tabIcon: BookIcon,
            tabContent: (
              <>
                {!(
                  deleteState ||
                  defaultBook.importing ||
                  defaultBook.isGenerating ||
                  defaultBook.templateProcessing
                ) ? (
                  <div style={{ visibility: loading ? "hidden" : "visible" }}>
                    <Paperform
                      prefill={prefill}
                      hasPrefill={true}
                      paperFormID="cc-book-settings"
                      onSubmission={handleSubmission}
                    />
                    {paperformSubmitted ? (
                      <div style={{ marginTop: "3rem", textAlign: "center" }}>
                        <Button onClick={() => history.push("/dashboard")}>
                          Back to dashboard
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <Heading
                      textAlign="center"
                      title="Your book is busy"
                      category={
                        <span>
                          Cannot update your book while there are processes
                          happening in your book. Please wait!
                        </span>
                      }
                    />
                    <CustomLinearProgress color="info" />
                  </>
                )}
              </>
            ),
          },

          {
            tabName: "Advanced",
            tabIcon: SettingsIcon,
            tabContent: (
              <div style={{ textAlign: "center" }}>
                {defaultBook.hasTemplate ? (
                  <>
                    <Heading
                      textAlign="center"
                      title="Remove your template"
                      category={
                        deleteState === "template"
                          ? "Currently removing your template, you will be redirected soon!"
                          : "Contact support@createcookbooks.com to remove your template"
                      }
                    />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={8} md={6}>
                        {deleteState === "template" ? (
                          <CustomLinearProgress color="info" />
                        ) : firebaseUser.admin ? (
                          <Button
                            color="danger"
                            //className={classes.marginRight}
                            disabled={!!deleteState}
                            onClick={() =>
                              !(
                                defaultBook.importing ||
                                defaultBook.isGenerating ||
                                defaultBook.templateProcessing
                              )
                                ? warningAlert(
                                    "Please confirm.",
                                    [
                                      "To remove your template please confirm by typing in the title of your book ",
                                      <b key="bold">{defaultBook.title}</b>,
                                      " and press ok.",
                                    ],
                                    removeCurrentTemplate,
                                    { input: true }
                                  )
                                : errorAlert(
                                    "Something went wrong",
                                    `Your book is currently active in some capacity, please wait and try again later.`
                                  )
                            }
                          >
                            <Close className={classes.icons} /> Remove Template
                          </Button>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </>
                ) : null}
                <Heading
                  textAlign="center"
                  title="Delete your book"
                  category={
                    deleteState === "book" ? (
                      <span>
                        Currently deleting, you will be redirected soon!
                      </span>
                    ) : (
                      <>
                        Deleting your book results in all recipes, uploaded
                        images, and edited Google Slides being deleted forever.
                        You cannot retrieve any of this data after deletion.
                        <br />
                        <b>You have been warned!</b>
                      </>
                    )
                  }
                />
                <GridContainer justify="center">
                  <GridItem xs={12} sm={8} md={6}>
                    {deleteState === "book" ? (
                      <CustomLinearProgress color="info" />
                    ) : (
                      <Button
                        color="danger"
                        //className={classes.marginRight}
                        disabled={!!deleteState}
                        onClick={() =>
                          !(
                            defaultBook.importing ||
                            defaultBook.isGenerating ||
                            defaultBook.templateProcessing
                          )
                            ? warningAlert(
                                "Please confirm.",
                                [
                                  "To delete your cookbook please confirm by typing in the title of your book ",
                                  <b key="bold">{defaultBook.title}</b>,
                                  " and press ok.",
                                ],
                                removeCurrentBook,
                                { input: true }
                              )
                            : errorAlert(
                                "Something went wrong",
                                `Your book is currently active in some capacity, please wait and try again later.`
                              )
                        }
                      >
                        <Close className={classes.icons} /> Remove Cookbook
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
