import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import Icon from "@material-ui/core/Icon";

import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import LockIcon from "@material-ui/icons/Lock";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Divider from "@material-ui/core/Divider";

import GoogleRegister from "components/Auth/GoogleRegister.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import { app } from "base.js";
import { Redirect } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);

export default function Register(props) {
  const { currentUser, setCurrentUser } = props;

  const [checked, setChecked] = useState([]);

  const [loading, setLoading] = useState(null);
  //const [googleLoad, setGoogleLoad] = useState(false);

  const handleToggle = (value) => {
    if (termsErr) setTermsErr(null);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      setTerms(true);
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setTerms(false);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();

  const [name, setName] = useState(null);
  const [nameErr, setNameErr] = useState(null);

  const [email, setEmail] = useState(
    props.location.state && props.location.state.email
      ? props.location.state.email
      : null
  );
  const [emailErr, setEmailErr] = useState(null);

  const [password, setPassword] = useState(null);
  const [passErr, setPassErr] = useState(null);

  const [terms, setTerms] = useState(false);
  const [termsErr, setTermsErr] = useState(null);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!name || !email || !password || !terms) {
      if (!name) setNameErr("Name is required.");
      if (!email) setEmailErr("Email address is required.");
      if (!password) setPassErr("Password is required");
      if (!terms)
        setTermsErr(
          "Please tick the above checkbox to agree to the terms and conditions."
        );
      setLoading(false);
      return;
    }

    app
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        try {
          setCurrentUser(user, true, { displayName: name });
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.code === "auth/email-already-in-use") {
          return app
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((providers) => {
              if (providers.length === 1 && providers[0] === "google.com") {
                setEmailErr(
                  "Email address is already connected to a Google account. Please use the Google Sign In button above this form to continue."
                );
              } else {
                setEmailErr("Email address is already in use.");
              }

              setLoading(false);
            });
        }

        if (err.code === "auth/invalid-email") {
          setEmailErr("Email address is invalid.");
        }

        if (err.code === "auth/weak-password") {
          setPassErr("Password is too weak (less than 6 characters).");
        }

        setLoading(false);
      });
  };

  return currentUser ? (
    <Redirect to="/" />
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Choose your package"
                    description={`
                     Sign up for free or upgrade to our premium packages
                    `}
                    icon={TouchAppIcon}
                    iconColor="info"
                  />
                  <InfoArea
                    title="No minimum quantities"
                    description="Print 1 or thousands, or create an ebook"
                    icon={DoneAllIcon}
                    iconColor="info"
                  />
                  <InfoArea
                    title="Treasured recipes"
                    description="Your recipes are kept safe and secure"
                    icon={LockIcon}
                    iconColor="info"
                  />
                  <InfoArea
                    title="Beautiful templates"
                    description="Add recipes and personal images to our beautiful templates. Upgrade and access our premium photo library"
                    icon={MenuBookIcon}
                    iconColor="info"
                  />
                </GridItem>

                <GridItem xs={12} sm={8} md={5}>
                  <Divider className={classes.horizontalLine} />
                  <div style={{ paddingTop: "20px" }}>
                    <div className={classes.center}>
                      <GoogleRegister setCurrentUser={setCurrentUser} />

                      <h4
                        className={classes.socialTitle}
                        style={{ paddingTop: "10px" }}
                      >
                        or
                      </h4>
                    </div>
                    <form className={classes.form} onSubmit={onFormSubmit}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Name...",
                          onChange: (e) => {
                            if (nameErr) setNameErr(null);
                            setName(e.target.value);
                          },
                          disabled: loading,
                        }}
                        error={!!nameErr}
                        helperText={nameErr}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Email...",
                          onChange: (e) => {
                            if (emailErr) setEmailErr(null);
                            setEmail(e.target.value);
                          },
                          defaultValue: email || "",
                          disabled: loading,
                        }}
                        error={!!emailErr}
                        helperText={emailErr}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Password...",
                          onChange: (e) => {
                            if (passErr) setPassErr(null);
                            setPassword(e.target.value);
                          },
                          type: "password",
                          disabled: loading,
                        }}
                        error={!!passErr}
                        helperText={passErr}
                      />
                      <FormControl error={!!termsErr}>
                        <FormControlLabel
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => {
                                handleToggle(1);
                              }}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                              disabled={loading}
                            />
                          }
                          label={
                            <span>
                              I agree to the{" "}
                              <a
                                href="https://www.createacookbook.com.au/tos"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                terms and conditions
                              </a>
                              .
                            </span>
                          }
                        />
                        {termsErr ? (
                          <FormHelperText>{termsErr}</FormHelperText>
                        ) : null}
                      </FormControl>

                      <div className={classes.center}>
                        {loading ? (
                          <div className={classes.loader}>
                            <CircularProgress />
                          </div>
                        ) : (
                          <Button color="info" type="submit" disabled={loading}>
                            Get Started
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
