import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import IconButton from "@material-ui/core/IconButton";

import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import LaunchIcon from "@material-ui/icons/Launch";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Icon from "@material-ui/core/Icon";

import { Link } from "react-router-dom";

import FundraisingGenerate from "./FundraisingGenerate.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const fundraiserText = {
  a4poster: {
    title: "A4 poster",
    description: "",
  },
  preorder: {
    title: "Preorder form",
    description: "",
  },
  recipeRequest: {
    title: "Recipe request form",
    description: "",
  },
  sponsorContract: {
    title: "Sponsorship contract form",
    description: "",
  },
  sponsorRequest: {
    title: "Sponsorship request form",
    description: "",
  },
  recipeHandwritten: {
    title: "Handwritten recipe form",
    description: "",
  },
};

export default function Fundraising(props) {
  const { defaultBook, hideAlert, googleProvider } = props;

  const classes = useStyles();

  useEffect(() => {
    return () => hideAlert();
  }, []);

  if (!defaultBook.fundraising) {
  }

  const authuser =
    googleProvider && googleProvider.email
      ? `?authuser=${encodeURIComponent(googleProvider.email)}`
      : "";

  const openWindowSlide = (key, presId) => {
    window.open(
      `https://docs.google.com/presentation/d/${presId}/edit${authuser}`,
      `${key}Window`
    );
  };

  const openWindowSheet = (key, sheetId) => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${sheetId}/edit${authuser}`,
      `${key}Window`
    );
  };

  const downloadSlidePDF = (presId) => {
    window.open(
      `https://docs.google.com/presentation/d/${presId}/export/pdf${authuser}`,
      "_blank"
    );
  };

  const downloadSheetPDF = (sheetId) => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${sheetId}/export?format=pdf`,
      "_blank"
    );
  };

  if (!defaultBook.fundraising) {
    return <FundraisingGenerate {...props} />;
  }

  return (
    <div>
      <GridContainer justify="space-evenly">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Heading
            title="Fundraising tools and templates"
            category={
              <a
                href="javascript:void(0)"
                onClick={() =>
                  window.Beacon("article", "63366bd3cf38bc37aecf5233", {
                    type: "sidebar",
                  })
                }
              >
                Information on how to gain sponsorship to fund the cost of
                printing your cookbooks and help you with your fundraiser
              </a>
            }
            textAlign="center"
          />
          <GridContainer justify="center" alignItems="center">
            <GridItem style={{ width: "285px" }}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Icon>auto_stories</Icon>
                  </CardIcon>

                  <h4
                    className={classes.cardTitle}
                    style={{ paddingTop: "10px" }}
                  >
                    Fundraiser guide
                  </h4>
                </CardHeader>
                <CardBody style={{ paddingBottom: "0" }}>
                  <p className={classes.cardCategory}></p>
                </CardBody>
                <CardFooter stats>
                  <Button
                    onClick={() =>
                      openWindowSlide("guide", defaultBook.fundraising.guide)
                    }
                    color="info"
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    Open
                  </Button>
                  <Button
                    onClick={() =>
                      downloadSlidePDF(defaultBook.fundraising.guide)
                    }
                    color="secondary"
                    style={{ width: "100%", marginLeft: "10px" }}
                  >
                    PDF
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem style={{ width: "285px" }}>
              <Card>
                <CardHeader color="secondary" stats icon>
                  <CardIcon color="secondary">
                    <Icon>calculate</Icon>
                  </CardIcon>

                  <h4
                    className={classes.cardTitle}
                    style={{ paddingTop: "10px" }}
                  >
                    Fundraiser calculator
                  </h4>
                </CardHeader>
                <CardBody style={{ paddingBottom: "0" }}>
                  <p className={classes.cardCategory}></p>
                </CardBody>
                <CardFooter stats>
                  <Button
                    onClick={() =>
                      openWindowSheet(
                        "calculator",
                        defaultBook.fundraising.calculator
                      )
                    }
                    color="info"
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    Open
                  </Button>
                  <Button
                    onClick={() =>
                      downloadSheetPDF(defaultBook.fundraising.calculator)
                    }
                    color="secondary"
                    style={{ width: "100%", marginLeft: "10px" }}
                  >
                    PDF
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            {Object.keys(defaultBook.fundraising)
              .filter((key) => !["guide", "calculator"].includes(key))
              .sort()
              .map((key) => (
                <GridItem style={{ width: "285px" }} key={key}>
                  <Card>
                    <CardHeader color="secondary" stats icon>
                      <CardIcon color="secondary">
                        <Icon>edit</Icon>
                      </CardIcon>

                      <h4
                        className={classes.cardTitle}
                        style={{ paddingTop: "10px" }}
                      >
                        {fundraiserText[key].title}
                      </h4>
                    </CardHeader>
                    <CardBody style={{ paddingBottom: "0" }}>
                      <p className={classes.cardCategory}>
                        {fundraiserText[key].description}
                      </p>
                    </CardBody>
                    <CardFooter stats>
                      <Button
                        onClick={() =>
                          openWindowSlide(key, defaultBook.fundraising[key])
                        }
                        color="info"
                        style={{ width: "100%", marginRight: "10px" }}
                      >
                        Open
                      </Button>
                      <Button
                        onClick={() =>
                          downloadSlidePDF(defaultBook.fundraising[key])
                        }
                        color="secondary"
                        style={{ width: "100%", marginLeft: "10px" }}
                      >
                        PDF
                      </Button>
                    </CardFooter>
                  </Card>
                </GridItem>
              ))}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
