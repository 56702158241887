import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Paperform from "components/Paperform.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Button from "components/CustomButtons/Button.js";

import Heading from "components/Heading/Heading.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

import BookIcon from "@material-ui/icons/Book";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import FolderIcon from "@material-ui/icons/Folder";

import { app, baseAPI } from "base.js";

const useStyles = makeStyles(styles);

let timeout;

export default function DoMyCookbook(props) {
  const classes = useStyles();

  const currencySymbols = {
    AUD: "AU $",
    USD: "US $",
    EUR: "€",
    GBP: "£",
    NZD: "NZ $",
  };

  const currencyPaperformIds = {
    AUD: "do-my-au",
    USD: "do-my-us",
    EUR: "do-my-eu",
    GBP: "do-my-gb",
    NZD: "do-my-nz",
  };

  const {
    defaultBook,
    currentUser,
    firebaseUser,
    warningAlert,
    hideAlert,
    googleProvider,
  } = props;

  const [authToken, setAuthToken] = useState(null);
  const [prefill, setPrefill] = useState(null);

  const [loading, setLoading] = useState(false);

  const [currency, setCurrency] = useState("USD");

  const [currencyVals, setCurrencyVals] = useState(null);

  const [importCount, setImportCount] = useState(0);

  useEffect(() => {
    //createPaperformPrefill();

    setLoading(true);

    fetch(`${baseAPI}/tasks/get-currencies-paperform`)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then((result) => {
        setCurrency(firebaseUser.currency || result.defaultCurrency);
        setCurrencyVals(result.currencies);
        setLoading(false);
      });
  }, []);

  const createPaperformPrefill = async () => {
    setLoading(true);

    timeout = setTimeout(() => {
      warningAlert(
        "Timed out",
        "The template form has timed out. Please click try again to refresh.",
        () => {
          setPrefill(null);
          hideAlert();
          createPaperformPrefill();
        },
        {
          hideCancel: true,
          onCancel: () => null,
          confirmText: "Try again",
        }
      );
    }, 3600000);

    const recipesRef = app
      .database()
      .ref(`books/${firebaseUser.defaultBook}/recipes`);

    const count = await new Promise((res, rej) =>
      recipesRef
        .orderByChild("import")
        .equalTo(true)
        .once(
          "value",
          (snapshot) => {
            res(snapshot.numChildren());
          },
          (err) => rej(err)
        )
    );

    setImportCount(count);

    const token = await currentUser.getIdToken(true);
    setAuthToken(token);
  };

  useEffect(() => {
    if (authToken) {
      setPrefill(
        `auth=${authToken}
        &userId=${currentUser.uid}
        &bookId=${firebaseUser.defaultBook}
        &flaggedRecipes=${importCount}
        &currentPlan=${firebaseUser.role}
        &chaptersCount=${defaultBook.chaptersArray.length}
        &currencyRate=${currencyVals[currency] || 1}
        &currencySymbol=${currencySymbols[currency]}
        &email=${currentUser.email}
        &name=${currentUser.displayName}`
      );
      setLoading(false);

      setAuthToken(null);
    }
  }, [authToken]);

  const doMyTypingComponent = (
    <GridItem
      xs={12}
      sm={12}
      md={8}
      style={{ textAlign: "center", marginTop: "50px" }}
    >
      <Heading
        title="Do my typing"
        category="Upload high quality photos or scans of recipes into your designated Google Drive folder below"
        textAlign="center"
      />
      <Button
        color="transparent"
        simple
        onClick={() =>
          window.open(
            `https://drive.google.com/drive/folders/${defaultBook.bookInfo?.typingFolder}?authuser=${googleProvider.email}`
          )
        }
      >
        <GridContainer direction="column">
          <GridItem>
            <FolderIcon style={{ height: "40px", width: "25px" }} />
          </GridItem>
          <GridItem>Open Folder</GridItem>
        </GridContainer>
      </Button>
    </GridItem>
  );

  if (loading) return <CustomLinearProgress />;

  if (!prefill) {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Heading
            title="Do my cookbook"
            category="Select your currency and press the START button"
            textAlign="center"
          />
          <Card
            style={{
              padding: "12px",
              width: "130px",
              margin: "0 auto",
            }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ margin: "0 auto" }}
            >
              <InputLabel id="currency-select-label">Currency</InputLabel>
              <Select
                labelId="currency-select-label"
                id="currency-select"
                value={currency || "USD"}
                onChange={(e) => setCurrency(e.target.value)}
                label="Currency"
                style={{ height: "40px" }}
              >
                <MenuItem value="AUD">🇦🇺 AUD</MenuItem>
                <MenuItem value="GBP">🇬🇧 GBP</MenuItem>
                <MenuItem value="CAD">🇨🇦 CAD</MenuItem>
                <MenuItem value="EUR">🇪🇺 EUR</MenuItem>
                <MenuItem value="NZD">🇳🇿 NZD</MenuItem>
                <MenuItem value="USD">🇺🇸 USD</MenuItem>
              </Select>
            </FormControl>
          </Card>
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <Button onClick={createPaperformPrefill} color="info" size="lg">
              Open Form
            </Button>
          </div>
        </GridItem>
        {defaultBook.bookInfo?.typingFolder ? doMyTypingComponent : null}
      </GridContainer>
    );
  }

  return (
    <div>
      <Paperform
        paperFormID={currencyPaperformIds[currency]}
        prefill={prefill}
        hasPrefill={true}
        onSubmission={(data) => console.log(data)}
      />
    </div>
  );
}
