import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

//var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { location } = props;

  let collapseRoutes = {};

  const collapseRef = React.useRef({});

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [isOpenAvatar, setIsOpenAvatar] = React.useState(false);

  const color = "blue";
  const bgColor = "white";
  const logo = require("assets/img/logo/logo_x1.png");
  const logoSmall = require("assets/img/logo/logo_icon_x1.png");

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
    });

  React.useEffect(() => {
    if (location && location.pathname) {
      const defaultBook = props.defaultBook;

      /*window.zE("webWidget", "updatePath", {
        title: `Dashboard - ${location.pathname}${
          defaultBook && defaultBook.title ? ` [${defaultBook.title}]` : ""
        }`,
        url: `https://app.createcookbooks.com${location.pathname}`,
      });*/

      window.Beacon("event", {
        type: "page-viewed",
        url: `https://app.createcookbooks.com${location.pathname}`,
        title: `Dashboard - ${location.pathname}${
          defaultBook && defaultBook.title ? ` [${defaultBook.title}]` : ""
        }`,
      });

      const routeName = getActiveRoute(routes);

      window.document.title = `${routeName} – ${defaultBook.title}`;

      //open collapse using route.collapse
      mainPanel.current.scrollTop = 0;

      if (
        collapseRoutes[location.pathname] &&
        collapseRef &&
        collapseRef.current &&
        collapseRef.current.openCollapse
      ) {
        if (collapseRoutes[location.pathname] === "openAvatar")
          setIsOpenAvatar(true);
        collapseRef.current.openCollapse(collapseRoutes[location.pathname]);
      }

      if (isOpenAvatar && collapseRoutes[location.pathname] !== "openAvatar") {
        collapseRef.current.closeOpenAvatar();
        setIsOpenAvatar(false);
      }
    }
  }, [location.pathname]);

  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getFullscreenRoute = () => {
    return true || window.location.pathname !== "/place-order";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname === routes[i].path) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const scrollToTop = (num) => {
    mainPanel.current.scrollTop = num ? num : 0;
  };

  const getRoutes = (routes, collapse) => {
    return routes.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.views, route.state);
      }

      const hasAccess = () => {
        if (route.pageRequirements) {
          const reqs = route.pageRequirements;

          if (reqs.template === true && !props.defaultBook.hasTemplate) {
            return false;
          }

          if (reqs.template === false && props.defaultBook.hasTemplate) {
            return false;
          }
          if (
            reqs.bookRoles &&
            reqs.bookRoles.indexOf(props.defaultBook.userBookRole) === -1
          ) {
            return false;
          }

          if (
            reqs.plans &&
            reqs.plans.indexOf(props.firebaseUser.role) === -1
          ) {
            return false;
          }
        }

        return true;
      };

      if (collapse && !route.hasFunction && hasAccess()) {
        //open collapse using route.collapse
        const path = route.path;
        collapseRoutes[path] = collapse;
      }

      if (route.layout === "dashboard") {
        return route.hasFunction ? null : (
          <Route
            exact
            path={route.path}
            render={(rest) => {
              if (hasAccess()) {
                return (
                  <route.component
                    {...rest}
                    {...props}
                    scrollToTop={scrollToTop}
                  />
                );
              }
              return (
                <Redirect
                  to={route.pageRequirements.redirect || "/dashboard"}
                />
              );
            }}
            key={index}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={classes.wrapper}>
      {props.alert}
      <Sidebar
        routes={routes}
        logo={logo}
        logoSmall={logoSmall}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        canUpgrade={props.firebaseUser.role === "starter"}
        isOwner={props.defaultBook.userBookRole === "owner"}
        collapseRef={collapseRef}
        setOpenAvatarBool={(bool) => setIsOpenAvatar(bool)}
        {...props}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...props}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getFullscreenRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect to="/" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        )}
        {false && getFullscreenRoute() ? <Footer fluid /> : null}
      </div>
    </div>
  );
}
