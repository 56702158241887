import React, { useState, useEffect } from "react";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Quote from "components/Typography/Quote.js";

import quotes from "assets/quotes/quotes.json";

export default function Preloader() {
  const [delay, setDelay] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [quote, setQuote] = useState(null);

  const logo = require("assets/img/logo/logo_icon_x2.png");

  useEffect(() => {
    async function loadDelay(ms) {
      await setTimeout(setDelay(false), ms);
    }

    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);

    if (delay) loadDelay(200);

    return () => setDelay(false);
  }, [delay]);

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };

  const img = {
    marginBottom: "10%",
    width: "18vmin",
    maxWidth: "125px",
    minWidth: "70px",
  };

  return (
    <div style={style}>
      <img
        src={logo}
        style={img}
        alt="Create Cookbooks Logo (Small)"
        onLoad={() => setLoaded(true)}
      />
      {loaded && quote ? (
        <div style={{ width: "400px" }}>
          <CustomLinearProgress color="info" />
          <Quote
            text={quote.quote}
            author={quote.author ? quote.author : "Anonymous"}
          />
        </div>
      ) : null}
    </div>
  );
}
