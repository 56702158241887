import BookIcon from "@material-ui/icons/Book";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import ShareIcon from "@material-ui/icons/Share";

import fundraiserIcon from "assets/img/icons/fundraiser.svg";

import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import TimelineIcon from "@material-ui/icons/Timeline";
import PrintIcon from "@material-ui/icons/Print";
import DashboardIcon from "@material-ui/icons/Dashboard";

import Share from "views/Share/Share.js";
import Preview from "views/Cookbook/Preview.js";
import Layout from "views/Cookbook/Layout.js";

import Chapters from "views/Cookbook/Chapters.js";
import Templates from "views/Cookbook/Templates.js";

import Recipes from "views/Recipes/Recipes.js";
import RecipesNew from "views/Recipes/RecipesNew.js";

import Import from "views/Recipes/Import.js";
import Submit from "views/Recipes/Submit.js";
import RecipeForm from "views/Recipes/RecipeForm.js";

import Publish from "views/Publish/Publish.js";

import Orders from "views/Publish/Orders.js";

import Login from "views/Auth/Login.js";
import Register from "views/Auth/Register.js";
import ForgotPass from "views/Auth/ForgotPass";
import Invite from "views/Auth/Invite.js";
import Plans from "views/Auth/Plans.js";
import Books from "views/Auth/Books.js";
import MyAccount from "views/Auth/MyAccount.js";

import Dashboard from "views/Dashboard/Dashboard.js";
import DoMyCookbook from "views/Cookbook/DoMyCookbook";
import Guide from "views/Guide/Guide";
import BookSettings from "views/Dashboard/BookSettings";

import PhoneAppt from "views/Support/PhoneAppt.js";

import Fundraising from "views/Fundraising/Fundraising.js";

import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";

var routes = [
  //auth pages
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: Login,
    invisible: true,
    layout: "auth",
  },
  {
    path: "/register",
    name: "Register",
    mini: "R",
    component: Register,
    invisible: true,
    layout: "auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    mini: "FP",
    component: ForgotPass,
    invisible: true,
    layout: "auth",
  },
  {
    path: "/invite",
    name: "Invite",
    mini: "I",
    component: Invite,
    invisible: true,
    layout: "auth",
  },
  {
    path: "/plans",
    invisible: true,
    layout: "auth",
    pageRequirements: {
      plans: [],
      redirect: "/packages",
    },
  },
  {
    path: "/packages",
    name: "Packages",
    mini: "P",
    component: Plans,
    invisible: true,
    layout: "auth",
    noAuthRedirect: "/register",
  },
  {
    path: "/books",
    name: "My Books",
    mini: "UP",
    component: Books,
    invisible: true,
    layout: "auth",
    noAuthRedirect: "/login",
  },
  {
    path: "/my-account",
    name: "My Account",
    mini: "UP",
    component: MyAccount,
    invisible: true,
    layout: "auth",
    noAuthRedirect: "/login",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "dashboard",
  },
  {
    collapse: true,
    state: "openAvatar",
    name: "User",
    invisible: true,
    views: [
      {
        path: "/book-settings",
        name: "Book Settings",
        mini: "BS",
        component: BookSettings,
        invisible: true,
        layout: "dashboard",
        pageRequirements: {
          bookRoles: ["owner"],
        },
      },
    ],
  },
  {
    path: "/guide",
    name: "4 step guide",
    icon: TimelineIcon,
    //component: Guide,
    hasFunction: true,
    function: () => {
      window.Beacon("article", "633cdd033f38fe1e46863cd7", { type: "modal" });
    },
    layout: "dashboard",
  },
  {
    collapse: true,
    name: "Recipes",
    icon: MenuBookIcon,
    state: "recipesCollapse",
    pageRequirements: {
      bookRoles: ["owner", "designer", "editor", "contributor"],
    },
    views: [
      {
        path: "/recipes",
        invisible: true,
        name: "Recipe library",
        layout: "dashboard",
        pageRequirements: {
          plans: [],
          redirect: "/recipes/library",
        },
      },
      {
        path: "/recipes/library",
        name: "Recipe library",
        mini: "R",
        component: RecipesNew,
        layout: "dashboard",
        pageRequirements: {
          bookRoles: ["owner", "designer", "editor", "contributor"],
        },
      },
      {
        path: "/recipes/import",
        name: "Import recipes",
        mini: "I",
        component: Import,
        layout: "dashboard",
        pageRequirements: {
          bookRoles: ["owner"],
          template: true,
        },
      },
      {
        path: "/recipes/submit",
        name: "Submit recipe",
        mini: "S",
        component: Submit,
        layout: "dashboard",
        pageRequirements: {
          bookRoles: ["owner", "designer", "editor", "contributor"],
        },
      },
      {
        path: "/recipes/submit/:chapterId/:chapterName",
        name: "Submit recipe",
        invisible: true,
        component: RecipeForm,
        layout: "dashboard",
        pageRequirements: {
          bookRoles: ["owner", "designer", "editor", "contributor"],
        },
      },
    ],
  },
  {
    collapse: true,
    name: "Cookbook",
    icon: BookIcon,
    state: "cookbookCollapse",
    pageRequirements: {
      bookRoles: ["owner", "designer", "editor"],
    },
    views: [
      {
        path: "/cookbook",
        invisible: true,
        layout: "dashboard",
        pageRequirements: {
          plans: [],
          redirect: "/cookbook/layout",
        },
      },
      {
        path: "/cookbook/template",
        name: "Select a template",
        mini: "ST",
        component: Templates,
        layout: "dashboard",
        pageRequirements: {
          template: false,
          bookRoles: ["owner"],
          redirect: "/cookbook/layout",
        },
      },
      {
        path: "/cookbook/layout",
        name: "Layout",
        mini: "L",
        component: Layout,
        layout: "dashboard",
        pageRequirements: {
          template: true,
          bookRoles: ["owner", "designer"],
        },
      },
      {
        path: "/cookbook/preview",
        name: "Preview",
        mini: "P",
        component: Preview,
        layout: "dashboard",
        pageRequirements: {
          template: true,
          bookRoles: ["owner", "designer"],
        },
      },

      {
        path: "/cookbook/chapters",
        name: "Chapters",
        mini: "C",
        component: Chapters,
        layout: "dashboard",
        pageRequirements: {
          bookRoles: ["owner", "designer", "editor"],
        },
      },
      {
        path: "/cookbook/do-my-layout",
        invisible: true,
        layout: "dashboard",
        pageRequirements: {
          plans: [],
          redirect: "/do-my-cookbook",
        },
      },
    ],
  },
  {
    path: "/do-my-cookbook",
    name: "Do my cookbook",
    icon: EmojiPeopleIcon,
    component: DoMyCookbook,
    //hasFunction: true,
    /*function: () => {
      window.open("https://www.createcookbooks.com/domycookbook", "_blank");
    },*/
    layout: "dashboard",
    pageRequirements: {
      template: true,
      bookRoles: ["owner"],
    },
  },
  {
    path: "/share",
    name: "Invite & Share",
    icon: ShareIcon,
    component: Share,
    layout: "dashboard",
    pageRequirements: {
      bookRoles: ["owner", "co-owner"],
    },
  },
  {
    path: "/place-order",
    name: "Place order",
    icon: PrintIcon,
    component: Orders,
    layout: "dashboard",
    pageRequirements: {
      bookRoles: ["owner"],
      template: true,
    },
  },
  {
    path: "/reprint",
    invisible: true,
    layout: "dashboard",
    pageRequirements: {
      plans: [],
      redirect: "/place-order",
    },
  },
  {
    path: "/publish",
    invisible: true,
    layout: "dashboard",
    pageRequirements: {
      plans: [],
      redirect: "/place-order",
    },
  },
  {
    collapse: true,
    name: "Support",
    icon: QuestionAnswerIcon,
    state: "supportCollapse",
    views: [
      {
        name: "Help articles",
        mini: "H",
        layout: "dashboard",
        hasFunction: true,
        function: () => {
          window.Beacon("open");
          window.Beacon("navigate", "/answers/");
        },
      },
      {
        name: "Contact us",
        mini: "C",
        layout: "dashboard",
        hasFunction: true,
        function: () => {
          window.Beacon("open");
          window.Beacon("navigate", "/ask/");
        },
      },
      {
        path: "/support/phone",
        name: "Phone appointment",
        mini: "PH",
        component: PhoneAppt,
        layout: "dashboard",
        pageRequirements: {
          plans: ["community"],
          redirect: "/packages",
        },
      },
    ],
  },
  {
    path: "/fundraising",
    name: "Fundraising",
    icon: "volunteer_activism",
    component: Fundraising,
    layout: "dashboard",
    pageRequirements: {
      bookRoles: ["owner"],
      plans: ["community"],
      redirect: "/packages",
    },
  },
];

export default routes;
