import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";

import GoogleConfirm from "./GoogleConfirm";
import EmailConfirm from "./EmailConfirm";

import registerStyles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(registerStyles);

export default function LoginConfirm(props) {
  const classes = useStyles();

  const {
    hideAlert,
    text,
    nextFn,
    options,
    errorAlert,
    currentUser,
    loginHint,
  } = props;

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <p>{text}</p>
      <br />
      {options.google ? (
        <GoogleConfirm
          onLogin={nextFn}
          onError={(errMsg) => errorAlert("Login error", errMsg)}
          currentUser={currentUser}
          onLoad={setLoading}
          loginHint={loginHint}
        />
      ) : null}
      {options.google && options.emailPass ? (
        <h4 className={classes.socialTitle} style={{ paddingTop: "10px" }}>
          or
        </h4>
      ) : null}
      {options.emailPass ? (
        <EmailConfirm
          currentUser={currentUser}
          errorAlert={errorAlert}
          nextFn={nextFn}
          onLoading={setLoading}
          hocLoading={loading}
        />
      ) : null}
      {!loading ? <Button onClick={hideAlert}>Cancel</Button> : null}
    </div>
  );
}
