import React, { useEffect, useState } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ShareMenu(props) {
  const { onUpdate, onRemove, expired, invitedUser, inviteLoading } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading && !inviteLoading) setLoading(false);
  }, [inviteLoading]);

  const handleUpdate = () => {
    handleMenuClose();
    onUpdate(invitedUser);
  };

  const handleRemove = () => {
    handleMenuClose();
    setLoading(true);
    onRemove(invitedUser);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return loading ? (
    <CircularProgress size={25} />
  ) : (
    <>
      <IconButton
        edge="end"
        aria-controls={`user-menu-${invitedUser.email}`}
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`user-menu-${invitedUser.email}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleUpdate}>
          {expired ? "Resend invite" : "Update and resend invite"}
        </MenuItem>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </Menu>
    </>
  );
}
