import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText";

import Heading from "components/Heading/Heading";

import features from "./Stories.js";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import Hidden from "@material-ui/core/Hidden";

import Autocomplete from "@material-ui/lab/Autocomplete";

import TextField from "@material-ui/core/TextField";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  //stringify: (option) => option.title + " " + option.plainText,
});

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    width: "100%",
    margin: "0",
  },
}));

export default function Guide(props) {
  const classes = useStyles();
  const stories = features(props.history);

  const [scrollTo, setScrollTo] = useState(null);

  const refs = stories.reduce((acc, value) => {
    acc[value.title.toLowerCase()] = React.createRef();
    return acc;
  }, {});

  const { history, location } = props;

  useEffect(() => {
    if (refs && location.state && location.state.scrollTo) {
      setScrollTo(location.state.scrollTo.toLowerCase());
    } else if (refs && location.search) {
      const urlParams = new URLSearchParams(location.search);
      const feature = urlParams.get("feature");
      if (feature) setScrollTo(feature.split("-").join(" ").toLowerCase());
    }
  }, []);

  useEffect(() => {
    if (scrollTo && refs[scrollTo] && refs[scrollTo].current) {
      history.replace("/guide");

      refs[scrollTo].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [scrollTo]);

  return (
    <div>
      <div className={classes.headingContainer}>
        <Heading
          title="Help guide"
          category={
            <a
              href="https://app.createcookbooks.com/guide"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open in new window
              <OpenInNewIcon style={{ height: "15px" }} />
            </a>
          }
          textAlign="center"
        />
      </div>

      <div>
        <Autocomplete
          id="search-box"
          options={stories.map((story) => story.title)}
          //getOptionLabel={(option) => option.title}
          style={{ width: 300, margin: "0 auto" }}
          openOnFocus={true}
          autoHighlight={true}
          filterOptions={filterOptions}
          clearOnBlur={true}
          onChange={(event, newValue) => {
            const id = newValue ? newValue.toLowerCase() : null;
            if (id && refs[id] && refs[id].current) {
              refs[id].current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Features dropdown" variant="filled" />
          )}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Hidden smDown>
          <GridContainer>
            <GridItem md={6}>
              <GridContainer direction="column">
                {stories
                  .filter((guide) => !guide.inverted)
                  .map((guide, index) => (
                    <div
                      ref={refs[guide.title.toLowerCase()]}
                      key={guide.title}
                      style={{ marginTop: "-10px", paddingTop: "10px" }}
                    >
                      <GridItem>
                        <Card>
                          <CardHeader color="info" text>
                            <CardText color="info">
                              <h4>{guide.title}</h4>
                            </CardText>
                          </CardHeader>
                          <CardBody>{guide.subtitle}</CardBody>
                          <CardBody>
                            {guide.body}
                            {guide.footer}
                          </CardBody>
                        </Card>
                      </GridItem>
                    </div>
                  ))}
              </GridContainer>
            </GridItem>
            <GridItem md={6}>
              <GridContainer direction="column">
                {stories
                  .filter((guide) => guide.inverted)
                  .map((guide, index) => (
                    <div
                      ref={refs[guide.title.toLowerCase()]}
                      key={guide.title}
                      style={{ marginTop: "-10px", paddingTop: "10px" }}
                    >
                      <GridItem key={guide.title + index}>
                        <Card>
                          <CardHeader color="info" text>
                            <CardText color="info">
                              <h4>{guide.title}</h4>
                            </CardText>
                          </CardHeader>
                          <CardBody>{guide.subtitle}</CardBody>
                          <CardBody>
                            {guide.body}
                            {guide.footer}
                          </CardBody>
                        </Card>
                      </GridItem>
                    </div>
                  ))}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Hidden>
        <Hidden mdUp>
          <GridContainer direction="column">
            {stories.map((guide, index) => (
              <div
                ref={refs[guide.title.toLowerCase()]}
                key={guide.title}
                style={{ marginTop: "-10px", paddingTop: "10px" }}
              >
                <GridItem key={guide.title + index}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <h4>{guide.title}</h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>{guide.subtitle}</CardBody>
                    <CardBody>
                      {guide.body}
                      {guide.footer}
                    </CardBody>
                  </Card>
                </GridItem>
              </div>
            ))}
          </GridContainer>
        </Hidden>
      </div>
    </div>
  );
}
