import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import EditRecipeForm from "components/RecipeForms/EditRecipeForm";

import { app, baseAPI } from "base.js";
import Paperform from "components/Paperform";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textTransform: "unset",
  },
  paperformContainer: {
    height: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

export default function EditPaperform(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);

  const [prefill, setPrefill] = useState(null);

  const {
    recipeKey,
    title,
    onClose,
    history,
    hideAlert,
    errorAlert,
    warningAlert,
    loadingAlert,
    currentUser,
    defaultBook,
    firebaseUser,
  } = props;

  const bookId = props.firebaseUser.defaultBook;

  const showFields = props.defaultBook.formSettings.showFields;
  const customFields = props.defaultBook.formSettings.customFields;

  const handleClose = () => {
    if (loaded && !submitted) {
      warningAlert(
        "Are you sure?",
        "Any changes you have made will not be saved.",
        () => {
          hideAlert();
          setOpen(false);
        }
      );
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    setOpen(true);

    const docRef = app
      .firestore()
      .collection("books")
      .doc(bookId)
      .collection("recipes")
      .doc(recipeKey);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          throw new Error("Could not get the recipe data.");
        }
      })
      .then((data) => {
        setPrefill(
          `${Object.entries(data.recipe)
            .filter(
              ([key, value]) =>
                key !== "bookID" &&
                key !== "imageUpload" &&
                key !== "chapter" &&
                value !== null
            )
            .map(([key, value]) => {
              if (
                key.startsWith("custom_field") &&
                !(customFields && customFields[key]) &&
                !!value
              ) {
                return `${key}=${encodeURIComponent(value)}&${key}_label=${key
                  .split("_")
                  .map((word) => word[0].toUpperCase() + word.slice(1))
                  .join(" ")}`;
              }

              if (key) return `${key}=${encodeURIComponent(value)}`;
            })
            .join("&")}&fields=${Object.keys(data.recipe)
            .filter(
              (key) =>
                key !== "bookID" &&
                ((data.recipe[key] !== null && data.recipe[key] !== "") ||
                  (showFields && showFields.indexOf(key) !== -1))
            )
            .join(",")}${
            customFields
              ? Object.keys(customFields)
                  .filter((customField) => !!customFields[customField])
                  .map(
                    (customField) =>
                      `&${customField}_label=${encodeURIComponent(
                        customFields[customField].label
                      )}`
                  )
                  .join("")
              : ""
          }${
            data.recipe.imageUpload
              ? `&imageCount=${data.recipe.imageUpload.length}`
              : ""
          }&bookID=${bookId}&edit=${recipeKey}&bookRole=${
            defaultBook.userBookRole
          }&userRole=${firebaseUser.role}`
        );
      });

    return () => {
      hideAlert();
    };
  }, []);

  useEffect(() => {
    if (!submitted) {
      const unblock = history.block((location, action) => {
        return window.confirm(
          "Are you sure? Anything you have entered may be lost."
        );
      });

      window.onbeforeunload = (event) => {
        return false;
      };

      return () => {
        window.onbeforeunload = undefined;
        unblock();
      };
    } else {
      const doNotBlock = history.block(() => true);
      window.onbeforeunload = undefined;

      return () => {
        window.onbeforeunload = undefined;
        doNotBlock();
      };
    }
  }, [submitted]);

  const handleSubmission = async (submission) => {
    try {
      loadingAlert();
      setLoading(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const response = await fetch(`${baseAPI}/recipes/edit`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        setLoading(false);
        hideAlert();
        setSubmitted(true);
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setLoading(false);
      setPrefill(null);
      errorAlert(
        "There was a problem submitting...",
        "We have saved your recipe. Please try submitting again. Error ID: " +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${encodeURIComponent(value)}`;
        })
        .join("&")}`
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      onExited={onClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!loaded ? <CustomLinearProgress /> : null}
      <div
        className={classes.paperformContainer}
        style={{ opacity: loading ? 0.1 : 1 }}
      >
        {false ? (
          <EditRecipeForm {...props} />
        ) : (
          <Paperform
            paperFormID="cc-edit"
            hasPrefill={true}
            prefill={prefill}
            onSubmission={handleSubmission}
            onLoad={() => setLoaded(true)}
            disableLoadingBar={true}
            fullPage={true}
          />
        )}
      </div>
    </Dialog>
  );
}
