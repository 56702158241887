import React, { useState, useEffect } from "react";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import Preloader from "views/Preloader/Preloader.js";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import SweetAlert from "react-bootstrap-sweetalert";

import DefaultThumbnail from "assets/img/cover-default-book.svg";

import GoogleLink from "components/Auth/GoogleLink.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import AuthTest from "components/Auth/AuthTest.js";

import { app, baseAPI } from "base.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const theme = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      underline: {
        "&:after": {
          // focused
          borderBottomColor: "#00acc1",
        },
      },
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#00acc1",
        },
        "&$error": {
          color: "#f44336",
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          color: "#00acc1",
          "& + $track": {
            backgroundColor: "#00acc1",
          },
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: "#00acc1",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#00acc1",
      },
    },
  },
});

const hist = createBrowserHistory();
const useStyles = makeStyles(styles);

export default function App() {
  const classes = useStyles();

  const [currentUser, setCurrentUser] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState(null);

  const [authenticated, setAuthenticated] = useState(null);
  const [gapi, setGapi] = useState(false);
  const [defaultBook, setDefaultBook] = useState(null);
  const [loading, setLoading] = useState(true);

  const hasGoogle = currentUser
    ? currentUser.providerData.filter(
        (provider) => provider.providerId === "google.com"
      ).length > 0
    : false;

  const googleProvider = hasGoogle
    ? currentUser.providerData.find(
        (provider) => provider.providerId === "google.com"
      )
    : null;

  const [role, setRole] = useState(null);

  const [goToDashboard, setGoToDashboard] = useState(false);

  const [forceBook, setForceBook] = useState(null);

  const [alert, setAlert] = useState(null);

  const [coverRef, setCoverRef] = useState(null);
  const [insideRef, setInsideRef] = useState(null);

  const [initUpdatePath, setInitUpdatePath] = useState(false);

  const hideAlert = () => {
    setAlert(null);
  };

  const googleAlert = (options) => {
    const {
      title,
      text,
      nextFn,
      successTitle,
      errorTitle,
      successText,
      successConfirmText,
      successCancelText,
      errorText,
    } = options;

    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={title || "Link Google Account"}
        onConfirm={hideAlert}
        onCancel={hideAlert}
        showConfirm={false}
        showCancel={true}
        cancelBtnCssClass={classes.button + " " + classes.gray}
      >
        <p>{text}</p>
        <GoogleLink
          currentUser={currentUser}
          setCurrentUser={handleCurrentUser}
          loginSuccess={() =>
            successAlert(
              successTitle || "Google Account successfully linked!",
              successText ||
                "Your Google Account has been successfully linked.",
              () => {
                if (nextFn) {
                  nextFn();
                } else {
                  hideAlert();
                }
              },
              { confirmText: successConfirmText, cancelText: successCancelText }
            )
          }
          loginError={() =>
            errorAlert(
              errorTitle || "Something went wrong",
              errorText ||
                "Unfortunately we couldn't link your Google Account. Please try again."
            )
          }
        />
      </SweetAlert>
    );
  };

  const loadingAlert = (text) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={"Loading"}
        onConfirm={hideAlert}
        showConfirm={false}
      >
        <CustomLinearProgress color="info" />
        {text ? "\n" + text : null}
      </SweetAlert>
    );
  };

  const successAlert = (title, text, nextFn, options) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={nextFn || hideAlert}
        onCancel={hideAlert}
        closeOnClickOutside={!options?.disableCancel}
        showCancel={!options?.disableCancel && !!nextFn}
        confirmBtnText={options ? options.confirmText || "OK" : "OK"}
        cancelBtnText={options ? options.cancelText || "CANCEL" : "CANCEL"}
        confirmBtnCssClass={classes.button + " " + classes.info}
        cancelBtnCssClass={classes.button + " " + classes.gray}
      >
        {text}
      </SweetAlert>
    );
  };

  const errorAlert = (title, text) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={hideAlert}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {text}
      </SweetAlert>
    );
  };

  const warningAlert = (title, text, nextFn, options) => {
    //change z-index to 10000
    setAlert(
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
        }}
        title={title}
        onConfirm={nextFn}
        onCancel={options && options.onCancel ? options.onCancel : hideAlert}
        input={options ? !!options.input : false}
        showConfirm={options ? !options.hideConfirm : true}
        showCancel={options ? !options.hideCancel : true}
        confirmBtnText={options ? options.confirmText || "OK" : "OK"}
        cancelBtnText={options ? options.cancelText || "CANCEL" : "CANCEL"}
        confirmBtnCssClass={classes.button + " " + classes.info}
        cancelBtnCssClass={classes.button + " " + classes.gray}
        closeOnClickOutside={options ? options.closeOnOutside : true}
        allowEscape={options ? options.closeOnEscape : true}
        focusCancelBtn={options ? options.focusCancel : false}
      >
        {text}
      </SweetAlert>
    );
  };

  const alerts = {
    hideAlert,
    googleAlert,
    loadingAlert,
    successAlert,
    errorAlert,
    warningAlert,
  };

  const christmasAlert = () => {
    return; // Remove this line next christmas

    if (alert) return;

    const xd_raw = window.localStorage.getItem("xmas_date");

    const xd = parseInt(xd_raw);

    if (!xd || xd + 1 * 24 * 60 * 60 * 1000 < Date.now()) {
      setTimeout(() =>
        warningAlert(
          "Christmas deadline approaching!",
          <>
            <p>
              Order books before <b>28th November</b> to ensure timely delivery
              amid high printer demand. Allow time for design checking and
              artwork processing.
            </p>
            <p>Contact us for 50+ book order timelines.</p>
          </>,
          () => hist.push("/place-order"),
          { confirmText: "Place order", cancelText: "Maybe later" }
        )
      );

      window.localStorage.setItem("xmas_date", Date.now());
    }
  };

  const googleOpenAlert = (slideTitle, nextFn) =>
    googleAlert({
      text: `In order to access ${slideTitle}, you must first link your Google
    Account.`,
      successText:
        "Your Google Account has been successfully linked and you can now view the Cookbook while logged into your Google Account.",
      successConfirmText: `Open ${slideTitle}`,
      nextFn,
    });

  const generateUrl = (presId, slideId, disableAuthUser) =>
    `https://docs.google.com/presentation/d/${presId}/edit${
      googleProvider && googleProvider.email && !disableAuthUser
        ? `?authuser=${encodeURIComponent(googleProvider.email)}`
        : ""
    }#${slideId ? `slide=id.${slideId}` : ""}`;

  const guideAlert = (slideTitle, nextFn) => {
    nextFn();

    return;

    //Alerts about guides but not needed anymore 10/02/23
    warningAlert(
      "Turn on your guides",
      <>
        <p>All text must stay within the safety margins.</p>
        <p>
          Make sure to turn on the{" "}
          <b>
            <i>Guides</i>
          </b>{" "}
          in your Google Slides{" "}
        </p>
        <p>{<b>View &gt; Guides &gt; Show Guides</b>}</p>
        <br />

        <Link
          to={{
            pathname: "/guide",
            state: {
              scrollTo: "Layout",
            },
          }}
        >
          Help article on Guides
        </Link>
      </>,
      () => {
        if (nextFn) nextFn();
        hideAlert();
      },
      {
        confirmText: `Open ${slideTitle}`,
      }
    );
  };

  const openWindowCover = () => {
    const coverGuideAlert = () =>
      guideAlert("Cover pages", () => {
        const newRef = window.open(
          generateUrl(defaultBook.slides.cover),
          "coverWindow"
        );
        setCoverRef(newRef);
      });

    if (!firebaseUser.googleRefresh) {
      googleOpenAlert("Cover pages", coverGuideAlert);
      return;
    }

    if (coverRef) {
      if (coverRef.closed) {
        coverGuideAlert();
      } else {
        coverRef.focus();
      }
    } else {
      coverGuideAlert();
    }
  };

  const openWindowInside = (title, slideId) => {
    const insideGuideAlert = () =>
      guideAlert(title || "Inside pages", () => {
        const newRef = window.open(
          slideId
            ? generateUrl(defaultBook.slides.inside, slideId)
            : generateUrl(defaultBook.slides.inside),
          "insideWindow"
        );
        setInsideRef(newRef);
      });

    if (!firebaseUser.googleRefresh) {
      googleOpenAlert(title || "Inside pages", insideGuideAlert);
      return;
    }

    if (insideRef) {
      if (insideRef.closed) {
        insideGuideAlert();
      } else {
        if (slideId) {
          insideRef.location.href = generateUrl(
            defaultBook.slides.inside,
            slideId,
            true
          );
        }
        insideRef.focus();
      }
    } else {
      insideGuideAlert();
    }
  };

  const openWindowPages = () => {
    if (!firebaseUser.googleRefresh) {
      googleOpenAlert("Page templates", () =>
        window.open(
          generateUrl(defaultBook.slides.pageTemplates),
          "pagesWindow"
        )
      );
      return;
    }
    if (
      firebaseUser.role === "starter" &&
      defaultBook.bookInfo.templateId === "basic"
    ) {
      warningAlert(
        "Upgrade to access",
        <p>
          Design pages are available on the Premium and Community packages.
        </p>,
        () => hist.push("/packages"),
        { confirmText: "Upgrade", cancelText: "Not now" }
      );
    } else {
      window.open(generateUrl(defaultBook.slides.pageTemplates), "pagesWindow");
    }
  };

  const openWindowSpare = () => {
    if (!firebaseUser.googleRefresh) {
      googleOpenAlert("Spare pages", () =>
        window.open(generateUrl(defaultBook.slides.spare), "spareWindow")
      );
      return;
    }

    if (
      firebaseUser.role === "starter" &&
      defaultBook.bookInfo.templateId === "basic"
    ) {
      warningAlert(
        "Upgrade to access",
        <p>
          Design pages are available on the Premium and Community packages.
        </p>,
        () => hist.push("/packages"),
        { confirmText: "Upgrade", cancelText: "Not now" }
      );
    } else {
      window.open(generateUrl(defaultBook.slides.spare), "spareWindow");
    }
  };

  const openSlides = {
    openWindowCover,
    openWindowInside,
    openWindowPages,
    openWindowSpare,
  };

  const signOutUser = () => {
    app
      .auth()
      .signOut()
      .then(async () => {
        //tawkLogOut();
        //zenLogOut();
        hsLogOut();
      })
      .then(() => hist.push("/login"));
  };

  const handleCurrentUser = (user, redirect, register) => {
    if (user) {
      /*zE('webWidget', 'updateSettings', {
        webWidget: {
          authenticate: {
            departments: {
              enabled: ['finance', 'hr', 'sales'],
              select: 'sales'
            }
          }
        }
      });*/

      if (redirect) {
        setLoading(true);
        hist.push("/");
        //window.zE("webWidget", "chat:reauthenticate");
      }

      setCurrentUser(user);
      setAuthenticated(true);
      if (register) {
        //tawkInit({ ...user, displayName: register.displayName });
        user.updateProfile({ displayName: register.displayName }).then(() => {
          user.sendEmailVerification({
            url: `https://api.createcookbooks.com/users/on-email-verify-link/${encodeURIComponent(
              user.email
            )}`,
          });

          initHelpScout(user);
        });
      } else {
        //tawkInit(user);
        initHelpScout(user);
      }
    } else {
      setAuthenticated(false);
      setCurrentUser(null);
      setFirebaseUser(null);
      setDefaultBook(null);
      setRole(null);
      setLoading(false);
    }
  };

  const handleFirebaseUserDefaultBook = (bookId) => {
    if (!firebaseUser) return;

    if (coverRef) setCoverRef(null);
    if (insideRef) setInsideRef(null);

    if (bookId === firebaseUser.defaultBook) {
      if (forceBook) {
        setForceBook(null);
        setGoToDashboard(true);
        setFirebaseUser({ ...firebaseUser, defaultBook: bookId });
        app
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .update({ defaultBook: bookId });
      } else {
        hist.push("/dashboard");
      }
    } else {
      if (forceBook) setForceBook(null);
      setGoToDashboard(true);
      setFirebaseUser({ ...firebaseUser, defaultBook: bookId });

      app
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .update({ defaultBook: bookId });
    }
  };

  /*const updateZenPath = () =>
    window.zE("webWidget", "updatePath", {
      title: `Dashboard - ${hist.location.pathname}${
        defaultBook && defaultBook.title ? ` [${defaultBook.title}]` : ""
      }`,
      url: `https://app.createcookbooks.com${hist.location.pathname}`,
    });*/

  useEffect(() => {
    /*let timeout;
    window.zE("webWidget:on", "chat:connected", async () => {
      timeout = setTimeout(() => updateZenPath, 2000);
    });

    window.zE("webWidget:on", "close", () => {
      window.zE("webWidget", "updateSettings", {
        webWidget: {
          chat: {
            suppress: false,
          },
          helpCenter: {
            suppress: false,
          },
        },
      });
    });

    window.zE("webWidget:on", "open", () => {
      const display = window.zE("webWidget:get", "display");

      if (display === "hidden") {
        errorAlert(
          "Could not open.",
          "Please try again, if you keep seeing this error the feature may be disabled."
        );
        window.zE("webWidget", "close");
      }
    });

    window.zESettings = {
      webWidget: {
        chat: {
          suppress: false,
          hideWhenOffline: false,
        },
        contactForm: {
          suppress: false,
        },
        helpCenter: {
          suppress: false,
        },
        authenticate: {
          chat: {
            jwtFn: function (callback) {
              getJwt(callback);
            },
          },
        },
      },
    };

    window.zE("webWidget", "helpCenter:setSuggestions", {
      search: "FAQ",
    });*/

    /*window.Tawk_API.onLoad = function () {
      //window.Tawk_API.hideWidget();
      tawkInit();
    };*/

    if (!gapi) {
      window.gapi.load("auth2", () => {
        setGapi(true);
      });
    }

    const query = new URLSearchParams(hist.location.search);
    const setBook = query.get("setBook");
    const impersonate = query.get("impersonate");

    if (setBook) setForceBook(setBook);

    if (impersonate) {
      app.auth().signInWithCustomToken(impersonate);
    }

    const removeAuthListener = app.auth().onAuthStateChanged((user) => {
      handleCurrentUser(user);
    });

    return () => {
      //clearTimeout(timeout);
      removeAuthListener();
    };
  }, []);

  const hsLogOut = () => window.Beacon("logout", { endActiveChat: true });

  /*const zenLogOut = () =>
    window.zE(() => {
      window.zE("webWidget", "logout");
      window.zE("webWidget", "prefill", {
        name: {
          readOnly: false, // optional
        },
        email: {
          readOnly: false, // optional
        },
        phone: {
          readOnly: false, // optional
        },
      });
    });*/

  const tawkInit = () => {
    const unsubscribe = app.auth().onAuthStateChanged((user) => {
      if (user) {
        unsubscribe();
        user.getIdToken(true).then((idToken) => {
          const header = new Headers();
          header.append("Authorization", `Bearer ${idToken}`);

          fetch(baseAPI + "/tawk/hash", {
            headers: header,
          })
            .then((response) => {
              console.log(response);
              return response.text();
            })
            .then((result) => {
              console.log(result);
              console.log("identify");
              console.log({
                name: user.displayName || " ",
                email: user.email,
                hash: result,
              });

              window.Tawk_API.setAttributes(
                {
                  name: user.displayName || "",
                  email: user.email,
                  hash: result,
                },
                function (error) {
                  if (error) console.error(error);

                  window.Tawk_API.showWidget();
                }
              );
            })
            .catch((err) => {
              console.error(err);
              window.Tawk_API.showWidget();
            });
        });
      } else {
        window.Tawk_API.showWidget();
      }
    });
  };

  const initHelpScout = (user) => {
    console.log("called");
    user.getIdToken(true).then((idToken) => {
      const header = new Headers();
      header.append("Authorization", `Bearer ${idToken}`);

      fetch(baseAPI + "/help-scout/signature", {
        headers: header,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          window.Beacon("identify", result.secureMode);
        })
        .catch((err) => {
          window.Beacon("logout");
        });
    });
  };

  /*const getJwt = (callback) => {
    const unsubscribe = app.auth().onAuthStateChanged((user) => {
      if (user) {
        unsubscribe();
        window.zE("webWidget", "prefill", {
          name: {
            value: user.displayName || "",
            readOnly: true, // optional
          },
          email: {
            value: user.email || "",
            readOnly: true, // optional
          },
          phone: {
            value: user.phoneNumber || "",
            readOnly: false, // optional
          },
        });

        user.getIdToken(true).then((idToken) => {
          const header = new Headers();
          header.append("Authorization", `Bearer ${idToken}`);

          fetch(baseAPI + "/zen/loginCreateSession", {
            headers: header,
          });

          fetch(baseAPI + "/zen/jwt", {
            headers: header,
          })
            .then((response) => {
              console.log(response);
              return response.text();
            })
            .then((result) => {
              callback(result);
            });
        });
      } else {
        callback("INVALID_JWT");
      }
    });
  };*/

  useEffect(() => {
    if (currentUser) {
      const userDoc = app.firestore().collection("users").doc(currentUser.uid);
      const removeFirebaseUser = userDoc.onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();

          setFirebaseUser({ ...data, userDoc });
        } else {
          setRole("none");
          setLoading(false);
        }
      });

      return () => removeFirebaseUser();
    }
  }, [currentUser]);

  useEffect(() => {
    if (firebaseUser) {
      if (role) {
        if (role !== firebaseUser.role) {
          const newRole = firebaseUser.role;

          if (role !== "none") {
            successAlert(
              `Successfully upgraded to ${
                newRole[0].toUpperCase() + newRole.slice(1)
              }!`,
              "Your account has been successfully upgraded."
            );
          } else if (newRole !== "starter") {
            successAlert(
              "Successfully signed up!",
              `Your account is now on the ${
                newRole[0].toUpperCase() + newRole.slice(1)
              } plan.`
            );
          }

          hist.push("/books");
          setRole(newRole);
        }
      } else {
        setRole(firebaseUser.role);
      }

      if (forceBook || firebaseUser.defaultBook) {
        const selectBook = forceBook || firebaseUser.defaultBook;

        const bookDoc = app.firestore().collection("books").doc(selectBook);

        const removeDefaultBook = bookDoc.onSnapshot(
          (doc) => {
            if (doc.exists) {
              const bookData = doc.data();

              if (bookData.users[currentUser.uid]) {
                christmasAlert();

                setDefaultBook({
                  ...bookData,
                  thumbnail: bookData.thumbnail || DefaultThumbnail,
                  userBookRole: bookData.users[currentUser.uid],
                  bookDoc,
                });
              } else if (firebaseUser.admin) {
                christmasAlert();
                setDefaultBook({
                  ...bookData,
                  thumbnail: bookData.thumbnail || DefaultThumbnail,
                  userBookRole: "owner",
                  bookDoc,
                });
              } else {
                setForceBook(null);
                setFirebaseUser({ ...firebaseUser, defaultBook: null });
                setDefaultBook(null);
                firebaseUser.userDoc.update({ defaultBook: null });
              }

              setLoading(false);
            } else {
              //hist.push("/books");
              setLoading(false);
            }
          },
          (err) => {
            console.error(err);
            setForceBook(null);
            setFirebaseUser({ ...firebaseUser, defaultBook: null });
            setDefaultBook(null);
            firebaseUser.userDoc.update({ defaultBook: null });
          }
        );

        return () => removeDefaultBook();
      } else {
        //hist.push("/books");
        setLoading(false);
      }
    }
  }, [firebaseUser, forceBook]);

  useEffect(() => {
    if (defaultBook && loading) {
      setLoading(false);
    }

    if (defaultBook && goToDashboard) {
      setGoToDashboard(false);
      hist.push("/dashboard");
    }
  }, [defaultBook]);

  useEffect(() => {
    //window.zE("webWidget", loading ? "hide" : "show");
    //window.Beacon("open");
    /*if (window.Tawk_API.showWidget && window.Tawk_API.hideWidget) {
      console.log("test load");
      if (loading) window.Tawk_API.hideWidget();
      else window.Tawk_API.showWidget();
    }*/
  }, [loading]);

  if (loading || !gapi) {
    return <Preloader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router history={hist}>
        <Switch>
          <Route
            exact
            path={routes
              .filter((route) => route.layout === "dashboard" || route.collapse)
              .flatMap((route) =>
                route.collapse
                  ? route.views
                      .filter((colRoute) => colRoute.path)
                      .map((colRoute) => colRoute.path)
                  : route.path
              )}
            render={(rest) =>
              authenticated &&
              firebaseUser &&
              (firebaseUser.defaultBook || forceBook) &&
              defaultBook &&
              currentUser.emailVerified ? (
                <AdminLayout
                  {...rest}
                  {...alerts}
                  {...openSlides}
                  alert={alert}
                  firebaseUser={
                    forceBook
                      ? { ...firebaseUser, defaultBook: forceBook }
                      : firebaseUser
                  }
                  currentUser={currentUser}
                  defaultBook={defaultBook}
                  googleProvider={googleProvider}
                  signOutUser={signOutUser}
                  setCurrentUser={setCurrentUser}
                  hist={hist}
                />
              ) : authenticated && firebaseUser ? (
                <Redirect to="/books" />
              ) : authenticated ? (
                <Redirect to="/packages" />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path={routes
              .filter((route) => route.layout === "auth")
              .map((route) => route.path)}
            render={(rest) => (
              <AuthLayout
                {...rest}
                {...alerts}
                alert={alert}
                setCurrentUser={handleCurrentUser}
                setDefaultBook={handleFirebaseUserDefaultBook}
                currentUser={currentUser}
                googleProvider={googleProvider}
                firebaseUser={firebaseUser}
                defaultBook={defaultBook}
                gapi={gapi}
                signOutUser={signOutUser}
                hist={hist}
              />
            )}
          />
          {authenticated &&
          firebaseUser &&
          (firebaseUser.defaultBook || forceBook) &&
          defaultBook &&
          currentUser.emailVerified ? (
            <Redirect from="/" to="/dashboard" />
          ) : authenticated && firebaseUser ? (
            <Redirect to="/books" />
          ) : authenticated ? (
            <Redirect to="/packages" />
          ) : (
            <Redirect from="/" to="/login" />
          )}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
