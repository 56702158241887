import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { Link } from "react-router-dom";

const styles = {
  cardTitle,
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

export default function Submit(props) {
  const classes = useStyles();

  const { defaultBook, history } = props;

  useEffect(() => {
    if (
      !(
        defaultBook.chapters &&
        Object.keys(defaultBook.chapters).length > 0 &&
        defaultBook.chaptersArray &&
        defaultBook.chaptersArray.length > 0
      )
    ) {
      history.replace("/cookbook/chapters");
    }
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Heading
          textAlign="center"
          title="Submit recipes"
          category={
            <a
              href="javascript:void(0)"
              onClick={() =>
                window.Beacon("article", "61f37d71d86136157d99e800", {
                  type: "sidebar",
                })
              }
            >
              Select a chapter to submit a recipe
            </a>
          }
        />
        <GridContainer direction="row" justify="center" alignItems="center">
          {defaultBook.chaptersArray
            ? defaultBook.chaptersArray.map((chapterId) => (
                <GridItem key={chapterId}>
                  <Card
                    className={classes.textCenter}
                    style={{ width: "20rem", marginBottom: "0" }}
                    key={chapterId}
                  >
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        {defaultBook.chapters[chapterId].name}
                      </h4>
                      <Button
                        size="sm"
                        color="info"
                        onClick={() =>
                          history.push(
                            `/recipes/submit/${encodeURIComponent(
                              chapterId
                            )}/${encodeURIComponent(
                              defaultBook.chapters[chapterId].name
                            )}`
                          )
                        }
                      >
                        Select Chapter
                      </Button>
                    </CardBody>
                  </Card>
                </GridItem>
              ))
            : null}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
