import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import Icon from "@material-ui/core/Icon";

import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";

import LinkIcon from "@material-ui/icons/Link";

import SettingsIcon from "@material-ui/icons/Settings";
import Close from "@material-ui/icons/Close";

import { baseAPI, emailProvider } from "base.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";

import Paperform from "components/Paperform.js";
import GoogleLink from "components/Auth/GoogleLink.js";
import LoginConfirm from "components/Auth/LoginConfirm.js";

import registerStyles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(registerStyles);

export default function MyAccount(props) {
  const classes = useStyles();

  const {
    currentUser,
    setCurrentUser,
    firebaseUser,
    hideAlert,
    successAlert,
    errorAlert,
    warningAlert,
    loadingAlert,
  } = props;

  const [loading, setLoading] = useState(false);

  const [prefill, setPrefill] = useState(null);

  const [forceHasGoogle, setForceHasGoogle] = useState(false);

  const [profileSubmitted, setProfileSubmitted] = useState(false);

  const [email, setEmail] = useState(null);
  const [emailErr, setEmailErr] = useState(null);

  const [password, setPassword] = useState(null);
  const [passErr, setPassErr] = useState(null);

  const [newEmail, setNewEmail] = useState(null);
  const [newEmailErr, setNewEmailErr] = useState(null);

  const [newPass, setNewPass] = useState(null);
  const [newPassErr, setNewPassErr] = useState(null);

  const hasGoogle =
    currentUser.providerData.filter(
      (provider) => provider.providerId === "google.com"
    ).length > 0 || forceHasGoogle;

  const hasEmailPass =
    currentUser.providerData.filter(
      (provider) => provider.providerId === "password"
    ).length > 0;

  const googleEmail =
    hasGoogle && !forceHasGoogle
      ? currentUser.providerData.filter(
          (provider) => provider.providerId === "google.com"
        )[0].email
      : null;

  /*const emailPassEmail = hasEmailPass
    ? currentUser.providerData.filter(
        (provider) => provider.providerId === "password"
      )[0].email
    : null;*/

  const loginHint =
    hasGoogle && !forceHasGoogle
      ? currentUser.providerData.filter(
          ({ providerId }) => providerId === "google.com"
        )[0].uid
      : currentUser.email;

  useEffect(() => {
    getPrefill();
  });

  const getPrefill = () => {
    currentUser.reload().then(() => {
      firebaseUser.userDoc
        .collection("public")
        .doc("profile")
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();

            const addressOrder = {
              street: 0,
              city: 1,
              state: 2,
              postcode: 3,
              country: 4,
            };

            setPrefill(
              `fullName=${currentUser.displayName}&dob=${
                data.dob || ""
              }&gender=${data.gender || ""}&organisation=${
                data.organisation || ""
              }&country=${data.country || ""}&address=${
                data.address
                  ? Object.entries(data.address)
                      .sort(([a], [b]) => addressOrder[b] - addressOrder[a])
                      .map(([key, value]) => value)
                      .join(",")
                  : null
              }&phone=${encodeURIComponent(currentUser.phoneNumber || "")}`
            );
          } else {
            setPrefill(
              `fullName=${currentUser.displayName}&phone=${encodeURIComponent(
                currentUser.phoneNumber || ""
              )}`
            );
          }
        });
    });
  };

  const reset = (value) => {
    if (!hasEmailPass) {
      setEmail(currentUser.email);
      setNewEmail(currentUser.email);
    } else {
      setEmail(null);
      setNewEmail(null);
    }

    setEmailErr(null);
    setNewEmailErr(null);
    setPassword(null);
    setPassErr(null);
    setNewPass(null);
    setNewPassErr(null);
    setProfileSubmitted(false);
    if (value === 0) {
      getPrefill();
    } else {
      setPrefill(null);
    }
  };

  const removeGoogleAccount = async () => {
    if (!(hasGoogle && hasEmailPass)) {
      return;
    }

    setLoading(true);
    loadingAlert();

    const headers = new Headers();

    headers.append(
      "Authorization",
      `Bearer ${await currentUser.getIdToken(true)}`
    );

    try {
      const response = await fetch(baseAPI + "/google/remove-google", {
        headers,
      });

      if (response.status === 200) {
        const result = await response.json();

        if (result.hasRefresh) {
          if (result.revoked) {
            await currentUser.unlink("google.com");

            successAlert(
              "Google account successfully removed!",
              "Your Google account was successfully removed."
            );
          } else {
            errorAlert(
              "Something went wrong...",
              "Could not remove your Google account. Please try again later."
            );
            console.log(result);
          }
        } else {
          await currentUser.unlink("google.com");
          successAlert(
            "Google account successfully removed!",
            "Your Google account was successfully removed."
          );
        }

        setLoading(false);
      } else {
        errorAlert("Something went wrong...");
        setLoading(false);
      }
    } catch (e) {
      errorAlert("Something went wrong...", e.message);
      setLoading(false);
      console.log(e);
    }
  };

  const loginConfirm = (title, text, options) => {
    const nextFn = options.nextFn ? options.nextFn : hideAlert;

    warningAlert(
      title,
      <LoginConfirm
        currentUser={currentUser}
        loginHint={loginHint}
        loadingAlert={loadingAlert}
        hideAlert={hideAlert}
        title={title}
        text={text}
        nextFn={nextFn}
        options={options}
      />,
      () => console.log("this shouldn't be activated"),
      {
        hideCancel: true,
        hideConfirm: true,
      }
    );
  };

  const onFormSubmit = (e) => {
    if (e) e.preventDefault();

    if (!email || !password) {
      if (!email) setEmailErr("Email address is required.");
      if (!password) setPassErr("Password is required");

      return;
    }

    setLoading(true);
    loadingAlert();

    const credential = emailProvider.credential(email, password);

    currentUser
      .linkWithCredential(credential)
      .then((userCred) => {
        const user = userCred.user;

        console.log(user);

        if (!user.emailVerified) {
          user
            .sendEmailVerification({
              url: "https://app.createcookbooks.com/books?verified=true",
            })
            .then(() => {
              setCurrentUser(user);
              setLoading(false);
              successAlert(
                "Account linked",
                "Email and password account linked to your Create Cookbooks account. Please verify email."
              );
            })
            .catch((error) => {
              errorAlert("Account linking error", error.message);
              setLoading(false);
            });
        } else {
          setCurrentUser(user);
          setLoading(true);
          loadingAlert();
          successAlert(
            "Account linked",
            "Email and password account linked to your Create Cookbooks account."
          );
        }

        console.log(user);
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          setEmailErr("Email address is invalid.");
          hideAlert();
        } else if (error.code === "auth/weak-password") {
          setPassErr("Password is too weak (less than 6 characters).");
          hideAlert();
        } else if (error.code === "auth/requires-recent-login") {
          loginConfirm(
            "Login with Google",
            "Please sign-in again order to confirm your account and link your email/password.",
            {
              nextFn: onFormSubmit,
              emailPass: hasEmailPass,
              google: hasGoogle,
            }
          );
        } else {
          errorAlert("Account linking error", error.message);
        }

        console.log(error);

        setLoading(false);
      });
  };

  const removeEmailPass = () => {
    setLoading(true);
    loadingAlert();
    currentUser
      .unlink("password")
      .then(() => {
        successAlert("Email and password login removed");
        setLoading(false);
      })
      .catch((err) => {
        errorAlert("Something went wrong...", err.message);
        setLoading(false);
      });
  };

  const emailPassForm = (onSubmit, buttonText) => (
    <form className={classes.form} onSubmit={onSubmit}>
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Email className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          placeholder: "Email...",
          defaultValue: !hasEmailPass ? currentUser.email : null,
          onChange: (e) => {
            if (emailErr) setEmailErr(null);
            setEmail(e.target.value);
          },
          disabled: loading,
        }}
        error={!!emailErr}
        helperText={emailErr}
      />
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
            </InputAdornment>
          ),
          placeholder: "Password...",
          onChange: (e) => {
            if (passErr) setPassErr(null);
            setPassword(e.target.value);
          },
          type: "password",
          disabled: loading,
        }}
        error={!!passErr}
        helperText={passErr}
      />

      <div className={classes.center} style={{ marginTop: "3vh" }}>
        <Button color="info" type="submit" disabled={loading}>
          {buttonText}
        </Button>
      </div>
    </form>
  );

  const updateEmail = () => {
    if (!newEmail) return setEmailErr("Email address is required.");

    setLoading(true);
    loadingAlert();

    const oldEmail = currentUser.email;

    currentUser
      .updateEmail(newEmail)
      .then(() => {
        currentUser.getIdToken(true).then((token) => {
          const headers = new Headers();
          headers.append("Authorization", `Bearer ${token}`);
          fetch(
            `${baseAPI}/users/change-email-mailerlite/${encodeURIComponent(
              oldEmail
            )}`,
            { headers }
          )
            .then(() => {
              successAlert(
                "Update email success",
                `Your email has been updated. 
              We have sent a verification email in order to continue using our services.`
              );
              setLoading(false);
            })
            .catch((err) => {
              successAlert(
                "Update email success",
                `Your email has been updated. 
              We have sent a verification email in order to continue using our services.`
              );
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          setNewEmailErr("Email address is invalid.");
          hideAlert();
        } else if (error.code === "auth/requires-recent-login") {
          loginConfirm(
            "Login to confirm",

            "Please sign-in again order to confirm your account and update your email.",
            {
              nextFn: updateEmail,
              emailPass: hasEmailPass,
              google: hasGoogle,
            }
          );
        } else {
          errorAlert("Update email error", error.message);
          setNewEmailErr(error.message);
        }

        console.log(error);

        setLoading(false);
      });
  };

  const updatePassword = () => {
    console.log("THIS IS A TEST");
    if (!newPass) {
      setNewPassErr("Password is required");
      console.log("no password");
      return;
    }

    setLoading(true);
    loadingAlert();

    currentUser
      .updatePassword(newPass)
      .then(() => {
        successAlert(
          "Update password success",
          `Your password has been updated.`
        );
        setLoading(false);
      })
      .catch((error) => {
        if (error.code === "auth/weak-password") {
          setNewPassErr("Password is too weak (less than 6 characters).");
          hideAlert();
        } else if (error.code === "auth/requires-recent-login") {
          loginConfirm(
            "Login to confirm",

            "Please sign-in again order to confirm your account and update your email.",
            {
              nextFn: updatePassword,
              emailPass: hasEmailPass,
              google: hasGoogle,
            }
          );
        } else {
          errorAlert("Update password error", error.message);
          setNewPassErr(error.message);
        }

        console.log(error);
        setLoading(false);
      });
  };

  const handleSubmission = async (submission) => {
    try {
      loadingAlert();
      setLoading(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const response = await fetch(`${baseAPI}/users/update-profile`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        const formKeys = {
          fullName: "displayName",
          profilePicture: "photoURL",
        };

        let updateInstant = {};

        for (let i = 0; i < submission.data.length; i++) {
          const entry = submission.data[i];
          const key = formKeys[entry.custom_key];
          if (key && entry.value !== currentUser[key]) {
            updateInstant[key] = entry.value || currentUser[key];
          }
        }

        //await currentUser.updateProfile(updateInstant);

        await currentUser.reload();

        setProfileSubmitted(true);
        setLoading(false);
        hideAlert();
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setPrefill(null);
      setLoading(false);
      setProfileSubmitted(false);
      errorAlert(
        "There was a problem submitting...",
        "We have saved your recipe. Please try submitting again. Error ID: " +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${encodeURIComponent(value)}`;
        })
        .join("&")}`
    );
  };

  return (
    <div className={classes.container} style={{ width: "100%" }}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <h2 className={classes.title} style={{ textAlign: "center" }}>
            My account
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <CustomTabs
            headerColor="info"
            //plainTabs={true}
            value={0}
            fullWidth={true}
            onChange={reset}
            tabs={[
              {
                tabName: "Update Profile",
                tabIcon: Face,
                tabContent: (
                  <div style={{ visibility: loading ? "hidden" : "visible" }}>
                    <Paperform
                      prefill={prefill}
                      hasPrefill={true}
                      paperFormID="cc-my-account"
                      onSubmission={handleSubmission}
                    />

                    {profileSubmitted ? (
                      <div style={{ marginTop: "3rem", textAlign: "center" }}>
                        <Button
                          onClick={() => {
                            setPrefill(null);
                            reset(0);
                          }}
                        >
                          Back
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ),
              },
              {
                tabName: "Link Accounts",
                tabIcon: LinkIcon,
                tabContent: (
                  <Card plain>
                    {hasGoogle ? (
                      <Heading
                        textAlign="center"
                        title="Your Google account is already linked"
                        category={
                          <span>
                            Your Google acount <b>{googleEmail}</b> is already
                            linked.
                            <br />
                            If you wish to change the Google account associated,
                            please click the remove account button below.
                          </span>
                        }
                      />
                    ) : (
                      <Heading
                        textAlign="center"
                        title="Link your Google account"
                        category={
                          <span>
                            Before selecting your template you must connect your
                            account with a valid google account.
                            <br />
                            Please click the button, login or create a Google
                            account and follow the prompts.
                          </span>
                        }
                      />
                    )}
                    <div style={{ textAlign: "center" }}>
                      {hasGoogle ? (
                        <>
                          <Button
                            color="danger"
                            //className={classes.marginRight}
                            onClick={() =>
                              hasGoogle && hasEmailPass
                                ? warningAlert(
                                    "Are you sure?",
                                    ` 
                              Removing your Google account from your Create\n
                              Cookbooks account will also remove access to your
                              Cookbook Google Slides. In order to regain access,
                              you must link another Google account.`,
                                    removeGoogleAccount
                                  )
                                : errorAlert(
                                    "Requires email and password account",
                                    `In order to remove your Google account, your Create Cookbooks account requires an email and password account in order to remove your Google account.`
                                  )
                            }
                          >
                            <Close className={classes.icons} /> Remove Google
                            account
                          </Button>
                        </>
                      ) : (
                        <GoogleLink
                          currentUser={currentUser}
                          setCurrentUser={setCurrentUser}
                          loginHint={loginHint}
                          loginSuccess={() => setForceHasGoogle(true)}
                        />
                      )}
                    </div>
                    <div style={{ marginTop: "5vh" }}>
                      <Heading
                        textAlign="center"
                        title={
                          hasEmailPass
                            ? "Remove email and password account"
                            : "Link email and password account"
                        }
                        category={
                          hasEmailPass
                            ? "Remove your email and password account by clicking the button below."
                            : "Add an email and password login for your Create Cookbooks account."
                        }
                      />
                      <div style={{ textAlign: "center" }}>
                        {hasEmailPass ? (
                          <Button
                            color="danger"
                            //className={classes.marginRight}
                            onClick={() =>
                              currentUser.providerData.length > 1
                                ? warningAlert(
                                    "Are you sure?",
                                    ` 
                              Removing your Email and Password account will leave Google Sign in as your only login option.`,
                                    removeEmailPass
                                  )
                                : errorAlert(
                                    "Requires email and password account",
                                    `You cannot remove your email and password login as it is your only way of loggin in. 
                                    To remove it, please link a Google account to your Create Cookbooks account.
                                    `
                                  )
                            }
                          >
                            <Close className={classes.icons} /> Remove Email and
                            Password Account
                          </Button>
                        ) : (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={6}>
                              {emailPassForm(
                                onFormSubmit,
                                "Create email and password account"
                              )}
                            </GridItem>
                          </GridContainer>
                        )}
                      </div>
                    </div>
                  </Card>
                ),
              },

              {
                tabName: "Account settings",
                tabIcon: SettingsIcon,
                tabContent: (
                  <div style={{ textAlign: "center" }}>
                    <Heading
                      textAlign="center"
                      title="Update email address"
                      category={
                        <span>
                          Update your email for contacting. This will not change
                          your Google email for login but WILL change your email
                          and password email login.
                        </span>
                      }
                    />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={8} md={6}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Email...",
                            defaultValue: currentUser.email,
                            onChange: (e) => {
                              if (newEmailErr) setNewEmailErr(null);
                              setNewEmail(e.target.value);
                            },
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                if (newEmailErr) setNewEmailErr(null);
                                updateEmail();
                              }
                            },
                            disabled: loading,
                          }}
                          error={!!newEmailErr}
                          helperText={newEmailErr}
                        />
                        <div
                          className={classes.center}
                          style={{ marginTop: "3vh" }}
                        >
                          <Button
                            color="info"
                            onClick={updateEmail}
                            disabled={loading}
                          >
                            Update Email
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer>
                    {hasEmailPass ? (
                      <div style={{ marginTop: "5vh" }}>
                        <Heading
                          textAlign="center"
                          title="Update password"
                          category={
                            <span>
                              Update your password if you use an email and
                              password login.
                            </span>
                          }
                        />
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={8} md={6}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon
                                      className={classes.inputAdornmentIcon}
                                    >
                                      lock_outline
                                    </Icon>
                                  </InputAdornment>
                                ),
                                placeholder: "Password...",
                                onChange: (e) => {
                                  if (newPassErr) setNewPassErr(null);
                                  setNewPass(e.target.value);
                                },
                                onKeyDown: (e) => {
                                  if (e.key === "Enter") {
                                    if (newPassErr) setNewPassErr(null);
                                    updatePassword();
                                  }
                                },
                                type: "password",
                                disabled: loading,
                              }}
                              error={!!newPassErr}
                              helperText={newPassErr}
                            />
                            <div
                              className={classes.center}
                              style={{ marginTop: "3vh" }}
                            >
                              <Button
                                color="info"
                                onClick={updatePassword}
                                disabled={loading}
                              >
                                Update password
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : null}
                  </div>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
