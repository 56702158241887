import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GoogleButton from "react-google-button";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import { app, provider } from "base.js";

const useStyles = makeStyles({
  btn: {
    margin: "0 auto",
  },
});

export default function GoogleConfirm(props) {
  const { overrideLoading, setCurrentUser, onLoad, onLoading } = props;

  const [loading, setLocalLoading] = useState(false);

  const classes = useStyles();

  const setLoading = (bool) => {
    if (onLoading) onLoading(bool);
    if (overrideLoading) {
      overrideLoading(bool);
    } else {
      setLocalLoading(bool);

      if (onLoad) {
        onLoad(bool);
      }
    }
  };

  const loginWithGoogle = () => {
    setLoading(true);
    app
      .auth()
      .signInWithPopup(provider)
      .then(async ({ user }) => {
        setLoading(false);
        setCurrentUser(user, true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return loading ? (
    <CustomLinearProgress color="gray" />
  ) : (
    <GoogleButton
      className={classes.btn}
      type="light"
      onClick={loginWithGoogle}
    />
  );
}
