import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import Icon from "@material-ui/core/Icon";

import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Divider from "@material-ui/core/Divider";

import CircularProgress from "@material-ui/core/CircularProgress";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

import defaultThumb from "assets/img/cover-default-book.svg";

import { app, baseAPI } from "base.js";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);

const roles = {
  contributor:
    "Contributors can submit, upload personal images and edit their own recipes.",
  editor: "Editors can edit all submitted recipes in the recipe library.",
  designer:
    "Designers have access to the cookbook template and can help with the design layout.",
};

export default function Invite(props) {
  const classes = useStyles();

  const {
    currentUser,
    setCurrentUser,
    history,
    location,
    errorAlert,
    defaultBook,
  } = props;

  const [name, setName] = useState(null);
  const [nameErr, setNameErr] = useState(null);

  const [emailErr, setEmailErr] = useState(null);

  const [password, setPassword] = useState(null);
  const [passErr, setPassErr] = useState(null);

  const [terms, setTerms] = useState(false);
  const [termsErr, setTermsErr] = useState(null);

  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(null);

  const [preload, setPreload] = useState(true);

  const [expired, setExpired] = useState(false);

  // const [expired, setExpired] = useState(false);
  const [inviteCodes, setInviteCodes] = useState(null);
  const [inviteData, setInviteData] = useState(null);

  const email = inviteData ? inviteData.email : null;

  const [confirmEmail, setConfirmEmail] = useState(null);

  const bookRole = inviteData
    ? inviteData.bookRole[0].toUpperCase() + inviteData.bookRole.slice(1)
    : null;

  useEffect(() => {
    const search = new URLSearchParams(location.search);

    if (search) {
      const query = new URLSearchParams(search);

      const bookId = query.get("bk"); //bookId
      const code = query.get("cd"); //oob

      if (bookId && code) {
        if (currentUser) {
          currentUser.getIdTokenResult(true).then((idToken) => {
            console.log(idToken);
            if (idToken.claims[bookId]) {
              errorAlert(
                "You already have access to this book",
                "Visit My Books to go into your shared cookbook."
              );
              history.push("/books");
            } else {
              setInviteCodes({ bookId, code });
            }
          });
        } else {
          setInviteCodes({ bookId, code });
        }
      } else {
        if (currentUser) {
          history.push("/share");
        } else {
          history.push("/register");
        }
      }
    } else {
      if (currentUser) {
        history.push("/share");
      } else {
        history.push("/register");
      }
    }

    return () => setInviteCodes(null);
  }, []);

  useEffect(() => {
    if (inviteCodes) {
      const { bookId, code } = inviteCodes;

      fetch(`${baseAPI}/users/retrieve-invite/${bookId}/${code}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((result) => {
          if (result) {
            if (result.expired) {
              setExpired(true);
            } else {
              setInviteData(result.bookData);
            }
          }

          setPreload(false);
        })
        .catch((err) => {
          console.log(err);
          setPreload(false);
        });
    }
  }, [inviteCodes]);

  useEffect(() => {
    if (currentUser && inviteCodes) {
      currentUser.reload().then(() => {
        if (currentUser[inviteCodes.bookId]) {
          history.push("/books");
        } else {
          if (inviteData && inviteData.email === currentUser.email) {
            confirmInvitedEmail();
          }
        }
      });
    }
  }, [currentUser, inviteCodes, inviteData]);

  const handleToggle = (value) => {
    if (termsErr) setTermsErr(null);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      setTerms(true);
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setTerms(false);
    }
    setChecked(newChecked);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!name || !email || !password || !terms) {
      if (!name) setNameErr("Name is required.");
      if (!email) setEmailErr("Email address is required.");
      if (!password) setPassErr("Password is required");
      if (!terms)
        setTermsErr(
          "Please tick the above checkbox to agree to the terms and conditions."
        );
      setLoading(false);
      return;
    }

    app
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        setCurrentUser(user);

        user.updateProfile({ displayName: name });
        user.getIdToken(true).then((token) => {
          const headers = new Headers();

          headers.append("Authorization", `Bearer ${token}`);

          fetch(
            `${baseAPI}/users/signup/invite/${inviteCodes.bookId}/${inviteCodes.code}`,
            { headers }
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.userCreated) {
                user.reload().then(() => {
                  setCurrentUser(user);
                  history.push("/books");
                });
              } else {
                user.sendEmailVerification({
                  url: "https://app.createcookbooks.com/books?verified=true",
                });
                history.push("/plans");
              }
            })
            .catch((e) => {
              setLoading(false);
              console.log(e);
              user.sendEmailVerification({
                url: "https://app.createcookbooks.com/books?verified=true",
              });
              history.push("/plans");
            });
        });
      })
      .catch((err) => {
        if (err.code === "auth/email-already-in-use") {
          return app
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((providers) => {
              if (providers.length === 1 && providers[0] === "google.com") {
                setEmailErr(
                  "Email address is already connected to a Google account. Please use the Google Sign In button above this form to continue."
                );
              } else {
                setEmailErr("Email address is already in use.");
              }

              setLoading(false);
            });
        }

        if (err.code === "auth/invalid-email") {
          setEmailErr("Email address is invalid.");
        }

        if (err.code === "auth/weak-password") {
          setPassErr("Password is too weak (less than 6 characters).");
        }

        setLoading(false);
      });
  };

  const confirmInvitedEmail = (confirmSubmittedEmail) => {
    if (
      !(
        currentUser &&
        inviteCodes &&
        !currentUser[inviteCodes.bookId] &&
        inviteData
      )
    ) {
      errorAlert(
        "Something went wrong",
        "Please email support@createcookbooks.com"
      );
      return;
    }

    setLoading(true);

    currentUser.getIdToken(true).then((token) => {
      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);

      console.log(email);

      fetch(
        `${baseAPI}/users/signup/invite/${inviteCodes.bookId}/${
          inviteCodes.code
        }${confirmSubmittedEmail ? `?emailConfirm=${confirmEmail}` : ""}`,
        { headers }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.userCreated) {
            currentUser.reload().then(() => {
              history.push("/books");
            });
          } else {
            setLoading(false);
            errorAlert(
              "Email not confirmed",
              "Could not confirm email address. Please enter the email in which you received the invite and try again."
            );
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          errorAlert(
            "Email not confirmed",
            "Could not confirm email address. Please enter the email in which you received the invite and try again."
          );
        });
    });
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            {preload ? (
              <div style={{ textAlign: "center" }}>
                <CustomLinearProgress />
              </div>
            ) : null}

            {inviteData && !expired ? (
              <>
                <div style={{ margin: "0 auto" }}>
                  <h2
                    className={classes.cardTitle}
                    style={{
                      maxWidth: "50rem",
                      paddingLeft: "4rem",
                      paddingRight: "4rem",
                    }}
                  >
                    {inviteData.book.owner} has invited you to create something
                    special
                  </h2>
                </div>
                <Divider style={{ marginTop: "3vh" }} />
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={5}>
                      <Card plain>
                        <CardBody>
                          <div style={{ textAlign: "center" }}>
                            <img
                              style={{ borderRadius: "5px" }}
                              src={inviteData.book.thumbnail || defaultThumb}
                              height="250px"
                              alt={`Book thumbnail for - ${inviteData.book.title}`}
                            />
                            <h4>{inviteData.book.title}</h4>
                            <Tooltip
                              title={roles[inviteData.bookRole]}
                              placement="bottom"
                            >
                              <Chip
                                label={bookRole}
                                clickable
                                onClick={() =>
                                  window.open(
                                    "https://createcookbooks.zendesk.com/hc/en-au"
                                  )
                                }
                              />
                            </Tooltip>
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>

                    <GridItem xs={12} sm={8} md={5}>
                      {currentUser &&
                      inviteData &&
                      currentUser.email === inviteData.email &&
                      loading ? (
                        <div style={{ textAlign: "center" }}>
                          <h3>Loading...</h3>
                          <div style={{ marginTop: "30px" }}>
                            <CircularProgress />
                          </div>
                        </div>
                      ) : currentUser &&
                        inviteCodes &&
                        !currentUser[inviteCodes.bookId] &&
                        inviteData &&
                        !password ? (
                        <div style={{ textAlign: "center" }}>
                          <h3>Confirm your invited email</h3>
                          <p>
                            Enter the email address that the invite link was
                            sent to below.
                          </p>

                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              placeholder: "Email...",
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Email
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              disabled: loading,
                              onChange: (e) => {
                                setConfirmEmail(e.target.value);
                              },
                            }}
                            error={!!emailErr}
                            helperText={emailErr}
                          />
                          {loading ? (
                            <div style={{ marginTop: "30px" }}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <Button
                              color="info"
                              onClick={() => confirmInvitedEmail(true)}
                              disabled={loading}
                              style={{ marginTop: "30px" }}
                            >
                              Confirm
                            </Button>
                          )}
                        </div>
                      ) : (
                        <div>
                          <h3 style={{ textAlign: "center" }}>
                            Sign up to continue
                          </h3>
                          <form
                            className={classes.form}
                            onSubmit={onFormSubmit}
                          >
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                placeholder: "Name...",
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Face
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),

                                onChange: (e) => {
                                  if (nameErr) setNameErr(null);
                                  setName(e.target.value);
                                },
                                disabled: loading,
                              }}
                              error={!!nameErr}
                              helperText={nameErr}
                            />
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                defaultValue: email,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Email
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                                disabled: loading,
                              }}
                              error={!!emailErr}
                              helperText={emailErr}
                            />
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon
                                      className={classes.inputAdornmentIcon}
                                    >
                                      lock_outline
                                    </Icon>
                                  </InputAdornment>
                                ),
                                placeholder: "Password...",
                                onChange: (e) => {
                                  if (passErr) setPassErr(null);
                                  setPassword(e.target.value);
                                },
                                type: "password",
                                disabled: loading,
                              }}
                              error={!!passErr}
                              helperText={passErr}
                            />
                            <FormControl error={!!termsErr}>
                              <FormControlLabel
                                classes={{
                                  root: classes.checkboxLabelControl,
                                  label: classes.checkboxLabel,
                                }}
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={() => {
                                      handleToggle(1);
                                    }}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                    disabled={loading}
                                  />
                                }
                                label={
                                  <span>
                                    I agree to the{" "}
                                    <a
                                      href="https://www.createacookbook.com.au/tos"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      terms and conditions
                                    </a>
                                    .
                                  </span>
                                }
                              />
                              {termsErr ? (
                                <FormHelperText>{termsErr}</FormHelperText>
                              ) : null}
                            </FormControl>

                            <div className={classes.center}>
                              {loading ? (
                                <div style={{ marginTop: "10px" }}>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <Button
                                  color="info"
                                  type="submit"
                                  disabled={loading}
                                >
                                  {" "}
                                  Get Started
                                </Button>
                              )}
                            </div>
                          </form>
                        </div>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </>
            ) : !preload ? (
              <div style={{ margin: "0 auto" }}>
                <h2
                  className={classes.cardTitle}
                  style={{
                    maxWidth: "50rem",
                    paddingLeft: "4rem",
                    paddingRight: "4rem",
                  }}
                >
                  {expired ? "Your invite has expired" : "No invite found"}
                </h2>
                {expired ? (
                  <p
                    style={{
                      textAlign: "center",
                      padding: "10px 30px",
                    }}
                  >
                    Your invite link has expired. Ask the book owner to invite
                    you again. In the meantime create a free account today!
                  </p>
                ) : null}
                <p style={{ textAlign: "center" }}>
                  <Link to="/register">Click here</Link> to register for free.
                </p>
              </div>
            ) : null}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
