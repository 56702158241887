import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import IconButton from "@material-ui/core/IconButton";

import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import LaunchIcon from "@material-ui/icons/Launch";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Icon from "@material-ui/core/Icon";

import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Layout(props) {
  const {
    defaultBook,
    openWindowCover,
    openWindowInside,
    openWindowPages,
    openWindowSpare,
    hideAlert,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    return () => hideAlert();
  }, []);

  return (
    <div>
      <GridContainer justify="space-evenly">
        <GridItem xs={12} sm={12} md={12} lg={7}>
          <Heading
            title={`${defaultBook.title} layout`}
            category={
              <a
                href="javascript:void(0)"
                onClick={() =>
                  window.Beacon("article", "6337d32f05ba014734a36a22", {
                    type: "sidebar",
                  })
                }
              >
                Select new fonts, add or replace images and re-organise pages.
              </a>
            }
            textAlign="center"
          />
          <GridContainer justify="center">
            <GridItem style={{ minWidth: "265px", maxWidth: "285px" }}>
              <Card>
                <CardHeader
                  color="info"
                  stats
                  icon
                  style={{ textAlign: "center" }}
                >
                  <CardIcon color="info">
                    <Icon>book</Icon>
                  </CardIcon>

                  <h2
                    className={classes.cardTitle}
                    style={{ paddingTop: "10px" }}
                  >
                    Cover
                  </h2>
                </CardHeader>
                <CardBody style={{ paddingBottom: "0" }}>
                  <p className={classes.cardCategory}>
                    The cover file consists of a total of 4 pages. Each page can
                    be used, except for hardcover.
                  </p>
                  <p className={classes.cardCategory}>
                    Details can be found inside the cover file or refer to
                    Support.
                  </p>
                </CardBody>
                <CardFooter stats>
                  <Button
                    onClick={openWindowCover}
                    color="info"
                    style={{ width: "100%" }}
                  >
                    Open
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem style={{ minWidth: "265px", maxWidth: "285px" }}>
              <Card>
                <CardHeader
                  color="info"
                  stats
                  icon
                  style={{ textAlign: "center" }}
                >
                  <CardIcon color="info">
                    <Icon>menu_book</Icon>
                  </CardIcon>

                  <h2
                    className={classes.cardTitle}
                    style={{ paddingTop: "10px" }}
                  >
                    Inside
                  </h2>
                </CardHeader>
                <CardBody style={{ paddingBottom: "0" }}>
                  <p className={classes.cardCategory}>
                    Minimum book size from 24 pages and increase in increments
                    of 4 pages at a time.
                  </p>
                  <p className={classes.cardCategory}>
                    One sheet of paper has 2 printed sides. Each side equals one
                    page.
                  </p>
                </CardBody>
                <CardFooter stats>
                  <Button
                    onClick={() => openWindowInside()}
                    color="info"
                    style={{ width: "100%" }}
                  >
                    Open
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem style={{ minWidth: "265px", maxWidth: "285px" }}>
              <Card>
                <CardHeader
                  color="secondary"
                  stats
                  icon
                  style={{ textAlign: "center" }}
                >
                  <CardIcon color="secondary">
                    <Icon>note_add</Icon>
                  </CardIcon>

                  <h2
                    className={classes.cardTitle}
                    style={{ paddingTop: "10px" }}
                  >
                    Design
                  </h2>
                </CardHeader>
                <CardBody style={{ paddingBottom: "0" }}>
                  <p className={classes.cardCategory}>
                    There are lots of Design element pages to choose from when
                    doing your layout.
                  </p>
                  <p className={classes.cardCategory}>
                    These include image collages, collages with text, tips and
                    icons and much more.
                  </p>
                </CardBody>
                <CardFooter stats>
                  <Button onClick={openWindowPages} style={{ width: "100%" }}>
                    Open
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem style={{ minWidth: "265px", maxWidth: "285px" }}>
              <Card>
                <CardHeader
                  color="secondary"
                  stats
                  icon
                  style={{ textAlign: "center" }}
                >
                  <CardIcon color="secondary">
                    <Icon>auto_stories</Icon>
                  </CardIcon>

                  <h2
                    className={classes.cardTitle}
                    style={{ paddingTop: "10px" }}
                  >
                    Spare
                  </h2>
                </CardHeader>
                <CardBody style={{ paddingBottom: "0" }}>
                  <p className={classes.cardCategory}>
                    Spare pages include pages showcased in the template gallery
                    on our website. These pages can be added to your cookbook.
                  </p>
                  <p className={classes.cardCategory}>
                    To add spare pages to your cookbook, refer to Support.
                  </p>
                </CardBody>
                <CardFooter stats>
                  <Button onClick={openWindowSpare} style={{ width: "100%" }}>
                    Open
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>

        {defaultBook.chapters && defaultBook.chaptersArray ? (
          <GridItem xs={8} sm={5} md={5} lg={4}>
            <Heading
              title="Chapters"
              category={
                <Link
                  to={{
                    pathname: "/guide",
                    state: {
                      scrollTo: "chapters",
                    },
                  }}
                >
                  View your chapters inside your book
                </Link>
              }
              textAlign="center"
            />
            <TableContainer component={Paper} style={{ marginTop: "30px" }}>
              <Table aria-label="chapters table">
                <TableHead>
                  <TableRow>
                    <TableCell>Chapters</TableCell>
                    <TableCell align="center">Open Chapter</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {defaultBook.chaptersArray.map((chapterId) => (
                    <TableRow key={chapterId}>
                      <TableCell component="th" scope="row">
                        <h4>{defaultBook.chapters[chapterId].name}</h4>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          disabled={!!defaultBook.importing}
                          onClick={() =>
                            openWindowInside(
                              defaultBook.chapters[chapterId].name,
                              defaultBook.chapters[chapterId].slideId
                            )
                          }
                        >
                          <LaunchIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        ) : null}
      </GridContainer>
    </div>
  );
}
