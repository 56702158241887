/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";

import { makeStyles } from "@material-ui/core";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import { app } from "base.js";

const styles = {
  fullPage: {
    height: "100%!important",
    "& > iframe": {
      height: "100%!important",
      overflow: "visible",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Paperform(props) {
  const {
    paperFormID,
    prefill,
    hasPrefill,
    hasAuth,
    onLoad,
    onSubmission,
    disableLoadingBar,
    fullPage,
  } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const paperFormDiv = useRef(null);

  const handleSubmission = useCallback(
    (e) => {
      if (onSubmission) {
        onSubmission(e.detail);
      }
    },
    [onSubmission]
  );

  useEffect(() => {
    if (paperFormDiv && paperFormDiv.current) {
      console.log(paperFormDiv);
      paperFormDiv.current.addEventListener(
        "PaperformSubmission",
        handleSubmission
      );
    }

    return () => {
      if (paperFormDiv && paperFormDiv.current) {
        paperFormDiv.current.removeEventListener(
          "PaperformSubmission",
          handleSubmission
        );
      }
    };
  }, [paperFormDiv.current]);

  useEffect(() => {
    if (prefill || hasAuth) {
      if (paperFormDiv.current) {
        setTimeout(() => {
          if (paperFormDiv.current) {
            const iframe = paperFormDiv.current.querySelector("iframe");

            iframe.onload = function () {
              onIframeLoad();
            };
          }
        }, 250);
      }
    } else {
      setLoading(true);
    }
  }, [prefill]);

  const onIframeLoad = () => {
    if (loading) {
      setLoading(false);
    }

    if (onLoad) onLoad();
  };

  return (
    <>
      {loading && !disableLoadingBar ? (
        <div style={{ height: "4px" }}>
          <CustomLinearProgress color="info" />
        </div>
      ) : null}
      {hasPrefill && prefill ? (
        <div
          className={fullPage ? classes.fullPage : ""}
          data-prefill={fullPage ? "embed=0&" + prefill : prefill}
          ref={paperFormDiv}
          data-paperform-id={paperFormID}
          data-spinner={true}
        />
      ) : null}
      {!hasPrefill ? (
        <div
          className={fullPage ? classes.fullPage : ""}
          data-paperform-id={paperFormID}
          data-prefill={fullPage ? "embed=0&" + prefill : prefill}
          data-spinner={true}
          ref={paperFormDiv}
        />
      ) : null}
    </>
  );
}
