import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import error from "assets/img/clint-mckoy.jpg";

import books from "assets/img/bg/books.jpg";
import plans from "assets/img/bg/plans.jpg";
import register from "assets/img/bg/register.jpg";
import login from "assets/img/bg/login.jpg";
import myaccount from "assets/img/bg/my-account.jpg";

const useStyles = makeStyles(styles);

export default function Auth(props) {
  const { ...rest } = props;

  const classes = useStyles();

  const { currentUser, location } = props;

  useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  useEffect(() => {
    if (location && location.pathname) {
      /*window.zE("webWidget", "updatePath", {
        title: `Dashboard - ${location.pathname}`,
        url: `https://app.createcookbooks.com${location.pathname}`,
      });*/

      window.Beacon("event", {
        type: "page-viewed",
        url: `https://app.createcookbooks.com${location.pathname}`,
        title: `Dashboard - ${location.pathname}`,
      });

      const routeName = getActiveRoute(routes);

      window.document.title = `${routeName} – Create Cookbooks`;
    }
  }, [location.pathname]);

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }

      if (route.layout === "auth") {
        return (
          <Route
            exact
            path={route.path}
            render={(rest) => {
              if (route.pageRequirements && route.pageRequirements.redirect) {
                return <Redirect to={route.pageRequirements.redirect} />;
              }

              if (
                !route.noAuthRedirect ||
                (currentUser && route.noAuthRedirect)
              ) {
                return <route.component {...rest} {...props} />;
              }
              return <Redirect to={route.noAuthRedirect || "/login"} />;
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBgImage = () => {
    if (
      window.location.pathname.indexOf("/register") !== -1 ||
      window.location.pathname.indexOf("/invite") !== -1 ||
      window.location.pathname.indexOf("/verify") !== -1
    ) {
      return register;
    } else if (
      window.location.pathname.indexOf("/login") !== -1 ||
      window.location.pathname.indexOf("/forgot-password") !== -1
    ) {
      return login;
    } else if (window.location.pathname.indexOf("/books") !== -1) {
      return books;
    } else if (window.location.pathname.indexOf("/packages") !== -1) {
      return plans;
    } else if (window.location.pathname.indexOf("/my-account") !== -1) {
      return myaccount;
    } else {
      return error;
    }
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Create Cookbooks";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname === routes[i].path) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <div>
      {props.alert}
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect to="/login" />
          </Switch>

          {false ? <Footer white /> : null}
        </div>
      </div>
    </div>
  );
}
