import React, { useState, useEffect } from "react";

import Button from "components/CustomButtons/Button.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import AuthIframePopup from "components/Iframe/AuthIframePopup.js";

import Alert from "@material-ui/lab/Alert";

import { app, baseAPI } from "base.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Link } from "react-router-dom";

export default function Preview(props) {
  const {
    firebaseUser,
    defaultBook,
    currentUser,
    loadingAlert,
    successAlert,
    errorAlert,
    warningAlert,
    googleAlert,
    hideAlert,
  } = props;

  const [flipbook, setFlipbook] = useState(null);
  const [viewFlipbook, setViewFlipbook] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openSettings, setOpenSettings] = useState(false);

  const [progress, setProgress] = useState(null);
  const [generateStart, setGenerateStart] = useState(true);

  const [fast, setFast] = useState(false);
  const [sound, setSound] = useState(false);
  const [settingsUpdate, setSettingsUpdate] = useState(false);

  const handleSettingsUpdate = () => {
    setLoading(true);

    const settingsError = () => {
      errorAlert(
        "Couldn't update settings",
        "We could not update your flipbook settings. Please try again"
      );
      setLoading(false);
    };

    currentUser
      .getIdToken(true)
      .then((token) => {
        const headers = new Headers();

        headers.append("Authorization", `Bearer ${token}`);

        console.log(firebaseUser.defaultBook);

        fetch(
          `${baseAPI}/flipbook/update-settings?bookId=${
            firebaseUser.defaultBook
          }&fast=${fast}&sound=${!sound}`,
          {
            headers,
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              settingsError();
            } else {
              setSettingsUpdate(false);
            }

            setLoading(false);
          })
          .catch((err) => settingsError());
      })
      .catch((err) => settingsError());
  };

  useEffect(() => {
    const flipbookProgressRef = app
      .database()
      .ref(`books/${firebaseUser.defaultBook}/flipbookProgress`);

    flipbookProgressRef.on("value", (snapshot) => {
      const data = snapshot.val();
      setProgress(data);
      setGenerateStart(false);
    });

    return () => {
      hideAlert();
      flipbookProgressRef.off();
    };
  }, []);

  useEffect(() => {
    if (!defaultBook.isGenerating) {
      setLoading(true);
      app
        .firestore()
        .collection("flipbook")
        .doc(firebaseUser.defaultBook)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const flipData = doc.data();

            if (flipData.settings && flipData.settings[currentUser.uid]) {
              const userSettings = flipData.settings[currentUser.uid];
              setFast(!!userSettings.fast);
              setSound(userSettings.sound === false);
            }

            setFlipbook(flipData);
          }

          setLoading(false);
          setGenerateStart(true);
        });
    } else {
      setGenerateStart(true);
      setLoading(false);
    }
  }, [defaultBook.isGenerating]);

  useEffect(() => {
    if (flipbook !== null) setLoading(false);
  }, [flipbook]);

  const googleGenerateAlert = () =>
    googleAlert({
      text:
        "In order to generate a flipbook you must attach your Google Account",
      successText:
        "Your Google Account has been successfully linked and you can now generate a flipbook!",
      successConfirmText: "Generate flipbook",
      nextFn: generateFlipbook,
    });

  const generateFlipbook = async () => {
    if (!firebaseUser.googleRefresh) {
      googleGenerateAlert();
      return;
    }

    loadingAlert();

    const header = new Headers();

    header.append(
      "Authorization",
      `Bearer ${await currentUser.getIdToken(true)}`
    );

    //we now get access tokens from stored refresh tokens!!
    fetch(`${baseAPI}/flipbook/generate/${firebaseUser.defaultBook}`, {
      headers: header,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          errorAlert(
            "Something went wrong...",
            `Your cookbook is NOT being converted into a flipbook.`
          );
        }
      })
      .then((result) => {
        if (result.google_refresh_error) {
          googleGenerateAlert();
        } else {
          successAlert(
            "Generating!",
            `Your cookbook is currently being converted into a flipbook.`
          );
        }
      })
      .catch((err) =>
        errorAlert("There was an error", `Error message: ${err.message}`)
      );
  };

  return (
    <div>
      {viewFlipbook && flipbook ? (
        <AuthIframePopup
          url={`${baseAPI}/flipbook/view-generated/${firebaseUser.defaultBook}`}
          title={`View Flipbook`}
          closeOnSecondLoad={false}
          onClose={() => {
            setViewFlipbook(false);
          }}
          getToken={async () => await currentUser.getIdToken(true)}
          insidePagesIndex={flipbook.insideIndex}
        />
      ) : null}
      <Heading
        textAlign="center"
        title="View your cookbook as a flipbook"
        category={
          <a
            href="javascript:void(0)"
            onClick={() =>
              window.Beacon("article", "62f082fdffff0c4e1783f828", {
                type: "sidebar",
              })
            }
          >
            Flipbook preview is displayed as a printed version of your cookbook
          </a>
        }
      />
      <GridContainer justify="center">
        {flipbook && flipbook.error && !defaultBook.isGenerating ? (
          <Alert severity="error">
            Something went wrong generating your flipbook. Please try again. If
            you keep seeing this please contact our support team.
          </Alert>
        ) : null}

        <GridItem xs={8} sm={10} md={9} lg={7}>
          <Card style={{ textAlign: "center", padding: "15px" }}>
            <CardBody>
              <GridContainer justify="center" alignItems="stretch">
                <GridItem xs={12} sm={6} md={6}>
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    src={defaultBook.thumbnail}
                    alt={`Cookbook thumbnail for ${defaultBook.title}`}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  {flipbook || !loading ? (
                    <GridContainer
                      style={{ height: "100%" }}
                      direction="column"
                      justify="space-between"
                    >
                      <GridItem style={{ margin: "auto 0" }}>
                        {defaultBook.isGenerating ? (
                          <div style={{ padding: "2rem 0" }}>
                            <h6>Generating flipbook preview</h6>
                            {progress ? (
                              <h3>
                                {Math.floor(
                                  (progress.count / progress.total) * 100
                                )}
                                %
                              </h3>
                            ) : null}
                            {progress ? (
                              <CustomLinearProgress
                                variant="determinate"
                                color="info"
                                value={Math.floor(
                                  (progress.count / progress.total) * 100
                                )}
                              />
                            ) : (
                              <CustomLinearProgress color="info" />
                            )}
                            <p>
                              {progress
                                ? `Generating ${progress.type} pages.`
                                : generateStart
                                ? "Starting up generation"
                                : "Finishing up..."}
                            </p>
                          </div>
                        ) : flipbook && !flipbook.error ? (
                          <div style={{ padding: "1rem 0" }}>
                            <h4 style={{ color: "#00acc1" }}>
                              Total inside pages
                            </h4>
                            <p>{flipbook.insidePageCount} pages</p>
                            <h4 style={{ color: "#00acc1" }}>Last generated</h4>
                            <div>
                              {flipbook.lastGenerated.toDate().toLocaleString()}
                            </div>
                          </div>
                        ) : flipbook && flipbook.error ? (
                          <>
                            <h3>There was an error</h3>
                            <p>Please try again</p>
                          </>
                        ) : (
                          <h3>Time to generate your first flipbook!</h3>
                        )}
                      </GridItem>

                      <GridItem>
                        <Button
                          style={{ width: "100%" }}
                          disabled={
                            defaultBook.isGenerating ||
                            loading ||
                            firebaseUser.busy
                          }
                          onClick={() =>
                            warningAlert(
                              `Are you sure you want to ${
                                flipbook ? "re" : ""
                              }generate?`,
                              "Generating a cookbook can take time. Please set aside 5-15 minutes depending on the size of your book.",
                              generateFlipbook
                            )
                          }
                        >
                          {flipbook ? "Reg" : "G"}enerate
                        </Button>
                        <Button
                          style={{ width: "100%" }}
                          size="lg"
                          onClick={() => setViewFlipbook(true)}
                          color="info"
                          disabled={
                            defaultBook.isGenerating ||
                            loading ||
                            (flipbook ? flipbook.error : true)
                          }
                        >
                          View
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <GridContainer
                      style={{ height: "100%" }}
                      direction="column"
                      justify="center"
                    >
                      <GridItem>
                        <CustomLinearProgress color="info" />
                      </GridItem>
                    </GridContainer>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => (flipbook ? setOpenSettings(!openSettings) : null)}
          color="transparent"
        >
          Preview flipbook settings{" "}
          {openSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        {openSettings ? (
          <Card
            style={{ margin: "0 auto", width: "250px", textAlign: "center" }}
          >
            <CardBody>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={fast}
                    onChange={() => {
                      setSettingsUpdate(true);
                      setFast(!fast);
                    }}
                    disabled={loading}
                  />
                }
                label="Disable 3D effect"
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={sound}
                    onChange={() => {
                      setSettingsUpdate(true);
                      setSound(!sound);
                    }}
                    disabled={loading}
                  />
                }
                label="Mute sound"
                labelPlacement="start"
              />
              <div style={{ textAlign: "center" }}>
                <Button
                  size="sm"
                  disabled={loading || !settingsUpdate}
                  onClick={handleSettingsUpdate}
                >
                  Update
                </Button>
              </div>
            </CardBody>
          </Card>
        ) : null}
      </div>
    </div>
  );
}
