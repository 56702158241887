const firebase = window.firebase;

//const baseAPI = "https://api.createcookbooks.com";

const baseAPI =
  window.location.hostname === "localhost"
    ? "http://localhost:5002"
    : window.location.hostname === "3000.code.hahn.fun"
    ? ""
    : "https://api.createcookbooks.com";

const provider = new firebase.auth.GoogleAuthProvider();

const emailProvider = firebase.auth.EmailAuthProvider;

const fieldValue = firebase.firestore.FieldValue;

const app = firebase;

export { app, provider, emailProvider, fieldValue, baseAPI };
