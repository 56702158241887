import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Muted from "components/Typography/Muted.js";

import SnackbarVerify from "components/Snackbar/SnackbarVerify.js";
import Snackbars from "components/Snackbar/Snackbar.js";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import DefaultThumbnail from "assets/img/cover-default-book.svg";

import { app, baseAPI } from "base.js";
import Paperform from "components/Paperform";
import { Redirect } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

const useStyles = makeStyles(styles);

export default function Books(props) {
  const classes = useStyles();

  const {
    firebaseUser,
    currentUser,
    location,
    setDefaultBook,
    history,
    errorAlert,
  } = props;

  const [books, setBooks] = useState(null);

  const [addBook, setAddBook] = useState(null);
  const [removeBook, setRemoveBook] = useState(null);

  const [loading, setLoading] = useState(true);

  const role = firebaseUser && firebaseUser.role ? firebaseUser.role : null;

  const [newBook, setNewBook] = useState(false);
  const [createBookProgress, setCreateBookProgress] = useState(null);
  const [newBookId, setNewBookId] = useState(null);

  const newBookPaperformId = "cc-create-book"; //nk4d5kdq
  const [prefill, setPrefill] = useState(null);

  const [verifyNotification, setVerifyNotification] = useState(false);
  const [checkResendLoad, setCheckResendLoad] = useState(false);
  const [checkResendText, setCheckResendText] = useState("check/resend");

  const [checkResendTime, setCheckResendTime] = useState(0);

  const [isVerifiedAlert, setIsVerifiedAlert] = useState(false);

  let timeout;

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.emailVerified) {
        setVerifyNotification(true);

        const creationTime = new Date(
          currentUser.metadata.creationTime
        ).getTime();

        if (Date.now() - creationTime < 5 * 60 * 1000) {
          resetToCheck(30000);
        }
      } else if (location.search) {
        if (!role) {
          history.push("/packages");
        } else {
          console.log(location.search);
          const query = new URLSearchParams(location.search);

          if (query.get("verified")) {
            history.replace("/books");
            setIsVerifiedAlert(true);
            timeout = setTimeout(() => setIsVerifiedAlert(false), 5000);
          }
        }
      }

      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  useEffect(() => {
    if (currentUser.emailVerified) {
      setLoading(true);
      setVerifyNotification(false);
      const removeBookQuery = app
        .firestore()
        .collection("books")
        .where(`users.${currentUser.uid}`, ">", "''")
        .limit(30)
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.size === 0 && (!books || books.length === 0)) {
            if (currentUser.emailVerified) {
              setNewBook(true);
              if (
                currentUser.providerData.filter(
                  (provider) => provider.providerId === "password"
                ).length > 0
              ) {
                currentUser.getIdToken(true).then((token) => {
                  const headers = new Headers();
                  headers.append("Authorization", `Bearer ${token}`);
                  fetch(`${baseAPI}/users/on-email-verify`, { headers });
                });
              }
            }
            setLoading(false);
          }
          console.log(`size = ${querySnapshot.size}`);
          console.log(querySnapshot);

          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const data = change.doc.data();

              const book = {
                thumbnail: data.thumbnail,
                userType:
                  data.users[currentUser.uid].charAt(0).toUpperCase() +
                  data.users[currentUser.uid].slice(1),
                title: data.title,
                id: change.doc.id,
                dateCreated: new Date(data.created_at).getTime(),
              };

              setAddBook(book);
            } else if (change.type === "removed") {
              setRemoveBook(change.doc.id);
            }

            if (querySnapshot.size === change.newIndex + 1) {
              setTimeout(() => setLoading(false), 500);
            }
          });
        });

      return () => removeBookQuery();
    } else {
      setBooks(null);
      setLoading(false);
    }
  }, [currentUser.emailVerified]);

  useEffect(() => {
    if (addBook) {
      setBooks((b) => (b ? [...b, addBook] : [addBook]));
    }
  }, [addBook]);

  useEffect(() => {
    if (removeBook) {
      setBooks((b) => b.filter((book) => removeBook !== book.id));
    }
  }, [removeBook]);

  useEffect(() => {
    const timeMillis = Date.now();
    const createBookProgressRef = app
      .database()
      .ref(`users/${currentUser.uid}/createBookProgress/${timeMillis}`);

    if (newBook) {
      setPrefill(`time=${timeMillis}&role=${firebaseUser.role}`);

      createBookProgressRef.on("value", (snapshot) => {
        setCreateBookProgress(snapshot.val());
      });
    } else {
      setNewBookId(null);
      createBookProgressRef.off("value", () => {
        setCreateBookProgress(null);
      });
    }

    return () =>
      createBookProgressRef.off("value", () => {
        setCreateBookProgress(null);
      });
  }, [newBook]);

  useEffect(() => {
    if (createBookProgress) {
      if (createBookProgress.bookId) {
        setNewBookId(createBookProgress.bookId);
      }
    }
  }, [createBookProgress]);

  const changeBook = (bookId) => {
    setDefaultBook(bookId);
  };

  const checkResendEmailVerify = () => {
    setCheckResendLoad(true);
    currentUser.reload().then(() => {
      if (!currentUser.emailVerified) {
        if (checkResendText !== "check") {
          currentUser
            .sendEmailVerification({
              url: `https://api.createcookbooks.com/users/on-email-verify-link/${encodeURIComponent(
                currentUser.email
              )}`,
            })
            .then(() => {
              resetToCheck();
            })
            .catch((err) => {
              resetToCheck();
              //error alert?
              console.log(err);
            });
        }
      } else {
        setCheckResendLoad(false);
        setVerifyNotification(false);
        setIsVerifiedAlert(true);
        timeout = setTimeout(() => setIsVerifiedAlert(false), 5000);
      }
    });
  };

  const resetToCheck = (time) => {
    const tmpText = checkResendText;
    setCheckResendText("check");
    setCheckResendLoad(false);
    setCheckResendTime(time ? time / 1000 : 60);

    timeout = setTimeout(() => {
      setCheckResendText(tmpText);
    }, time || 60000);
  };

  useEffect(() => {
    let interval;

    if (checkResendText === "check") {
      interval = setInterval(() => {
        console.log("test");
        setCheckResendTime((t) => (t === 0 ? 0 : t - 1));
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [checkResendText]);

  const handleNewBookSubmission = async (submission) => {
    try {
      setCreateBookProgress(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const response = await fetch(`${baseAPI}/books/create`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        const result = await response.json();
        setNewBookId(result.bookId);
        console.log(submission.data);

        await currentUser.getIdToken(true);
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setPrefill(null);
      setCreateBookProgress(null);
      setNewBookId(null);

      errorAlert(
        "There was a problem submitting...",
        "We have saved your cookbook details. Please try submitting again. Error ID: " +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${encodeURIComponent(value)}`;
        })
        .join("&")}`
    );
  };

  return currentUser && firebaseUser ? (
    <div className={classes.container} style={{ width: "100%" }}>
      <Snackbars
        place="bc"
        color="success"
        icon={EmailIcon}
        message="Your email has been verified!"
        open={isVerifiedAlert}
        closeNotification={() => setIsVerifiedAlert(false)}
        close
      />
      <SnackbarVerify
        place="bc"
        color="warning"
        icon={EmailIcon}
        message={
          <p>
            <b>{currentUser.email}</b> is not verified. Go to your email and
            click the link to verify. Click <b>RESEND</b>{" "}
            {checkResendText === "check"
              ? `in ${checkResendTime} seconds `
              : ""}
            if you did not receive an email. Ensure you have checked the junk
            box before clicking resend.
          </p>
        }
        open={verifyNotification}
        onCheckResend={checkResendEmailVerify}
        checkResend
        checkResendText={checkResendText}
        checkResendDisabled={checkResendLoad}
      />
      {loading ? (
        <GridContainer justify="center">
          <GridItem xs={6} sm={6} md={4}>
            <CustomLinearProgress />
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>
                {books && !newBook ? "Select book" : null}
                {currentUser.emailVerified && (newBook || (!books && role))
                  ? books && books.length > 0
                    ? "Create new book"
                    : "Let's get started"
                  : null}
                {!books && !role ? "No books" : null}
                {!currentUser.emailVerified ? "Verify your email" : null}
              </h2>
            </GridItem>
          </GridContainer>
          {books && !newBook ? (
            <GridContainer justify="center" alignItems="flex-end">
              {books
                .sort((a, b) => b.dateCreated - a.dateCreated) //at some point make it last accessed?
                .map((book, index) => {
                  return (
                    <GridItem key={index}>
                      <Card style={{ width: "168px" }} raised>
                        <CardActionArea onClick={() => changeBook(book.id)}>
                          <img
                            alt={book.title}
                            style={{
                              //height: "100%",
                              width: "168px",
                              borderTopLeftRadius: "calc(.25rem - 1px)",
                              borderTopRightRadius: "calc(.25rem - 1px)",
                            }}
                            src={book.thumbnail || DefaultThumbnail}
                            data-holder-rendered="true"
                          />
                          <CardContent>
                            <h4>{book.title}</h4>
                            <Muted>{book.userType}</Muted>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </GridItem>
                  );
                })}
              <GridItem
                style={{
                  maxWidth: "168px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                {books.length < 30 ? (
                  <Card pricing plain>
                    <CardActionArea
                      onClick={() => {
                        if (!role) {
                          props.history.push("/packages");
                        } else if (currentUser.emailVerified) {
                          setNewBook(true);
                        }
                      }}
                    >
                      <CardBody pricing>
                        <div
                          className={classes.icon}
                          style={{
                            width: "100px",
                            height: "100px",
                            lineHeight: "144px",
                          }}
                        >
                          <AddIcon className={classes.iconWhite} />
                        </div>
                        <h3 className={classes.cardCategory}>New book</h3>
                      </CardBody>
                    </CardActionArea>
                  </Card>
                ) : null}
              </GridItem>
            </GridContainer>
          ) : null}

          {currentUser.emailVerified && (newBook || (!books && role)) ? (
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={8} lg={6}>
                <Card>
                  <CardBody>
                    {createBookProgress ? (
                      <div style={{ textAlign: "center" }}>
                        <h3>Creating your book</h3>
                        <CustomLinearProgress color="info" />
                        <p>{createBookProgress.status}</p>
                      </div>
                    ) : newBookId ? (
                      <div style={{ textAlign: "center" }}>
                        <h3>Your cookbook is ready!</h3>

                        <Button
                          size="lg"
                          color="info"
                          onClick={() => changeBook(newBookId)}
                        >
                          Open Book
                        </Button>
                      </div>
                    ) : (
                      <Paperform
                        paperFormID={newBookPaperformId}
                        hasPrefill={true}
                        prefill={prefill}
                        onSubmission={handleNewBookSubmission}
                      />
                    )}
                  </CardBody>
                </Card>
                {!(createBookProgress || (!books && role)) ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        setNewBook(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : null}
              </GridItem>
            </GridContainer>
          ) : null}

          {!books && !currentUser.emailVerified ? (
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <Card>
                  <CardBody>
                    Please verify <b>{currentUser.email}</b> email to continue.
                  </CardBody>
                  <CardBody>
                    The verification may have been sent to your junk/spam box,
                    please ensure you check there before selecting resend.
                  </CardBody>
                  <CardBody>
                    If you need help verifying your email please{" "}
                    <a
                      href="https://www.createcookbooks.com/contact"
                      target="_blank"
                    >
                      contact us
                    </a>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          ) : null}
        </>
      )}
    </div>
  ) : currentUser ? (
    <Redirect to="/packages" />
  ) : (
    <Redirect to="/login" />
  );
}
