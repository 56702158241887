import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import StyleIcon from "@material-ui/icons/Style";

import Button from "components/CustomButtons/Button.js";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { history, firebaseUser } = props;

  const classes = useStyles();

  return (
    <div>
      {firebaseUser ? (
        <Button
          color="transparent"
          simple
          aria-label={"Current package - " + firebaseUser.role}
          className={classes.buttonLink}
          onClick={() => history.push("/packages")}
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <StyleIcon className={classes.headerLinksSvg + " " + classes.links} />

          <span className={classes.linkText}>
            {firebaseUser.role[0].toUpperCase() + firebaseUser.role.slice(1)}{" "}
            package
          </span>
        </Button>
      ) : null}
      <Button
        color="transparent"
        simple
        aria-label="My books"
        className={classes.buttonLink}
        onClick={() => history.push("/books")}
      >
        <CollectionsBookmarkIcon
          className={classes.headerLinksSvg + " " + classes.links}
        />

        <span className={classes.linkText}>My books</span>
      </Button>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
