import React from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

export default function ChaptersMenu(props) {
  const {
    onRemove,
    onRename,
    chapterId,
    importing,
    disabled,
    enableRename,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleRemove = () => {
    handleMenuClose();
    onRemove(chapterId);
  };

  const handleRename = () => {
    handleMenuClose();
    onRename(chapterId);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const iconButton = (
    <IconButton
      edge="end"
      aria-controls={`chapter-menu-${chapterId}`}
      aria-haspopup="true"
      onClick={handleMenuClick}
      disabled={disabled}
    >
      <MoreVertIcon />
    </IconButton>
  );

  return (
    <>
      {importing ? (
        <Tooltip title="There is currently a process occuring in your book. Please wait.">
          {iconButton}
        </Tooltip>
      ) : (
        iconButton
      )}
      {!importing ? (
        <Menu
          id={`chapter-menu-${chapterId}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {" "}
          <MenuItem onClick={handleRename} disabled={!enableRename}>
            Rename
          </MenuItem>
          <MenuItem onClick={handleRemove}>Delete</MenuItem>
        </Menu>
      ) : null}
    </>
  );
}
