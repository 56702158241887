import React, { useState } from "react";
import GoogleButton from "react-google-button";
import { makeStyles } from "@material-ui/core/styles";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import { provider, baseAPI } from "base.js";

const useStyles = makeStyles({
  btn: {
    margin: "0 auto",
  },
});

export default function GoogleLink(props) {
  const {
    overrideLoading,
    currentUser,
    onLoad,
    setCurrentUser,
    loginHint,
    loginSuccess,
    loginError,
    requireOnlyProfile,
    errorAlert,
  } = props;

  const gapi = window.gapi;
  const [loading, setLocalLoading] = useState(false);

  const setLoading = (bool) => {
    if (overrideLoading) {
      overrideLoading(bool);
    } else {
      setLocalLoading(bool);
      if (onLoad) {
        onLoad(bool);
      }
    }
  };

  const classes = useStyles();

  const authOptions = requireOnlyProfile
    ? {
        client_id:
          "236787831955-37qcm2hj3ifpurp684at27bg8d8b0hu9.apps.googleusercontent.com",
        scope: "email profile openid",
        prompt: "select_account",
        response_type: "id_token permission code",
        login_hint: loginHint,
      }
    : {
        client_id:
          "236787831955-37qcm2hj3ifpurp684at27bg8d8b0hu9.apps.googleusercontent.com",
        scope: "email profile openid",
        //"email profile openid https://www.googleapis.com/auth/presentations",
        //"email profile openid https://www.googleapis.com/auth/drive.file",
        //"email profile openid https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/script.external_request",
        prompt: "select_account consent",
        response_type: "id_token permission code",
        access_type: "offline",
        login_hint: loginHint,
      };

  const linkWithGoogle = () => {
    setLoading(true);

    console.log(authOptions);

    gapi.auth2.authorize(authOptions, (res) => {
      if (res.error) {
        console.log(res);
        setLoading(false);

        if (res.error === "idpiframe_initialization_failed") {
          errorAlert(
            "Could not link Google Account",
            "Please try clearing your browser cache or using another browser. For more information contact support@createcookbooks.com"
          );
        }

        //error alert
      } else {
        currentUser.getIdToken(true).then((token) => {
          const header = new Headers({
            "Content-Type": "application/json",
          });

          header.append("Authorization", `Bearer ${token}`);

          fetch(baseAPI + "/google/refresh", {
            method: "POST",
            headers: header,
            body: JSON.stringify({ code: res.code, id_token: res.id_token }),
          })
            .then((response) => response.json())
            .then((result) => {
              console.log(result);
              if (result.login) {
                if (result.linkGoogle) {
                  const credential = provider.credential(
                    res.id_token,
                    res.permission
                  );

                  currentUser
                    .linkWithCredential(credential)
                    .then(async ({ user }) => {
                      setLoading(false);
                      setCurrentUser(user);
                      if (loginSuccess) loginSuccess();
                    });
                } else {
                  setLoading(false);
                  if (loginSuccess) loginSuccess();
                }
              } else {
                setLoading(false);
                if (loginError) loginError();
              }
            });
        });
      }
    });
  };

  return loading ? (
    <CustomLinearProgress color="gray" />
  ) : (
    <GoogleButton
      className={classes.btn}
      type="light"
      onClick={linkWithGoogle}
    />
  );
}
