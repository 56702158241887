import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";

import Paperform from "components/Paperform.js";

import { baseAPI } from "base.js";

export default function RecipeForm(props) {
  const {
    currentUser,
    defaultBook,
    firebaseUser,
    history,
    match,
    loadingAlert,
    errorAlert,
    successAlert,
    warningAlert,
    hideAlert,
  } = props;

  const { displayName, email } = currentUser;

  const [prefill, setPrefill] = useState(null);

  const [submitted, setSubmitted] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleSubmission = async (submission) => {
    try {
      loadingAlert();
      setLoading(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const response = await fetch(`${baseAPI}/recipes/submit`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        setLoading(false);
        hideAlert();
        setSubmitted(true);
        /*successAlert("Success", "Wanna resubmit?", () =>
          handleSubmission(submission)
        );*/
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setPrefill(null);
      setLoading(false);
      errorAlert(
        "There was a problem submitting...",
        "We have saved your recipe. Please try submitting again. Error ID: " +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${encodeURIComponent(value)}`;
        })
        .join("&")}`
    );
  };

  const reset = () => {
    if (submitted) {
      history.push("/recipes/submit");
    } else {
      warningAlert(
        "Are you sure?",
        "Changing your chapter will lose anything you have already entered.",
        () =>
          history.push({
            pathname: "/recipes/submit",
            state: { changedChapter: true },
          })
      );
    }
  };

  useEffect(() => {
    if (!defaultBook.chapters[decodeURIComponent(match.params.chapterId)]) {
      history.replace("/recipes/submit");
    } else {
      const customFields = defaultBook.formSettings.customFields
        ? Object.keys(defaultBook.formSettings.customFields)
            .filter(
              (customField) =>
                defaultBook.formSettings.customFields[customField] !== null
            )
            .map(
              (customField) =>
                `&${customField}_label=${defaultBook.formSettings.customFields[customField].label}`
            )
            .join("")
        : "";

      setPrefill(
        `bookID=${firebaseUser.defaultBook}&chapter=${
          match.params.chapterId
        }&chapterName=${
          match.params.chapterName
        }&fields=${defaultBook.formSettings.showFields.join(
          ","
        )}${customFields}&email=${email}&name=${
          defaultBook.formSettings.showFields.indexOf("name") !== -1
            ? displayName
            : ""
        }&bookRole=${defaultBook.userBookRole}&userRole=${firebaseUser.role}`
      );

      return () => {
        hideAlert();
        window.onbeforeunload = undefined;
      };
    }
  }, []);

  useEffect(() => {
    if (!submitted) {
      const unblock = history.block((location, action) => {
        if (
          location.pathname === "/recipes/submit" &&
          location.state &&
          location.state.changedChapter
        ) {
          return true;
        }

        if (
          action === "POP" ||
          (action === "PUSH" &&
            location.pathname !== `/recipes/submit/${match.params.chapter}`)
        ) {
          return window.confirm(
            "Are you sure? Anything you have entered may be lost."
          );
        }
      });
      window.onbeforeunload = (event) => {
        return false;
      };

      return () => {
        unblock();
      };
    } else {
      const doNotBlock = history.block(() => true);
      window.onbeforeunload = undefined;

      return () => doNotBlock();
    }
  }, [submitted]);

  return (
    <GridContainer>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        style={{ visibility: loading ? "hidden" : "visible" }}
      >
        <form style={{ display: "none" }}>
          <input type="hidden" value="preventBack" />
        </form>
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <Button size="sm" onClick={reset}>
            Change chapter
          </Button>
        </div>

        <Paperform
          paperFormID="cc-submit"
          hasPrefill={true}
          prefill={prefill}
          onLoad={() => {
            setSubmitted(false);
          }}
          onSubmission={handleSubmission}
        />
      </GridItem>
    </GridContainer>
  );
}
