import React from "react";

import GoogleLink from "components/Auth/GoogleLink.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";

import Heading from "components/Heading/Heading.js";

export default function GoogleLinkView(props) {
  const { currentUser, setCurrentUser, errorAlert } = props;

  const loginHint = hasGoogle
    ? currentUser.providerData.filter(
        ({ providerId }) => providerId === "google.com"
      )[0].uid
    : currentUser.email;

  const hasGoogle =
    currentUser.providerData.filter(
      (provider) => provider.providerId === "google.com"
    ).length > 0;

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={8} sm={8} md={8}>
          <Heading
            textAlign="center"
            title="Why we ask you to sign in or create a Google account?"
            category={
              <span>
                We use Google’s powerful services including Google Drive and
                Google Slides.{" "}
                <b>Our templates are created in Google slides </b>
                and require you to connect your existing Google account, or
                create a new Google account in order to connect to your
                template.
              </span>
            }
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Heading
            textAlign="center"
            title="Link existing Google account"
            category={
              <span>
                Click button below and follow the prompts to connect your
                existing Google account.
              </span>
            }
          />
          <div style={{ marginTop: "60px" }}>
            <GoogleLink
              {...props}
              loginHint={loginHint}
              //loginSuccess={() => setHasRefresh(true)}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <div style={{ textAlign: "center" }}>
            <Heading
              textAlign="center"
              title="Create your Google account"
              category={
                <span>
                  Click button below and create a new <b>Gmail</b>, or{" "}
                  <b>select use my current email address instead</b>{" "}
                  <i>(your non-Gmail address you signed up with)</i> to create a
                  Google account.
                </span>
              }
            />
            <Button
              color="info"
              size="lg"
              onClick={() =>
                window.open(
                  "https://accounts.google.com/signup/v2/webcreateaccount?hl=en&flowName=GlifWebSignIn&flowEntry=SignUp",
                  "_blank"
                )
              }
            >
              Create Google account
            </Button>
          </div>
        </GridItem>
      </GridContainer>

      <GridContainer direction="column" style={{ display: "none" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Heading
            textAlign="center"
            title="Link your Google account"
            category={
              <span>
                Before selecting your template you must connect your account
                with a valid google account.
                <br />
                Please click the button, login or create a Google account and
                follow the prompts.
              </span>
            }
          />
          <GoogleLink
            {...props}
            loginHint={loginHint}
            //loginSuccess={() => setHasRefresh(true)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ textAlign: "center", marginTop: "60px" }}>
            <h3>Don't have a Google account?</h3>
            <p>
              <a
                href="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&flowName=GlifWebSignIn&flowEntry=SignUp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to create a Google account.
              </a>
            </p>
            <p>
              After that, use the <i>Sign in with Google</i> button above to
              link your Google account to your Create Cookbooks account and
              follow the prompts.
            </p>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ textAlign: "center", marginTop: "60px" }}>
            <h3>Why do we need you to sign in with Google?</h3>
            <p>
              We require you to link your Google account too allow you to choose
              and access your Cookbook templates.
            </p>
            <p>
              We use Google's very powerful and familiar services, Google Drive
              and Google Slides, in order to deliver a versatile and easy to use
              experience.
            </p>
            <img
              width="256"
              alt="Google Drive logo"
              src="https://upload.wikimedia.org/wikipedia/commons/d/da/Google_Drive_logo.png"
              style={{ padding: "20px" }}
            />
            <img
              width="256"
              alt="Google Slides 2020 Logo"
              src="https://upload.wikimedia.org/wikipedia/commons/1/16/Google_Slides_2020_Logo.svg"
              style={{ padding: "20px" }}
            />
            <p>
              <i>Google Drive and Google Slides logo's</i>
            </p>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
