import React, { useEffect, useState } from "react";

import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import Paperform from "components/Paperform.js";

import GoogleLinkView from "components/Auth/GoogleLinkView.js";

import Button from "components/CustomButtons/Button.js";

import { app } from "base.js";

let timeout;

export default function Templates(props) {
  const {
    currentUser,
    setCurrentUser,
    defaultBook,
    firebaseUser,
    history,
    hideAlert,
    warningAlert,
    errorAlert,
  } = props;

  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = useState(null);

  const [prefill, setPrefill] = useState(null);

  const [authToken, setAuthToken] = useState(null);

  const [onSubmission, setOnSubmission] = useState(false);

  const id = firebaseUser.defaultBook;

  useEffect(() => {
    if (
      !(
        defaultBook.chapters &&
        Object.keys(defaultBook.chapters).length > 0 &&
        defaultBook.chaptersArray &&
        defaultBook.chaptersArray.length > 0
      )
    ) {
      history.replace("/cookbook/chapters");
    } else {
      if (!defaultBook.templateProcessing) {
        setLoading(true);

        createPaperformPrefill();
      } else {
        setLoading(false);
      }
    }

    return () => {
      clearTimeout(timeout);
      hideAlert();
    };
  }, []);

  const createPaperformPrefill = () => {
    timeout = setTimeout(() => {
      warningAlert(
        "Timed out",
        "The template form has timed out. Please click try again to refresh.",
        () => {
          setPrefill(null);
          hideAlert();
          createPaperformPrefill();
        },
        {
          hideCancel: true,
          onCancel: () => null,
          confirmText: "Try again",
        }
      );
    }, 3600000);

    currentUser.getIdToken(true).then((token) => {
      setAuthToken(token);

      setLoading(false);
    });
  };

  useEffect(() => {
    if (authToken) {
      setPrefill(
        `auth=${authToken}&bookID=${firebaseUser.defaultBook}&role=${firebaseUser.role}&signupToken=${firebaseUser.templateTokens}&email=${currentUser.email}&name=${currentUser.displayName}`
      );
      setAuthToken(null);
    }
  }, [authToken]);

  useEffect(() => {
    if (firebaseUser.googleRefresh) {
      if (firebaseUser.role === "starter") {
        warningAlert(
          "Upgrade for a free template!",
          <p>
            Receive up to <b>three free templates</b> valued at <b>$20 each</b>{" "}
            and access to many more useful features by upgrading today!
          </p>,
          () => {
            history.push("/plans");
          },
          {
            confirmText: "View plans",
            cancelText: "Not now",
          }
        );
      } else if (
        firebaseUser.role === "premium" &&
        firebaseUser.templateTokens === 0
      ) {
        warningAlert(
          "Get another free template!",
          <p>
            Upgrade to <b>Community</b> to receive another TWO free templates
            and access to many useful features including advanced cookbook and
            recipe sharing and premium support.
          </p>,
          () => {
            history.push("/plans");
          },
          {
            confirmText: "View plans",
            cancelText: "Not now",
          }
        );
      }
    }
  }, [firebaseUser.googleRefresh]);

  useEffect(() => {
    if (defaultBook.templateProcessing) {
      clearTimeout(timeout);
      setPrefill(null);

      const templateProgressRef = app
        .database()
        .ref(`books/${id}/templateProgress`);

      templateProgressRef.on("value", (snapshot) => {
        setProgress(snapshot.val());
      });

      return () => templateProgressRef.off();
    }
  }, [defaultBook.templateProcessing]);

  if (loading) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomLinearProgress color="info" />
        </GridItem>
      </GridContainer>
    );
  } else if (!firebaseUser.googleRefresh) {
    return (
      <GoogleLinkView
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        errorAlert={errorAlert}
      />
    );
  } else {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {defaultBook.templateProcessing && !prefill ? (
            <>
              {defaultBook.templateProcessing === "error" ? (
                <Heading
                  textAlign="center"
                  title="There was an error"
                  category={
                    <span>Email support@createcookbooks.com for help</span>
                  }
                />
              ) : (
                <Heading
                  textAlign="center"
                  title="Your template is being created!"
                  category={<span>This process may take a few minutes.</span>}
                />
              )}

              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={6}>
                  {defaultBook.templateProcessing === "error" ? (
                    <Card>
                      <CardBody style={{ textAlign: "center" }}>
                        <p>
                          We are sorry but there was an error when processing
                          your template. If you used a template token, or
                          purchased a premium template, we have added{" "}
                          <b>1 free template token</b> as a refund. You can use
                          this token to try again.
                        </p>
                        <p>
                          If this error continues to show. Please email{" "}
                          <b>lucas@createcookbooks.com</b> or use the blue CHAT
                          button in the bottom right corner asap so we can
                          quickly resolve this issue. Sorry for any
                          inconvenience.
                        </p>

                        <Button
                          onClick={createPaperformPrefill}
                          size="lg"
                          color="info"
                        >
                          Try again
                        </Button>
                      </CardBody>
                    </Card>
                  ) : progress || onSubmission ? (
                    <Card>
                      <CardBody style={{ textAlign: "center" }}>
                        {progress && progress.count && progress.total ? (
                          <>
                            <h2>
                              {Math.floor(
                                (progress.count / progress.total) * 100
                              )}
                              %
                            </h2>
                            <CustomLinearProgress
                              variant="determinate"
                              color="info"
                              value={Math.floor(
                                (progress.count / progress.total) * 100
                              )}
                            />
                          </>
                        ) : (
                          <CustomLinearProgress color="info" />
                        )}
                        <h4>
                          {progress && progress.status ? progress.status : ""}
                        </h4>
                      </CardBody>
                    </Card>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "3vh" }}>
                      <CircularProgress style={{ margin: "0 auto" }} />
                    </div>
                  )}
                </GridItem>
              </GridContainer>
            </>
          ) : loading ? (
            <CustomLinearProgress color="info" />
          ) : (
            <Paperform
              paperFormID="cc-template"
              //paperFormID="v8atvmxn"
              hasPrefill={true}
              prefill={prefill}
              onSubmission={(data) => {
                if (data.total > 0) {
                  window.gtag("event", "conversion", {
                    send_to: "AW-311881269/lZldCN63lfECELXc25QB",
                    value: data.total,
                    currency: "USD",
                  });
                }

                clearTimeout(timeout);
                setOnSubmission(true);
              }}
            />
          )}
        </GridItem>
      </GridContainer>
    );
  }
}
