import React, { useState, useEffect } from "react";

import GoogleLinkView from "components/Auth/GoogleLinkView.js";
import Paperform from "components/Paperform";
import Heading from "components/Heading/Heading.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

import { app, baseAPI } from "base.js";

export default function FundraisingGenerate(props) {
  const {
    currentUser,
    setCurrentUser,
    firebaseUser,
    loadingAlert,
    errorAlert,
    hideAlert,
    defaultBook,
  } = props;

  const [prefill, setPrefill] = useState(null);

  const [progress, setProgress] = useState(null);

  const [onSubmission, setOnSubmission] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (firebaseUser.googleRefresh && !defaultBook.fundraisingProcessing) {
      createPaperformPrefill();
    }

    return () => {
      hideAlert();
    };
  }, [firebaseUser.googleRefresh]);

  useEffect(() => {
    if (defaultBook.fundraisingProcessing) {
      const bookId = firebaseUser.defaultBook;

      setPrefill(null);

      const fundraisingProgressRef = app
        .database()
        .ref(`books/${bookId}/fundraisingProgress`);

      fundraisingProgressRef.on("value", (snapshot) => {
        setProgress(snapshot.val());
      });

      return () => fundraisingProgressRef.off();
    }
  }, [defaultBook.fundraisingProcessing]);

  const createPaperformPrefill = async () => {
    const profileDoc = await firebaseUser.userDoc
      .collection("public")
      .doc("profile")
      .get();

    let defaultPrefill = `bookID=${firebaseUser.defaultBook}&name=${
      currentUser.displayName || ""
    }&email=${currentUser.email || ""}&cookbookTitle=${
      defaultBook.title
    }&phone=${currentUser.phoneNumber || ""}`;

    if (profileDoc.exists) {
      const data = profileDoc.data();

      setPrefill(`${defaultPrefill}&organisation=${data.organisation || ""}`);
    } else {
      setPrefill(defaultPrefill);
    }
  };

  const handleSubmission = async (submission) => {
    try {
      loadingAlert();
      setLoading(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const response = await fetch(`${baseAPI}/fundraising/generate`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        setLoading(false);
        hideAlert();
        setOnSubmission(true);
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setPrefill(null);
      setLoading(false);
      errorAlert(
        "There was a problem submitting...",
        "We have saved your recipe. Please try submitting again. Error ID: " +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${encodeURIComponent(value)}`;
        })
        .join("&")}`
    );
  };

  if (!firebaseUser.googleRefresh) {
    return (
      <GoogleLinkView
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
      />
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {defaultBook.fundraisingProcessing && !prefill ? (
          <>
            {defaultBook.fundraisingProcessing === "error" ? (
              <Heading
                textAlign="center"
                title="There was an error"
                category={
                  <span>Email support@createcookbooks.com for help</span>
                }
              />
            ) : (
              <Heading
                textAlign="center"
                title="Your fundraising templates are being generated!"
                category={<span>This process may take a few minutes.</span>}
              />
            )}

            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={6}>
                {defaultBook.fundraisingProcessing === "error" ? (
                  <Card>
                    <CardBody style={{ textAlign: "center" }}>
                      <p>
                        We are sorry but there was an error when processing your
                        fundraising templates. Please try again.
                      </p>
                      <p>
                        If this error continues to show. Please email{" "}
                        <b>lucas@createcookbooks.com</b> or use the blue CHAT
                        button in the bottom right corner asap so we can quickly
                        resolve this issue. Sorry for any inconvenience.
                      </p>

                      <Button
                        onClick={createPaperformPrefill}
                        size="lg"
                        color="info"
                      >
                        Try again
                      </Button>
                    </CardBody>
                  </Card>
                ) : progress || onSubmission ? (
                  <Card>
                    <CardBody style={{ textAlign: "center" }}>
                      {progress && progress.count && progress.total ? (
                        <>
                          <h2>
                            {Math.floor(
                              (progress.count / progress.total) * 100
                            )}
                            %
                          </h2>
                          <CustomLinearProgress
                            variant="determinate"
                            color="info"
                            value={Math.floor(
                              (progress.count / progress.total) * 100
                            )}
                          />
                        </>
                      ) : (
                        <CustomLinearProgress color="info" />
                      )}
                      <h4>
                        {progress && progress.status ? progress.status : ""}
                      </h4>
                    </CardBody>
                  </Card>
                ) : (
                  <div style={{ textAlign: "center", marginTop: "3vh" }}>
                    <CircularProgress style={{ margin: "0 auto" }} />
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </>
        ) : (
          <div style={{ opacity: loading ? 0.1 : 1 }}>
            <Paperform
              paperFormID="cc-fundraising"
              prefill={prefill}
              hasPrefill={true}
              onSubmission={handleSubmission}
            />
          </div>
        )}
      </GridItem>
    </GridContainer>
  );
}
