import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GoogleButton from "react-google-button";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import { provider } from "base.js";

const useStyles = makeStyles({
  btn: {
    margin: "0 auto",
  },
});

export default function GoogleConfirm(props) {
  const {
    overrideLoading,
    loginHint,
    currentUser,
    onLoad,
    onLogin,
    onError,
  } = props;

  const [loading, setLocalLoading] = useState(false);

  const classes = useStyles();

  const setLoading = (bool) => {
    if (overrideLoading) {
      overrideLoading(bool);
    } else {
      setLocalLoading(bool);
      if (onLoad) {
        onLoad(bool);
      }
    }
  };

  const confirmLoginWithGoogle = () => {
    setLoading(true);

    if (loginHint) {
      provider.setCustomParameters({
        login_hint: loginHint,
      });
    }

    currentUser
      .reauthenticateWithPopup(provider)
      .then(() => {
        setLoading(false);
        onLogin();
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/popup-closed-by-user") {
          return;
        }

        onError(error.message);
        //error alert
      });
  };

  return loading ? (
    <CustomLinearProgress color="gray" />
  ) : (
    <GoogleButton
      className={classes.btn}
      type="light"
      onClick={confirmLoginWithGoogle}
    />
  );
}
