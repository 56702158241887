import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoggedIn(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const { avatar, name, register } = props;

  return (
    <div className={!register ? classes.container : null}>
      <form>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
          style={{ paddingTop: "20px" }}
        >
          {avatar ? (
            <CardAvatar profile className={classes.cardAvatar}>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
          ) : null}

          <CardBody profile>
            <h4 className={classes.cardTitle}>{name}</h4>
            <Button
              size="lg"
              color="info"
              style={{ marginTop: "15px" }}
              onClick={props.continue}
            >
              Continue
            </Button>
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="danger" simple onClick={props.signOutUser}>
              Sign out
            </Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
}
