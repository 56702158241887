import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import Email from "@material-ui/icons/Email";

import CircularProgress from "@material-ui/core/CircularProgress";

import { app } from "base.js";
import { Redirect } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function ForgotPass(props) {
  const { currentUser, history } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [resetSent, setResetSent] = useState(false);

  const [email, setEmail] = useState(
    props.location.state && props.location.state.email
      ? props.location.state.email
      : null
  );

  const [emailErr, setEmailErr] = useState(null);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email) {
      if (!email) setEmailErr("Email address is required.");

      setLoading(false);
      return;
    }

    app
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((providers) => {
        if (providers.length === 1 && providers[0] === "google.com") {
          setEmailErr(
            "Email address is only connected to a Google account. To reset your password, click the link in the description above"
          );
          setLoading(false);
        } else {
          const actionCode = {
            url: `https://app.createcookbooks.com/login?email=${email}`,
          };

          app
            .auth()
            .sendPasswordResetEmail(email, actionCode)
            .then(() => {
              setResetSent(true);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              if (err.code === "auth/invalid-email") {
                setEmailErr("Email address is invalid.");
              }

              if (err.code === "auth/user-disabled") {
                setEmailErr(
                  "The account tied to this email address has been disabled."
                );
              }

              if (err.code === "auth/user-not-found") {
                setEmailErr("No account tied to this email exists.");
              }
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === "auth/invalid-email") {
          setEmailErr("Email address is invalid.");
        }
      });
  };

  return currentUser ? (
    <Redirect to="/my-account" />
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <form onSubmit={onFormSubmit}>
            <Card login style={{ maxWidth: "400px" }}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Forgot Password</h4>
              </CardHeader>
              <CardBody style={{ textAlign: "center" }}>
                {resetSent ? (
                  <h4>
                    Password reset link successufully sent to <b>{email}</b>.
                    Please click the link and follow the prompts to reset your
                    password.
                  </h4>
                ) : (
                  <div>
                    Enter a valid Create Cookbooks email to send a password
                    reset link and follow the prompts. Your account must be
                    linked to an email/password login. To reset your Google
                    account password,{" "}
                    <a
                      href="https://support.google.com/accounts/answer/41078?co=GENIE.Platform%3DDesktop&hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      please see this help article.
                    </a>
                  </div>
                )}
              </CardBody>
              {!resetSent ? (
                <CardBody>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: (e) => {
                        if (emailErr) setEmailErr(null);
                        setEmail(e.target.value);
                      },
                      disabled: loading,
                      defaultValue: email || "",
                    }}
                    error={!!emailErr}
                    helperText={emailErr}
                  />
                </CardBody>
              ) : null}
              {!resetSent ? (
                <CardFooter className={classes.justifyContentCenter}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button color="info" type="submit" disabled={loading}>
                      Send Password Reset Email
                    </Button>
                  )}
                </CardFooter>
              ) : null}
              {resetSent || !loading ? (
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    size="sm"
                    simple
                    color="danger"
                    onClick={() =>
                      history.push({
                        pathname: "/login",
                        state: { email },
                      })
                    }
                  >
                    Back
                  </Button>
                </CardFooter>
              ) : null}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
