import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Heading from "components/Heading/Heading.js";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import CircularProgress from "@material-ui/core/CircularProgress";

import Divider from "@material-ui/core/Divider";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import styles from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle.js";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "components/CustomButtons/Button.js";

import ButtonMui from "@material-ui/core/Button";

import ShareMenu from "components/Menus/ShareMenu.js";
import ShareInviteMenu from "components/Menus/ShareInviteMenu.js";

import PublicForm from "./PublicForm.js";

import Paperform from "components/Paperform.js";
import { app, baseAPI } from "base.js";

import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Share(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);

  const [users, setUsers] = useState(null);

  const [newUser, setNewUser] = useState(null);
  const [inviteUser, setInviteUser] = useState(false);

  const [inviteLoading, setInviteLoading] = useState(false);

  const [openPaperform, setOpenPaperform] = useState(false);
  const [paperformPrefill, setPaperformPrefill] = useState(null);

  const [publicFormLoading, setPublicFormLoading] = useState(true);
  const [publicFormData, setPublicFormData] = useState(null);

  const [loading, setLoading] = useState(false);

  const removeUser = (user) => {
    const usersTmp = users;
    setUsers(null);
    currentUser.getIdToken(true).then((token) => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);

      fetch(`${baseAPI}/share/remove/${firebaseUser.defaultBook}/${user.uid}`, {
        headers,
      }).then((response) => {
        if (response.status !== 200) {
          setUsers(usersTmp);
        }
      });
    });
  };

  const updateUser = (user) => {
    handlePaperform(user);
  };

  const usersSortBy = {
    owner: 1,
    designer: 2,
    editor: 3,
    contributor: 4,
  };

  const {
    currentUser,
    defaultBook,
    firebaseUser,
    history,
    warningAlert,
    successAlert,
    errorAlert,
    loadingAlert,
    hideAlert,
  } = props;

  useEffect(() => {
    if (firebaseUser.role === "community") {
      const removePublicFormQuery = app
        .firestore()
        .collection("publicForm")
        .where("bookId", "==", firebaseUser.defaultBook)
        .limit(1)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              setPublicFormData({ ...doc.data(), slug: doc.id });
            });
          } else {
            setPublicFormData(null);
          }

          setPublicFormLoading(false);
        });

      return () => removePublicFormQuery();
    } else {
      setPublicFormLoading(false);
    }
  }, []);

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const handlePaperform = (user) => {
    const selectedUser = user || newUser;

    if (!selectedUser && !email) return;

    if (user) setNewUser(user);

    setOpenPaperform(true);

    let prefill = `&bookID=${firebaseUser.defaultBook}&bookTitle=${
      defaultBook.title
    }&ownerRole=${firebaseUser.role}&ownerName=${
      currentUser.displayName
    }&email=${selectedUser && selectedUser.email ? selectedUser.email : email}`;

    prefill +=
      selectedUser && selectedUser.bookRole
        ? `&shareType=update&bookRole=${
            selectedUser.bookRole[0].toUpperCase() +
            selectedUser.bookRole.slice(1)
          }&uid=${selectedUser.uid}`
        : selectedUser
        ? `&shareType=add&uid=${selectedUser.uid}`
        : `&shareType=invite`;

    setPaperformPrefill(prefill);
  };

  const updateAndResendInvite = async (invitedUser) => {
    setOpenPaperform(true);

    const headers = new Headers();

    const token = await currentUser.getIdToken(true);

    headers.append("Authorization", `Bearer ${token}`);

    const response = await fetch(
      `${baseAPI}/share/email-exists/${encodeURIComponent(invitedUser.email)}/${
        firebaseUser.defaultBook
      }`,
      {
        headers,
      }
    );

    let prefill;

    if (response.status === 200) {
      const result = await response.json();

      if (
        result.emailExists &&
        result.user &&
        result.user.email &&
        result.user.uid
      ) {
        prefill = `&bookID=${firebaseUser.defaultBook}&bookTitle=${defaultBook.title}&ownerRole=${firebaseUser.role}&ownerName=${currentUser.displayName}&email=${result.user.email}&shareType=add&uid=${result.user.uid}&bookRole=${invitedUser.bookRole}`;

        removeInvite(invitedUser);
      } else {
        prefill = `&bookID=${firebaseUser.defaultBook}&bookTitle=${defaultBook.title}&ownerRole=${firebaseUser.role}&ownerName=${currentUser.displayName}&email=${invitedUser.email}&shareType=invite&bookRole=${invitedUser.bookRole}`;
      }
    } else {
      prefill = `&bookID=${firebaseUser.defaultBook}&bookTitle=${defaultBook.title}&ownerRole=${firebaseUser.role}&ownerName=${currentUser.displayName}&email=${invitedUser.email}&shareType=invite&bookRole=${invitedUser.bookRole}`;
    }

    setPaperformPrefill(prefill);
  };

  const removeInvite = (invitedUser) => {
    setInviteLoading(true);
    currentUser.getIdToken(true).then((token) => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);

      fetch(
        `${baseAPI}/share/remove-invite/${firebaseUser.defaultBook}/${invitedUser.email}`,
        {
          headers,
        }
      ).then((response) => {
        setInviteLoading(false);
      });
    });
  };

  const handleSubmit = async () => {
    if (
      firebaseUser.role === "starter" &&
      defaultBook.userBookRole !== "co-owner"
    ) {
      warningAlert(
        "Upgrade your plan",
        "Inviting users to contribute recipes and more requires upgrading to the Premium or Community plan.",
        () => {
          hideAlert();
          history.push("/plans");
        },
        {
          confirmText: "Upgrade plan",
        }
      );

      return;
    }

    setNewUser(null);
    setInviteUser(null);
    setEmailLoading(true);
    if (!verifyEmail(email)) {
      setError(true);
      setEmailLoading(false);
      setHelperText("Email is invalid");
      return;
    }

    setError(false);
    setHelperText("");

    const headers = new Headers();

    const token = await currentUser.getIdToken(true);

    headers.append("Authorization", `Bearer ${token}`);

    const response = await fetch(
      `${baseAPI}/share/email-exists/${encodeURIComponent(email)}/${
        firebaseUser.defaultBook
      }`,
      {
        headers,
      }
    );

    if (response.status === 200) {
      const result = await response.json();

      if (result.emailExists) {
        setNewUser(result.user);
      } else {
        setInviteUser(true);
      }
    } else {
      setError(true);
      setHelperText("Something went wrong.");
    }

    setEmailLoading(false);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError(false);
    setHelperText("");
    setNewUser(null);
    setInviteUser(false);
  };

  const reset = () => {
    setNewUser(null);
    setInviteUser(false);
    setEmailLoading(null);
    setHelperText("");
    setEmail("");
    setError(null);
    setOpenPaperform(false);
    setPaperformPrefill(null);
  };

  const handlePaperformSubmission = async (submission) => {
    try {
      loadingAlert();
      setLoading(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const shareType = submission.data.find(
        (field) => field.custom_key === "shareType"
      ).value;

      const response = await fetch(`${baseAPI}/share/${shareType}`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        setLoading(false);
        hideAlert();
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setPaperformPrefill(null);
      setLoading(false);
      errorAlert(
        "There was a problem submitting...",
        "We have saved your selection. Please try again." +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPaperformPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${value}`;
        })
        .join("&")}`
    );
  };

  useEffect(() => {
    const reloadUsers = () => {
      setUsers(null);
      currentUser.getIdToken(true).then((token) => {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-type", "application/json");

        fetch(baseAPI + "/share/get-users", {
          headers,
          method: "POST",
          body: JSON.stringify({
            users: defaultBook.users,
            bookId: firebaseUser.defaultBook,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            setUsers(result.userList);
          });
      });
    };

    if (!users) {
      console.log("no users");
      reloadUsers();
    } else {
      const isSame =
        users.every(({ uid, bookRole }) => {
          return defaultBook.users[uid] === bookRole;
        }) && users.length === Object.keys(defaultBook.users).length;

      if (!isSame) {
        reloadUsers(true);
      }
    }
  }, [defaultBook]);

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Heading
            title="Invite and collaborate"
            category={
              <a
                href="javascript:void(0)"
                onClick={() =>
                  window.Beacon("article", "633d0ecd9f7c1931ee006945", {
                    type: "sidebar",
                  })
                }
              >
                Invite family, friends or members of your community to submit
                recipes, <br />
                help with editing recipes and the layout of your cookbook
              </a>
            }
            textAlign="center"
          />

          {openPaperform ? (
            <Card>
              <CardBody style={{ opacity: loading ? 0.1 : 1 }}>
                <Paperform
                  paperFormID="cc-share"
                  hasPrefill={true}
                  prefill={paperformPrefill}
                  onSubmission={handlePaperformSubmission}
                />
                <div style={{ textAlign: "center" }}>
                  <Button onClick={reset}>Back</Button>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card>
              <CardBody>
                <h4 style={{ marginBottom: "1rem" }}>Invite user</h4>
                <FormControl
                  //className={clsx(classes.margin, classes.textField)}
                  variant="filled"
                  fullWidth={true}
                  error={error}
                >
                  <InputLabel htmlFor="filled-email-adornment">
                    Email Address
                  </InputLabel>
                  <FilledInput
                    id="filled-email-adornment"
                    type="text"
                    value={email}
                    aria-describedby="helper-text"
                    onChange={handleEmail}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    disableUnderline={emailLoading}
                    endAdornment={
                      <InputAdornment position="end">
                        {/*<IconButton
                          type="submit"
                          className={classes.iconButton}
                          aria-label="search email"
                          onClick={handleSubmit}
                        >
                          <SearchIcon />
                        </IconButton>*/}
                        <ButtonMui
                          type="submit"
                          aria-label="invite email"
                          onClick={handleSubmit}
                        >
                          Invite
                        </ButtonMui>
                      </InputAdornment>
                    }
                  />
                  {emailLoading ? (
                    <CustomLinearProgress
                      color="info"
                      style={{
                        height: "2px",
                        transform: "scaleX(1)",
                        marginTop: "-2px",
                      }}
                    />
                  ) : null}
                  {helperText ? (
                    <FormHelperText id="helper-text">
                      {helperText}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                {newUser && !inviteUser ? (
                  <div>
                    <GridContainer justify="center">
                      <Card style={{ width: "unset" }}>
                        <CardHeader
                          avatar={
                            <Avatar
                              alt={newUser.displayName}
                              src={newUser.photoURL}
                            >
                              {newUser.displayName[0].toUpperCase()}
                            </Avatar>
                          }
                          title={newUser.displayName}
                          subheader={newUser.email}
                        />
                      </Card>
                    </GridContainer>

                    <div style={{ textAlign: "center" }}>
                      {newUser.bookRole === "owner" ? (
                        <h4>
                          An owner cannot be added or changed. Please email
                          support.
                        </h4>
                      ) : (
                        <Button color="info" onClick={() => handlePaperform()}>
                          {newUser.bookRole ? "Update user" : "Add user"}
                        </Button>
                      )}
                    </div>
                  </div>
                ) : null}

                {inviteUser && !newUser ? (
                  <div
                    style={{
                      marginTop: "3rem",
                      textAlign: "center",
                    }}
                  >
                    {defaultBook.invites && defaultBook.invites[email] ? (
                      <>
                        <h4>{email} has already been invited.</h4>
                        <h6>Would you like to resend a new invitation?</h6>
                      </>
                    ) : (
                      <>
                        <h4>
                          {" "}
                          Would you like to send an invite link to{" "}
                          <b>{email}</b> to create an account?
                        </h4>
                      </>
                    )}
                    <div>
                      <Button color="info" onClick={() => handlePaperform()}>
                        Invite
                      </Button>
                    </div>
                  </div>
                ) : null}

                {newUser && inviteUser ? (
                  <div style={{ marginTop: "3rem", textAlign: "center" }}>
                    <h4>Something went wrong</h4>
                    <h6>Please try again</h6>
                  </div>
                ) : null}

                {newUser || inviteUser ? (
                  <div style={{ textAlign: "center" }}>
                    <Button size="sm" simple color="danger" onClick={reset}>
                      Cancel
                    </Button>
                  </div>
                ) : null}
              </CardBody>
              <CardBody>
                <Divider variant="middle" />
              </CardBody>
              <CardBody>
                <h4 style={{ margin: "0" }}>Current users</h4>
                {!users ? (
                  <CustomLinearProgress
                    color="info"
                    style={{ marginTop: "3rem" }}
                  />
                ) : (
                  <List>
                    {users
                      .sort(
                        (a, b) =>
                          usersSortBy[a.bookRole] - usersSortBy[b.bookRole]
                      )
                      .map((user, index) => (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <Avatar alt={user.displayName} src={user.photoURL}>
                              {user.displayName[0].toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={user.displayName}
                            secondary={user.email}
                          />
                          <Chip
                            style={{ marginRight: "1vh" }}
                            label={
                              user.bookRole[0].toUpperCase() +
                              user.bookRole.slice(1)
                            }
                          />
                          <ListItemSecondaryAction>
                            {user.bookRole !== "owner" ? (
                              <ShareMenu
                                onUpdate={updateUser}
                                onRemove={removeUser}
                                user={user}
                              />
                            ) : null}
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                )}
              </CardBody>
              {users &&
              defaultBook.invites &&
              Object.keys(defaultBook.invites).length > 0 ? (
                <CardBody>
                  <h4 style={{ margin: "0" }}>Invites</h4>
                  <List>
                    {Object.keys(defaultBook.invites)
                      .sort(
                        (a, b) =>
                          defaultBook.invites[b].expiry -
                          defaultBook.invites[a].expiry
                      )
                      .map((email, index) => {
                        const invite = defaultBook.invites[email];

                        const currentDate = new Date().getTime();
                        const expiryDate = invite.expiry.toMillis();

                        const diffTime = expiryDate - currentDate;

                        const diffMins = diffTime / (1000 * 60);

                        const diffHours = diffMins / 60;

                        const diffDays = diffHours / 24;

                        const expiryLabel =
                          diffTime < 0
                            ? "expired"
                            : diffHours < 1
                            ? `expires ${Math.ceil(diffMins)} min${
                                Math.ceil(diffMins) === 1 ? "" : "s"
                              }`
                            : diffDays < 1
                            ? `expires ${Math.floor(diffHours)} hour${
                                Math.floor(diffHours) === 1 ? "" : "s"
                              }`
                            : `expires ${Math.floor(diffDays)} day${
                                Math.floor(diffDays) === 1 ? "" : "s"
                              }`;

                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primary={email}
                              secondary={invite.bookRole}
                            />
                            <Chip
                              style={{ marginRight: "1vh" }}
                              label={expiryLabel}
                            />
                            <ListItemSecondaryAction>
                              <ShareInviteMenu
                                onUpdate={updateAndResendInvite}
                                onRemove={removeInvite}
                                invitedUser={{ ...invite, email }}
                                expired={diffTime < 0}
                                inviteLoading={inviteLoading}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                  </List>
                </CardBody>
              ) : null}
            </Card>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <div style={{ textAlign: "center" }}>
            <h3 className={classes.title}>
              {publicFormLoading && firebaseUser.role === "community"
                ? ""
                : publicFormData
                ? "Edit your public recipe form"
                : "Create and share public recipe form"}
            </h3>
            <a
              href="javascript:void(0)"
              onClick={() =>
                window.Beacon("article", "63366bd3cf38bc37aecf5233", {
                  type: "sidebar",
                })
              }
            >
              Request recipes from your school, organisation or community,{" "}
              <br />
              create a custom recipe request form and URL link.{" "}
            </a>
          </div>

          <Card>
            {firebaseUser.role !== "community" ? (
              <CardBody style={{ textAlign: "center" }}>
                <Heading
                  title="Upgrade to Community"
                  category="Upgrade and accept recipe submissions from anyone without requiring signup!"
                />
                <Button
                  color="info"
                  size="lg"
                  onClick={() => history.push("/plans")}
                >
                  Upgrade
                </Button>
              </CardBody>
            ) : publicFormLoading ? (
              <CardBody style={{ textAlign: "center", padding: "50px" }}>
                <CircularProgress size={40} />
              </CardBody>
            ) : (
              <CardBody style={{ textAlign: "center" }}>
                <PublicForm
                  {...{
                    warningAlert,
                    errorAlert,
                    successAlert,
                    loadingAlert,
                    hideAlert,
                    currentUser,
                    publicFormData,
                  }}
                  bookId={firebaseUser.defaultBook}
                />
              </CardBody>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
