import React, { useState, useEffect } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import Heading from "components/Heading/Heading";

import Button from "components/CustomButtons/Button.js";

import Paperform from "components/Paperform";

import MUIDataTable, { TableFilterList } from "mui-datatables";

import { app, baseAPI } from "base.js";

import Retool from "react-retool";

export default function Orders(props) {
  const {
    currentUser,
    firebaseUser,
    defaultBook,
    errorAlert,
    warningAlert,
    loadingAlert,
    successAlert,
    hideAlert,
    scrollToTop,
  } = props;

  const [orderData, setOrderData] = useState(null);

  const [newOrder, setNewOrder] = useState(false);

  const [newOrderPreload, setNewOrderPreload] = useState(false);

  const [insidePagesCount, setInsidePagesCount] = useState(null);

  const [publicProfile, setPublicProfile] = useState(null);

  const [retoolHeight, setRetoolHeight] = useState(100);

  const returnToHome = () => {
    hideAlert();
    setNewOrder(false);
  };

  const openXero = (xeroUrl, payNow) => {
    window.open(xeroUrl + (payNow ? "#paynow" : ""), "_blank");
    returnToHome();
  };

  const createInvoice = async (invoice) => {
    loadingAlert("Please do not close this window or refresh the page.");

    const token = await currentUser.getIdToken(true);

    const headers = new Headers();

    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Content-type", "application/json");

    try {
      const response = await fetch(`${baseAPI}/publish/create-invoice`, {
        method: "POST",
        headers,
        body: JSON.stringify(invoice),
      });

      const result = await response.json();

      if (result.payNow) {
        successAlert(
          "Your invoice is ready",
          null,
          () => openXero(result.invoiceLink, true),
          {
            disableCancel: true,
            confirmText: "Pay Now",
          }
        );
      } else {
        successAlert(
          "Your quote request has been submitted",
          "We will provide price options for freight to complete your order within the next business day.",
          () => returnToHome(),
          {
            disableCancel: true,
            confirmText: "Okay",
          }
        );
      }
    } catch (e) {
      console.error(e);
      console.log("Could not create invoice");
      errorAlert("Something went wrong.", "Please try again");
    }
  };

  const placeNewOrder = async () => {
    setNewOrderPreload(true);

    try {
      await Promise.all([getInsidePages(), getPublicProfile()]);

      setNewOrder(true);
    } catch (e) {
      errorAlert(
        "Something went wrong",
        "Please try again or contact support@createcookbooks.com if this persists."
      );
      console.log(e);
    }

    setNewOrderPreload(false);
  };

  const getInsidePages = async () => {
    const token = await currentUser.getIdToken(true);

    const headers = new Headers();

    headers.append("Authorization", `Bearer ${token}`);

    const response = await fetch(
      `${baseAPI}/publish/get-inside-pages-count/${firebaseUser.defaultBook}`,
      { headers }
    );

    if (response.status === 200) {
      const result = await response.json();

      setInsidePagesCount(result.count);
    }
  };

  const getPublicProfile = async () => {
    const profileDoc = app
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("public")
      .doc("profile");

    const doc = await profileDoc.get();

    if (doc.exists) {
      const data = doc.data();

      setPublicProfile(data);
    } else {
      setPublicProfile({});
    }
  };

  const col = [
    {
      name: "id",
      label: "Invoice ID",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
      },
    },
    {
      name: "order",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "date",
      label: "Date",
    },
    {
      name: "updates",
      label: "Updates",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: false,
      },
    },
    {
      name: "btn",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    if (!newOrder) {
      setOrderData(null);
      fetchOrders();
    }
  }, [newOrder]);

  const formatDateString = (time) => {
    const opt = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const date = new Date(`${time} UTC`).toLocaleString(undefined, opt);

    const dateBackup = new Date(time).toLocaleString(undefined, opt);

    const noOptionDate = new Date(`${time} UTC`).toLocaleString();

    const noOptionDateBackup = new Date(time).toLocaleString();

    const defaultDate = new Date(`${time} UTC`).toLocaleString("en-US", opt);

    const defaultDateBackup = new Date(time).toLocaleString("en-US", opt);

    if (date !== "Invalid Date") return date;

    if (dateBackup !== "Invalid Date") return dateBackup;

    if (noOptionDate !== "Invalid Date") return noOptionDate;

    if (noOptionDateBackup !== "Invalid Date") return noOptionDateBackup;

    if (defaultDate !== "Invalid Date") return defaultDate;

    if (defaultDateBackup !== "Invalid Date") return defaultDateBackup;

    return time;
  };

  const fetchOrders = async () => {
    try {
      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseAPI}/publish/get-orders/${firebaseUser.defaultBook}`,
        { headers }
      );

      const result = await response.json();

      const data = result.invoices.map((invoice) => {
        const dueDate = new Date(invoice.dueDate).getTime();
        const dateNow = new Date().getTime();

        const dateDiff = Math.ceil((dueDate - dateNow) / (1000 * 60 * 60 * 24));
        const statusMap = {
          pay: {
            status:
              dateDiff < 0
                ? "Expired (Create a new order)"
                : "Awaiting Payment (" +
                  (dateDiff > 0
                    ? "Due in " + dateDiff + " days"
                    : "Due Today") +
                  ")",
            btnText: "Pay now",
            btnColour: "success",
            btn: true,
          },
          paid: {
            status: "Paid",
            btnColour: undefined,
            btnText: "View order",
            btn: true,
          },
          quote: { status: "Pending", btn: false },
        };

        return {
          id: invoice.invoiceID,
          order: invoice.invoiceNumber,
          date: formatDateString(invoice.date),
          status: statusMap[invoice.status].status,
          updates: invoice.updates,
          total:
            new Intl.NumberFormat(undefined, {
              currency: invoice.currency,
              style: "currency",
            }).format(invoice.total) + (invoice.status === "quote" ? "*" : ""),
          btn: statusMap[invoice.status].btn ? (
            <Button
              color={statusMap[invoice.status].btnColour}
              size="sm"
              key={invoice.invoiceNumber}
              onClick={() =>
                openXero(invoice.invoiceLink, invoice.status === "pay")
              }
            >
              {statusMap[invoice.status].btnText}
            </Button>
          ) : null,
        };
      });

      console.log(data);

      console.log("ORDER DATA");
      setOrderData(data);
    } catch (e) {
      console.error(e);
      console.log("Could not fetch orders");
      errorAlert(
        "Could not fetch your orders",
        "Please reload the page and try again"
      );
      setOrderData([]);
    }
  };

  const onSendData = async (data) => {
    console.log(data);

    if (data.invoice) {
      await createInvoice({
        ...data.invoice,
        bookId: data.invoice.bookId || firebaseUser.defaultBook,
      });
    }

    if (data.page) {
      await scrollToTop(75);
    }

    if (data.height) {
      await setRetoolHeight(data.height);
    }
  };

  const data = [
    {
      order: "abc123",
      date: "12345",
      description: `Large book
      small book`,
      status: "Awaiting payment",
      total: "Total",
      btn: (
        <Button color="success" size="sm">
          Pay now
        </Button>
      ),
    },
    {
      order: "abc123",
      date: "12345",
      description: `Large book
        small book`,
      status: "Enter details and sign off",
      total: "Total",
      btn: (
        <Button color="warning" size="sm">
          Sign off
        </Button>
      ),
    },
    {
      order: "abc123",
      date: "12345",
      description: `Large book
          small book`,
      status: "Confirm quoted price",
      total: "Total",
      btn: (
        <Button color="warning" size="sm">
          Open Quote
        </Button>
      ),
    },
    {
      order: "abc123",
      date: "12345",
      description: `Large book
            small book`,
      status: "Awaiting manual quoted price",
      total: "N/A",
      btn: null,
    },
    {
      order: "abc123",
      date: "12345",
      description: `Large book
              small book`,
      status: "Shipped via DHL: Tracking no. 12345",
      total: "Total",
      btn: (
        <Button color="primary" size="sm">
          Track order
        </Button>
      ),
    },
    {
      order: "abc123",
      date: "12345",
      description: `Large book
          small book`,
      status: "Delivered",
      total: "Total",
      btn: <Button size="sm">View order</Button>,
    },
  ];

  const options = {
    download: false,
    filter: false,
    print: false,
    pagination: false,
    searchPlaceholder: "Search for your order",
    selectableRows: "none",
    viewColumns: false,
  };

  if (newOrderPreload) {
    return (
      <div>
        <Heading
          title="Fetching your cookbook details"
          category="This should only take a minute"
          textAlign="center"
        />
        <CustomLinearProgress color="info" />
      </div>
    );
  }

  if (newOrder) {
    /* return (
      <Paperform
        paperFormID="u4vdlqyi"
        hasPrefill={false}
        //prefill={paperformPrefill}
        //onSubmission={handlePaperformSubmission}
        disableLoadingBar={true}
      />
    );
 */
    return (
      <div>
        <div
          style={{
            height: `${retoolHeight + 50}px`,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: `${retoolHeight + 200}px`,
              overflow: "hidden",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <Retool
              url="https://createcookbooks.retool.com/embedded/public/b328d33c-0b48-4376-87e9-21791935e858"
              data={{
                user: {
                  user: true,
                  uid: currentUser.uid || null,
                  name: currentUser.displayName || null,
                  email: currentUser.email || null,
                  package: firebaseUser.role || null,
                  organisation: publicProfile.organisation || null,
                },
                placeOrder: {
                  placeOrder: true,
                  bookId: firebaseUser.defaultBook || null,
                  currency: firebaseUser.currency || null,
                  organisation: publicProfile.organisation || null,
                  name: currentUser.displayName || null,
                  email: currentUser.email || null,
                  address: publicProfile.address,
                  phone: currentUser.phoneNumber || null,
                  previouslyDiscounted: firebaseUser.packageDiscounted || false,
                },
                book: {
                  pages: insidePagesCount || null,
                  size: defaultBook.bookInfo.size || null,
                  currency: firebaseUser.currency || null,
                  country:
                    publicProfile.country ||
                    publicProfile.address?.country ||
                    null,
                  postcode: publicProfile.address?.postcode || null,
                  bookTitle: defaultBook.title || null,
                },
              }}
              onData={onSendData}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "80px",
            textAlign: "center",
          }}
        >
          <Button round size="sm" onClick={() => setNewOrder(false)}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Heading
        title="Publish my cookbook"
        category={
          <a href="https://www.createcookbooks.com/checklist" target="_blank">
            Before placing an order please view the checklist here
          </a>
        }
        textAlign="center"
      />
      {orderData ? (
        <>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <Button
              size="lg"
              color="info"
              onClick={() =>
                warningAlert(
                  "Checklist",
                  <>
                    <p>
                      Before you place your order, please review the checklist
                      on our website
                    </p>
                    <Button
                      size="lg"
                      color="success"
                      onClick={() =>
                        window.open(
                          "https://www.createcookbooks.com/checklist",
                          "_blank"
                        )
                      }
                    >
                      Open Checklist
                    </Button>
                  </>,
                  () => {
                    placeNewOrder();
                    hideAlert();
                  },
                  {
                    cancelText: "Not now",
                    confirmText: "Continue to place order ",
                  }
                )
              }
            >
              Place New Order
            </Button>
          </div>
          <div>
            <MUIDataTable
              title={`View Orders`}
              data={orderData}
              columns={col}
              options={options}
            />
          </div>
        </>
      ) : (
        <CustomLinearProgress />
      )}
    </div>
  );
}
