import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Paperform from "components/Paperform.js";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import SvgIcon from "@material-ui/core/SvgIcon";
import Hidden from "@material-ui/core/Hidden";

import { baseAPI } from "base.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

import Link from "@material-ui/core/Link";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const planVals = {
  AUD: {
    premium: 39,
    community: 99,
  },
  CAD: {
    premium: 35,
    community: 95,
  },
  GBP: {
    premium: 20,
    community: 55,
  },
  USD: {
    premium: 29,
    community: 79,
  },
  NZD: {
    premium: 40,
    community: 105,
  },
  EUR: {
    premium: 25,
    community: 65,
  },
};

const useStyles = makeStyles(styles);

let timeout;

export default function Plans(props) {
  const classes = useStyles();

  const { currentUser, firebaseUser, history, warningAlert, hideAlert } = props;

  const [loading, setLoading] = useState(true);
  const role = firebaseUser && firebaseUser.role ? firebaseUser.role : null;

  const [prefill, setPrefill] = useState(null);

  const [selectedPlan, setSelectedPlan] = useState(null);

  const [authToken, setAuthToken] = useState(null);

  const [currency, setCurrency] = useState("USD");

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency || "USD",
    maximumFractionDigits: 0,
  });

  const [initSignup, setInitSignup] = useState(false);

  const payment = {
    AUD: {
      stripe: "cc-signup-stripe-au", //stripe: "s68yt0yk"
      //paypal: "fy5k7eno",
    },
    USD: {
      stripe: "cc-signup-stripe-us", //stripe: "a41vrb6k",
    },
    CAD: {
      stripe: "cc-signup-stripe-ca",
    },
    GBP: {
      stripe: "cc-signup-stripe-gb",
    },
    EUR: {
      stripe: "cc-signup-stripe-eu",
    },
    NZD: {
      stripe: "cc-signup-stripe-nz",
    },
  };

  const Embolden = (text) => (
    <b style={{ color: "unset" }}>
      <i>{text}</i>
    </b>
  );

  const plans = {
    starter: [
      "Safe and secure recipe library",
      "Blank template",
      "Help articles, video tutorials, and guide",
      "Customer support",
    ],
    premium: [
      <b style={{ color: "unset" }}>
        Print 5+ &amp; receive {formatter.format(planVals[currency].premium)}{" "}
        discount on first order
      </b>,
      <>Everything in {Embolden("Starter")} plus:</>,
      "2 premium templates valued at US$40",
      "Preview flipbook",
      "Unlimited premium photo library images",
      "Customised recipe page layout",
      "Invite others to contribute their recipes",
      "Upload personal images alongside recipes",
      "100s of ready-made design pages",
      "Unlimited icons",
      "Layout check prior to print",
      "Priority customer support",
      "Free ebook with 5+ printed books",
    ],
    community: [
      <b style={{ color: "unset" }}>
        Print 50+ &amp; receive {formatter.format(planVals[currency].community)}{" "}
        discount on first order
      </b>,
      <>
        Everything in {Embolden("Starter")} &amp; {Embolden("Premium")} plus:
      </>,
      "3 premium templates",
      "Customised public recipe request form",
      "Custom recipe fields",
      "First stage layout",
      "Invite others to edit recipes and layout",
      "Collaborate with team members inside layout in real-time",
      "Fundraiser tools, tips & templates",
      "Phone meetings",
      "All 3 ebooks with 50+ printed books",
    ],
  };

  useEffect(() => {
    if (firebaseUser && firebaseUser.currency) {
      setCurrency(
        planVals[firebaseUser.currency] ? firebaseUser.currency : "USD"
      );
      setLoading(false);
      setInitSignup(false);
    } else if (firebaseUser) {
      fetch(`${baseAPI}/users/default-currency`)
        .then((response) => response.text())
        .then((defaultCurrency) => {
          setCurrency(planVals[defaultCurrency] ? defaultCurrency : "USD");
          setLoading(false);
          setInitSignup(false);
        })
        .catch((err) => {
          console.error(err);
          setCurrency("USD");
          setLoading(false);
          setInitSignup(false);
        });
    } else {
      setInitSignup(true);
      currentUser.getIdToken(true).then((token) => {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        fetch(`${baseAPI}/users/on-user-create`, {
          headers,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.currency) setCurrency(result.currency);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      });
    }
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      createPaperformPrefill();
    } else {
      setPrefill(null);
      setAuthToken(null);
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedPlan]);

  const createPaperformPrefill = () => {
    timeout = setTimeout(() => {
      warningAlert(
        "Timed out",
        "The checkout page has timed out. Please click try again to refresh.",
        () => {
          setPrefill(null);
          hideAlert();
          createPaperformPrefill();
        },
        {
          hideCancel: true,
          onCancel: () => null,
          confirmText: "Try again",
        }
      );
    }, 3600000);

    currentUser.getIdToken(true).then((token) => {
      setAuthToken(token);
    });
  };

  useEffect(() => {
    if (authToken) {
      setPrefill(
        `auth=${authToken}&uid=${currentUser.uid}&email=${
          currentUser.email
        }&name=${currentUser.displayName}&plan=${selectedPlan}${
          role ? `&currentPlan=${role}` : ""
        }`
      );
      setAuthToken(null);
    }
  }, [authToken]);

  /*useEffect(() => {
    if (initSignup && firebaseUser) {
      history.push("/books");
    }
  }, [initSignup, firebaseUser]);*/

  const freeSignup = async () => {
    if (currentUser) {
      try {
        setLoading(true);
        const token = await currentUser.getIdToken(true);

        const headers = new Headers();

        headers.append("Authorization", `Bearer ${token}`);

        const nameArr = currentUser.displayName.split(" ");

        window.gtag("set", "user_data", {
          email: currentUser.email,
          address: {
            first_name: nameArr[0],
            last_name: nameArr.length > 0 ? nameArr.slice(1).join(" ") : "",
          },
        });

        window.gtag("event", "conversion", {
          send_to: "AW-311881269/lZldCN63lfECELXc25QB",
          value: 0,
          currency: currency || "USD",
        });

        window.fbq("track", "CompleteRegistration");

        const response = await fetch(baseAPI + "/users/signup/free", {
          headers,
        });

        const result = await response.json();

        if (!result.userCreated) {
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <CustomLinearProgress />
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        {!selectedPlan ? (
          <GridContainer justify="center">
            <GridItem xs={5} style={{ textAlign: "center" }}>
              <h2 className={classes.title}>
                {role ? "Upgrade your" : "Select a"} package
              </h2>

              {!firebaseUser || firebaseUser.role === "starter" ? (
                <Link
                  component="button"
                  data-paperform-id="plan-quiz"
                  data-popup-button="1"
                  data-prefill={`email=${currentUser.email}`}
                  style={{ color: "white", marginBottom: "30px" }}
                >
                  Not sure? Click here
                </Link>
              ) : null}
              {firebaseUser && firebaseUser.currency ? null : (
                <Card
                  style={{
                    padding: "12px",
                    width: "130px",
                    margin: "0 auto",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ margin: "0 auto" }}
                  >
                    <InputLabel id="currency-select-label">Currency</InputLabel>
                    <Select
                      labelId="currency-select-label"
                      id="currency-select"
                      value={currency || "USD"}
                      onChange={(e) => setCurrency(e.target.value)}
                      label="Currency"
                      style={{ height: "40px" }}
                    >
                      <MenuItem value="AUD">🇦🇺 AUD</MenuItem>
                      <MenuItem value="GBP">🇬🇧 GBP</MenuItem>
                      <MenuItem value="CAD">🇨🇦 CAD</MenuItem>
                      <MenuItem value="EUR">🇪🇺 EUR</MenuItem>
                      <MenuItem value="NZD">🇳🇿 NZD</MenuItem>
                      <MenuItem value="USD">🇺🇸 USD</MenuItem>
                    </Select>
                  </FormControl>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        ) : null}
        <GridContainer justify="center">
          {selectedPlan ? (
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardBody>
                  <Paperform
                    paperFormID={payment[currency].stripe}
                    hasPrefill={true}
                    prefill={prefill}
                    onSubmission={(data) => {
                      const nameArr = currentUser.displayName.split(" ");

                      window.gtag("set", "user_data", {
                        email: currentUser.email,
                        address: {
                          first_name: nameArr[0],
                          last_name:
                            nameArr.length > 0
                              ? nameArr.slice(1).join(" ")
                              : "",
                        },
                      });

                      window.gtag("event", "conversion", {
                        send_to: "AW-311881269/lZldCN63lfECELXc25QB",
                        value: data.total,
                        currency: currency || "USD",
                      });

                      window.fbq("track", "Purchase", {
                        currency: currency || "USD",
                        value: data.total,
                      });
                    }}
                  />
                </CardBody>
              </Card>
              <div style={{ textAlign: "center" }}>
                <Button
                  round
                  color="rose"
                  onClick={() => setSelectedPlan(null)}
                >
                  Change package
                </Button>
              </div>
            </GridItem>
          ) : (
            <>
              <GridItem
                xs={8}
                sm={6}
                md={3}
                className={
                  role && role !== "starter" ? "" : classes.marginTop30
                }
              >
                <Card pricing raised>
                  <CardBody pricing>
                    <Hidden smDown lgUp>
                      <h3
                        className={classes.cardCategory}
                        style={{ color: role ? "#999" : "unset" }}
                      >
                        Starter
                      </h3>
                    </Hidden>
                    <Hidden only={["md"]}>
                      <h2
                        className={classes.cardCategory}
                        style={{ color: role ? "#999" : "unset" }}
                      >
                        Starter
                      </h2>
                    </Hidden>
                    <div className={classes.icon}>
                      <PersonIcon
                        className={classes.iconRose}
                        style={{ color: "#00acc1" }}
                      />
                    </div>

                    <h3
                      className={`${classes.cardTitle} ${classes.marginTop30}`}
                    >
                      <span style={{ color: role ? "gray" : "black" }}>
                        {firebaseUser ? formatter.format(0) : "Free"}
                      </span>
                    </h3>
                    {firebaseUser && firebaseUser.role === "starter" ? (
                      <Button disabled>Current package</Button>
                    ) : firebaseUser && firebaseUser.role ? (
                      <Button disabled>Select package</Button>
                    ) : (
                      <Button color="info" onClick={freeSignup}>
                        Select package
                      </Button>
                    )}

                    <h6
                      className={role ? classes.cardCategory : ""}
                      style={{ fontSize: "1em" }}
                    >
                      FEATURES
                    </h6>

                    <ul>
                      {plans.starter.map((desc, index) => (
                        <li
                          key={index}
                          style={{ color: role ? "#999" : "unset" }}
                        >
                          <p className={classes.cardDescription}>{desc}</p>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={10} sm={6} md={3}>
                <Card
                  pricing
                  raised
                  style={
                    role && role !== "starter"
                      ? {
                          backgroundColor: "#FFF",
                        }
                      : {
                          backgroundColor: "#00acc1",
                          color: "#FFF",
                          boxShadow:
                            "0 16px 38px -12px rgb(0 0 0), 0 4px 25px 0px rgb(0 0 0), 0 8px 10px -5px rgb(0 0 0)",
                          fontWeight: "500",
                        }
                  }
                >
                  {role && role !== "starter" ? null : (
                    <div
                      style={{
                        height: "30px",
                        backgroundColor: "red",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                        fonSize: "1em",
                        textTransform: "uppercase",
                        fontWeight: "500",
                        color: "white",
                        lineHeight: "30px",
                      }}
                    >
                      Best Value
                    </div>
                  )}
                  <CardBody pricing>
                    <Hidden smDown lgUp>
                      <h3
                        className={classes.cardCategory}
                        style={{
                          color: role && role !== "starter" ? "#999" : "unset",
                        }}
                      >
                        Premium
                      </h3>
                    </Hidden>
                    <Hidden only={["md"]}>
                      <h2
                        className={classes.cardCategory}
                        style={{
                          color: role && role !== "starter" ? "#999" : "unset",
                        }}
                      >
                        Premium
                      </h2>
                    </Hidden>
                    <div className={classes.icon}>
                      <GroupIcon
                        className={classes.iconRose}
                        style={{
                          color:
                            role && role !== "starter" ? "#00acc1" : "#FFF",
                        }}
                      />
                    </div>

                    <h3
                      className={`${classes.cardTitle} ${classes.marginTop30}`}
                      style={
                        role && role !== "starter"
                          ? {
                              color: "gray",
                            }
                          : {
                              color: "white",
                              fontSize: "24pt",
                              lineHeight: "24pt",
                              height: "35px",
                            }
                      }
                    >
                      <span>
                        {formatter.format(planVals[currency].premium)}
                      </span>
                    </h3>

                    {firebaseUser && firebaseUser.role === "premium" ? (
                      <Button disabled>Current package</Button>
                    ) : firebaseUser && firebaseUser.role === "community" ? (
                      <Button disabled>Select package</Button>
                    ) : (
                      <Button
                        color="white"
                        style={{ color: "black" }}
                        onClick={() => setSelectedPlan("premium")}
                      >
                        {role ? "Upgrade" : "Select package"}
                      </Button>
                    )}

                    <h6
                      className={
                        role === "premium" || role === "community"
                          ? classes.cardCategory
                          : ""
                      }
                      style={{ fontSize: "1em" }}
                    >
                      FEATURES
                    </h6>

                    <ul>
                      {plans.premium.map((desc, index) => (
                        <li
                          key={index}
                          style={
                            role && role !== "starter"
                              ? {}
                              : {
                                  color: "#FFF",
                                  borderBottom: `1px solid rgba(255, 255, 255, ${
                                    plans.premium.length === index + 1 ? 0 : 0.3
                                  })`,
                                }
                          }
                        >
                          <p className={classes.cardDescription}>{desc}</p>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem
                xs={8}
                sm={6}
                md={3}
                className={
                  role && role !== "starter" ? "" : classes.marginTop30
                }
              >
                <Card pricing raised>
                  <CardBody pricing>
                    <Hidden smDown lgUp>
                      <h3
                        className={classes.cardCategory}
                        style={{ color: role ? "#999" : "unset" }}
                      >
                        Community
                      </h3>
                    </Hidden>
                    <Hidden only={["md"]}>
                      <h2
                        className={classes.cardCategory}
                        style={{ color: role ? "#999" : "unset" }}
                      >
                        Community
                      </h2>
                    </Hidden>

                    <div className={classes.icon}>
                      <SvgIcon
                        className={classes.iconRose}
                        style={{ color: "#00acc1" }}
                      >
                        <path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z" />
                      </SvgIcon>
                    </div>
                    <h3
                      className={`${classes.cardTitle} ${classes.marginTop30}`}
                    >
                      <span
                        style={{
                          color: role === "community" ? "gray" : "black",
                        }}
                      >
                        {role === "premium"
                          ? formatter.format(
                              planVals[currency].community -
                                planVals[currency].premium
                            )
                          : formatter.format(planVals[currency].community)}
                        {/*role === "premium" ? <Muted>Was $99</Muted> : null*/}
                      </span>
                    </h3>
                    {firebaseUser && firebaseUser.role === "community" ? (
                      <Button color="info" disabled>
                        Current package
                      </Button>
                    ) : (
                      <Button
                        color="info"
                        onClick={() => setSelectedPlan("community")}
                      >
                        {role ? "Upgrade" : "Select package"}
                      </Button>
                    )}

                    <h6
                      className={
                        role === "community" ? classes.cardCategory : ""
                      }
                      style={{ fontSize: "1em" }}
                    >
                      FEATURES
                    </h6>

                    <ul>
                      {plans.community.map((desc, index) => (
                        <li
                          key={index}
                          style={{
                            color: role === "community" ? "#999" : "unset",
                          }}
                        >
                          <p className={classes.cardDescription}>{desc}</p>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </GridItem>
            </>
          )}
        </GridContainer>
      </div>
    );
  }
}
