import React, { useEffect, useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import Edit from "@material-ui/icons/Edit";

import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import CardText from "components/Card/CardText.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Muted from "components/Typography/Muted";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PublishIcon from "@material-ui/icons/Publish";
import BookIcon from "@material-ui/icons/Book";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import ImageIcon from "@material-ui/icons/Image";

import { Link } from "react-router-dom";
import { app } from "base.js";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const {
    firebaseUser,
    defaultBook,
    currentUser,
    history,
    warningAlert,
    googleAlert,
    hideAlert,
    googleProvider,
  } = props;

  const [recentRecipes, setRecentRecipes] = useState(null);

  const hasSubmittedBy =
    defaultBook && defaultBook.formSettings.showFields.indexOf("name") !== -1;

  useEffect(() => {
    if (firebaseUser.defaultBook && defaultBook) {
      const bookId = firebaseUser.defaultBook;

      const baseRef = app.database().ref(`books/${bookId}/recipes`);

      const ref =
        defaultBook.userBookRole === "contributor"
          ? baseRef.orderByChild("uid").equalTo(currentUser.uid).limitToLast(5)
          : baseRef.limitToLast(5);

      const onError = () => {
        ref.off("value");

        currentUser.getIdToken(true).then(() => {
          rtdbFn();
        });
      };

      const rtdbFn = () =>
        ref.on(
          "value",
          (snapshot) => {
            const size = snapshot.numChildren();
            const arr = new Array(size);
            let i = 0;
            snapshot.forEach((snapshotChild) => {
              const val = snapshotChild.val();

              const { chapter, dateSubmitted, name, title } = val;

              const date = new Date(dateSubmitted).toLocaleString();

              console.log(val);

              console.log(defaultBook.chapters);

              console.log(firebaseUser.defaultBook);

              arr[size - 1 - i] = [
                title,
                name,
                defaultBook.chapters[chapter].name,
                date,
              ];

              i++;
            });

            setRecentRecipes(arr);
          },
          (err) => {
            console.error(err);
            onError();
          }
        );

      rtdbFn();

      return () => {
        hideAlert();

        ref.off();
      };
    } else {
      setRecentRecipes([]);

      return () => {
        hideAlert();
      };
    }
  }, [firebaseUser.defaultBook]);

  return (
    <div>
      <GridContainer alignItems="flex-end">
        <GridItem xs={8} sm={4} md={4} lg={3} style={{ margin: "0 auto" }}>
          <div>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <Link
                  to={
                    defaultBook.hasTemplate &&
                    (defaultBook.userBookRole === "designer" ||
                      defaultBook.userBookRole === "owner")
                      ? "/cookbook/layout"
                      : !defaultBook.hasTemplate &&
                        defaultBook.userBookRole === "owner"
                      ? "/cookbook/template"
                      : defaultBook.userBookRole === "editor"
                      ? "/recipes/library"
                      : "/recipes/submit"
                  }
                >
                  <img src={defaultBook.thumbnail} alt="..." />
                </Link>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  {defaultBook.hasTemplate &&
                  (defaultBook.userBookRole === "designer" ||
                    defaultBook.userBookRole === "owner") ? (
                    <Tooltip
                      id="tooltip-preview"
                      title="Preview"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        onClick={() => history.push("/cookbook/preview")}
                        color="success"
                        simple
                        justIcon
                      >
                        <VisibilityIcon className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {defaultBook.hasTemplate &&
                  (defaultBook.userBookRole === "designer" ||
                    defaultBook.userBookRole === "owner") ? (
                    <Tooltip
                      id="tooltip-layout"
                      title="Layout"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        onClick={() => history.push("/cookbook/layout")}
                        color="danger"
                        simple
                        justIcon
                      >
                        <Edit className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {defaultBook.userBookRole === "owner" ? (
                    <Tooltip
                      id="tooltip-settings"
                      title="Book Settings"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        onClick={() => history.push("/book-settings")}
                        color="transparent"
                        simple
                        justIcon
                      >
                        <SettingsIcon className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  ) : null}
                </div>
                <h3 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {defaultBook.title}
                  </a>
                </h3>
                <p className={classes.cardProductDesciprion}>
                  {defaultBook.description}
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h6>
                    {new Date(defaultBook.created_at).toLocaleDateString()}
                  </h6>
                </div>
                <div>
                  <Muted>
                    {defaultBook.userBookRole[0].toUpperCase() +
                      defaultBook.userBookRole.slice(1)}
                  </Muted>
                </div>
              </CardFooter>
            </Card>
          </div>
        </GridItem>
        <GridItem xs={12} sm={8} md={8} lg={9} style={{ marginBottom: "auto" }}>
          <GridContainer direction="column" justify="space-between">
            <GridItem>
              <h1 style={{ textAlign: "center" }}>
                Welcome, {currentUser.displayName}
              </h1>
            </GridItem>
            <GridItem>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitleWhite}>
                      Recently submitted recipes
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {recentRecipes ? (
                    recentRecipes.length > 0 ? (
                      <>
                        <Table
                          hover
                          tableHeaderColor="info"
                          tableHead={
                            matches && hasSubmittedBy
                              ? [
                                  "Recipe name",
                                  "Submitted by",
                                  "Chapter",
                                  "Date",
                                ]
                              : matches && !hasSubmittedBy
                              ? ["Recipe name", "Chapter", "Date"]
                              : !matches && hasSubmittedBy
                              ? ["Recipe name", "Submitted by"]
                              : ["Recipe name", "Chapter"]
                          }
                          tableData={
                            matches && hasSubmittedBy
                              ? recentRecipes
                              : matches && !hasSubmittedBy
                              ? recentRecipes.map((row) => [
                                  row[0],
                                  row[2],
                                  row[3],
                                ])
                              : !matches && hasSubmittedBy
                              ? recentRecipes.map((row) => [row[0], row[1]])
                              : recentRecipes.map((row) => [row[0], row[2]])
                          }
                        />

                        <Button
                          size="sm"
                          onClick={() => history.push("/recipes/library")}
                        >
                          View all
                        </Button>
                      </>
                    ) : (
                      <div style={{ textAlign: "center", margin: "auto" }}>
                        <h2>No recipes have been added</h2>

                        <Button
                          color="success"
                          size="lg"
                          onClick={() => history.push("/recipes/submit")}
                        >
                          Submit a recipe
                        </Button>
                      </div>
                    )
                  ) : (
                    <CustomLinearProgress color="info" />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer alignItems="flex-start" justify="center">
        <GridItem xs={6}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Recipes</h4>
              </CardText>
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <Button
                color="transparent"
                simple
                onClick={() => history.push("/recipes/library")}
              >
                <GridContainer direction="column">
                  <GridItem>
                    <FormatListBulletedIcon
                      style={{ height: "40px", width: "25px" }}
                    />
                  </GridItem>
                  <GridItem>Recipe library</GridItem>
                </GridContainer>
              </Button>
              <Button
                color="transparent"
                simple
                onClick={() => history.push("/recipes/submit")}
              >
                <GridContainer direction="column">
                  <GridItem>
                    <PublishIcon style={{ height: "40px", width: "25px" }} />
                  </GridItem>
                  <GridItem>Submit recipe</GridItem>
                </GridContainer>
              </Button>
              {defaultBook.hasTemplate &&
              defaultBook.userBookRole === "owner" ? (
                <Button
                  color="transparent"
                  simple
                  onClick={() => history.push("/recipes/import")}
                >
                  <GridContainer direction="column">
                    <GridItem>
                      <ImportExportIcon
                        style={{ height: "40px", width: "25px" }}
                      />
                    </GridItem>
                    <GridItem>Import recipes</GridItem>
                  </GridContainer>
                </Button>
              ) : null}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Cookbook</h4>
              </CardText>
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              {defaultBook.hasTemplate &&
              (defaultBook.userBookRole === "designer" ||
                defaultBook.userBookRole === "owner") ? (
                <>
                  <Button
                    color="transparent"
                    simple
                    onClick={() => history.push("/cookbook/layout")}
                  >
                    <GridContainer direction="column">
                      <GridItem>
                        <BookIcon style={{ height: "40px", width: "25px" }} />
                      </GridItem>
                      <GridItem>Cookbook layout</GridItem>
                    </GridContainer>
                  </Button>
                  <Button
                    color="transparent"
                    simple
                    onClick={() => history.push("/cookbook/preview")}
                  >
                    <GridContainer direction="column">
                      <GridItem>
                        <VisibilityIcon
                          style={{ height: "40px", width: "25px" }}
                        />
                      </GridItem>
                      <GridItem>Preview</GridItem>
                    </GridContainer>
                  </Button>
                </>
              ) : !defaultBook.hasTemplate ? (
                <Button
                  color="transparent"
                  simple
                  onClick={() => history.push("/cookbook/template")}
                >
                  <GridContainer direction="column">
                    <GridItem>
                      <BookIcon style={{ height: "40px", width: "25px" }} />
                    </GridItem>
                    <GridItem>Select a template</GridItem>
                  </GridContainer>
                </Button>
              ) : null}

              <Button
                color="transparent"
                simple
                onClick={() => history.push("/cookbook/chapters")}
              >
                <GridContainer direction="column">
                  <GridItem>
                    <BookmarksIcon style={{ height: "40px", width: "25px" }} />
                  </GridItem>
                  <GridItem>Chapters</GridItem>
                </GridContainer>
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={6} style={{ display: "none" }}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Misc shortcuts</h4>
              </CardText>
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <Button
                color="transparent"
                simple
                onClick={() => {
                  if (
                    firebaseUser.role === "starter" &&
                    (defaultBook.userBookRole === "contributor" ||
                      defaultBook.userBookRole === "editor")
                  ) {
                    warningAlert(
                      "Upgrade your plan",
                      "The Premium Photo library is only available to users on the Premium or Community plan, or shared Designer or Co-Owner users.",
                      () => {
                        hideAlert();
                        history.push("/plans");
                      },
                      {
                        confirmText: "Upgrade Plan",
                      }
                    );
                  } else {
                    if (!firebaseUser.googleRefresh) {
                      googleAlert({
                        text:
                          "The Premium Photo Library is hosted on Google Drive. To view the Premium Photo Library you must first connect your Google account.",
                        successText:
                          "Your Google Account has been successfully linked and you can now view the Premium Photo Library.",
                        successConfirmText: "Open Photo Library",
                        nextFn: () =>
                          window.open(
                            `https://drive.google.com/drive/folders/0AKb1E7IRAMDeUk9PVA${
                              googleProvider && googleProvider.email
                                ? `?authuser=${encodeURIComponent(
                                    googleProvider.email
                                  )}`
                                : ""
                            }`,
                            "_blank"
                          ),
                      });
                    } else {
                      window.open(
                        `https://drive.google.com/drive/folders/0AKb1E7IRAMDeUk9PVA${
                          googleProvider && googleProvider.email
                            ? `?authuser=${encodeURIComponent(
                                googleProvider.email
                              )}`
                            : ""
                        }`,
                        "_blank"
                      );
                    }
                  }
                }}
              >
                <GridContainer direction="column">
                  <GridItem>
                    <ImageIcon style={{ height: "40px", width: "25px" }} />
                  </GridItem>
                  <GridItem>Photo library</GridItem>
                </GridContainer>
              </Button>
              {defaultBook.userBookRole === "owner" ? (
                <Button
                  color="transparent"
                  simple
                  onClick={() => history.push("/book-settings")}
                >
                  <GridContainer direction="column">
                    <GridItem>
                      <SettingsIcon style={{ height: "40px", width: "25px" }} />
                    </GridItem>
                    <GridItem>Book settings</GridItem>
                  </GridContainer>
                </Button>
              ) : null}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
