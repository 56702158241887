import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  w100: {
    width: "100%",
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.chapter}
        </TableCell>
        <TableCell align="center">{row.count}</TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Recipes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Recipe name</TableCell>
                    <TableCell>Submitted by</TableCell>
                    {/*<TableCell>Email</TableCell>*/}
                    <TableCell>Last Edited</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.recipes.map((recipe) => (
                    <TableRow key={recipe.key}>
                      <TableCell component="th" scope="row">
                        {recipe.title}
                      </TableCell>
                      <TableCell>{recipe.name}</TableCell>
                      {/*<TableCell>{recipe.email}</TableCell>*/}
                      <TableCell>
                        {recipe.lastEdited
                          ? new Date(recipe.lastEdited).toLocaleString(
                              undefined,
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                                //hour12: true,
                                hour: "numeric",
                                minute: "2-digit",
                                hourCycle: "h12",
                              }
                            )
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ImportTable(props) {
  const { rows } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Chapters</TableCell>
            <TableCell align="center">Count</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.chapter} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
