import React, { useState, useEffect, useRef } from "react";

import MUIDataTable, { TableFilterList } from "mui-datatables";

import CustomToolbar from "./Components/CustomToolbar.js";

import EditPaperform from "./Components/EditPaperform.js";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LaunchIcon from "@material-ui/icons/Launch";
import CheckIcon from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ImageIcon from "@material-ui/icons/Image";
import Heading from "components/Heading/Heading.js";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Button from "components/CustomButtons/Button.js";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import CircularProgress from "@material-ui/core/CircularProgress";

import Lightbox, { useLightboxState } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/dist/plugins/captions";
import Counter from "yet-another-react-lightbox/dist/plugins/counter";
import Download from "yet-another-react-lightbox/dist/plugins/download";
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";

import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/captions/captions.css";
import "yet-another-react-lightbox/dist/plugins/counter/counter.css";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";

import { app } from "base.js";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

const FilterIcon = () => <Icon>filter_alt</Icon>;

const CustomFilterList = (props) => {
  return (
    <GridContainer alignItems="center">
      <div style={{ paddingLeft: "30px", paddingTop: "8px" }}>
        <h4>
          {props.filterList.some((filter) => filter.length > 0)
            ? "Filters"
            : "No filters"}
        </h4>
      </div>

      <TableFilterList {...props} />
    </GridContainer>
  );
};

export default function Recipes(props) {
  const [recipeData, setRecipeData] = useState(null);
  const [recipesRef, setRecipesRef] = useState(null);

  const [loading, setLoading] = useState(true);
  const [updateLoad, setUpdateLoad] = useState(false);

  const [chapterView, setChapterView] = useState(null);

  const [deleteView, setDeleteView] = useState(false);
  const [filterList, setFilterList] = useState(null);

  const [imageLightbox, setImageLightbox] = useState(null);

  const thumbnailsRef = useRef(null);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [iframe, setIframe] = useState(false);

  const {
    defaultBook,
    firebaseUser,
    currentUser,
    history,
    loadingAlert,
    successAlert,
    errorAlert,
    warningAlert,
    openWindowInside,
    hideAlert,
  } = props;

  const chapterNameToId = Object.fromEntries(
    Object.entries(defaultBook.chapters).map(([id, obj]) => [obj.name, id])
  );

  const { columns, userDoc } = firebaseUser;
  const isImporting = defaultBook.importing || firebaseUser.busy;

  const bookId = firebaseUser.defaultBook;

  const deleteImageWarning = (e) => {
    setOpenLightbox(false);

    console.log(e);
    console.log(e?.target);

    const image = imageLightbox.images[currentSlideIndex];
    const recipe = recipeData[imageLightbox.index];

    warningAlert(
      `Delete the following?`,
      <>
        <p>
          <i>Note: You cannot undo this action.</i>
        </p>
        <img
          src={image.src}
          style={{
            maxWidth: "200px",
            maxHeight: "200px",
            objectFit: "contain",
          }}
        />
        <h6>{image.caption}</h6>
      </>,
      () => deleteImage(image, recipe),
      {
        confirmText: "Yes, Delete!",
        onCancel: () => {
          setCurrentSlideIndex(0);
          hideAlert();
          //setOpenLightbox(true)
        },
      }
    );
  };

  const deleteImage = async (image, recipe) => {
    if (!recipe.images.some((img) => image.src === img.src)) {
      return errorAlert("Something went wrong", "");
    }

    loadingAlert();

    await new Promise((res, rej) => setTimeout(res, 2000));
    successAlert("Deleted!", `Deleted ${image?.caption}`);
  };

  useEffect(() => {
    setLoading(true);

    const chId = props.location.state ? props.location.state.chapter : null;

    if (chId && defaultBook.chapters[chId]) {
      setChapterView(defaultBook.chapters[chId].name);
    }

    const baseRef = app.database().ref(`books/${bookId}/recipes`);

    const ref =
      defaultBook.userBookRole === "contributor"
        ? baseRef.orderByChild("uid").equalTo(currentUser.uid)
        : baseRef;

    setRecipesRef(baseRef);

    ref.on("value", (snapshot) => {
      const arr = [];
      snapshot.forEach((snapshotChild) => {
        const val = snapshotChild.val();

        arr.push({
          ...val,
          chapter: defaultBook.chapters[val.chapter]
            ? defaultBook.chapters[val.chapter].name
            : null,
          key: snapshotChild.key,
        });
      });
      setRecipeData(arr);
    });

    return () => ref.off();
  }, []);

  useEffect(() => {
    if (recipeData) {
      setLoading(false);
      setUpdateLoad(false);
    }
  }, [recipeData]);

  useEffect(() => {
    if (imageLightbox) {
      setOpenLightbox(true);
    }
  }, [imageLightbox]);

  const toggleDelete = () => setDeleteView(!deleteView);

  const deleteRows = async (rows) => {
    console.log(rows);
    if (isImporting) {
      return errorAlert(
        "Unable to delete recipes.",
        "An import of recipes is in progress."
      );
    }
    if (updateLoad) {
      return errorAlert(
        "Unable to delete recipes.",
        "Something went wrong. Please try again."
      );
    }

    loadingAlert();

    const delObj = {};
    let count = 0;

    const recipesWithImages = [];

    rows.data.forEach((row) => {
      const rowData = recipeData[row.dataIndex];

      const key = rowData.key;

      if (rowData.images) {
        recipesWithImages.push(`${key}/${rowData.uid}`);
      }

      if (key) {
        delObj[key] = null;
        count++;
      }
    });

    const delImgPromises = recipesWithImages.flatMap((key) => {
      const storageRef = app
        .storage()
        .ref(`books/${firebaseUser.defaultBook}/recipes/${key}`);

      return storageRef.listAll().then((list) => {
        console.log(list);
        const delImgs = list.items.map((img) => {
          return img.delete();
        });

        return delImgs;
      });
    });

    try {
      if (delImgPromises.length > 0) {
        await Promise.all(delImgPromises);
      }

      recipesRef.update(delObj, (err) => {
        if (err) {
          console.log(err);
          console.log(delObj);
          errorAlert("Unable to delete recipes.", `Error: ${err.message}`);
        } else {
          successAlert(
            "Success!",
            `${count} recipe${
              count === 1 ? "" : "s"
            } were successfully removed.`
          );
        }
      });
    } catch (e) {
      console.log(e);
      errorAlert(
        "Unable to delete recipes.",
        "Could not remove images, please try again."
      );
    }
  };

  const formatTimeString = (time) => {
    const opt = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      //hour12: true,
      hour: "numeric",
      minute: "2-digit",
      hourCycle: "h12",
    };

    const date = new Date(`${time} UTC`).toLocaleString(undefined, opt);

    const dateBackup = new Date(time).toLocaleString(undefined, opt);

    const noOptionDate = new Date(`${time} UTC`).toLocaleString();

    const noOptionDateBackup = new Date(time).toLocaleString();

    const defaultDate = new Date(`${time} UTC`).toLocaleString("en-US", opt);

    const defaultDateBackup = new Date(time).toLocaleString("en-US", opt);

    if (date !== "Invalid Date") return date;

    if (dateBackup !== "Invalid Date") return dateBackup;

    if (noOptionDate !== "Invalid Date") return noOptionDate;

    if (noOptionDateBackup !== "Invalid Date") return noOptionDateBackup;

    if (defaultDate !== "Invalid Date") return defaultDate;

    if (defaultDateBackup !== "Invalid Date") return defaultDateBackup;

    return time;
  };

  const col = [
    {
      name: "key",
      options: {
        display: "excluded",
        viewColumns: false,
        filter: false,
        sort: false,
        download: false,
        searchable: !!firebaseUser.admin,
      },
    },
    {
      name: "title",
      label: "Recipe name",
      options: {
        display: columns && columns.title === false ? "false" : "true",
        customFilterListOptions: {
          render: (value) => `Title: ${value}`,
        },
        filterList: filterList ? filterList[1] : null,
        searchable: true,
      },
    },
    {
      name: "name",
      label: "Submitted by",
      options: {
        display:
          columns && columns.name === false
            ? "false"
            : columns && columns.name === true
            ? "true"
            : defaultBook.formSettings.showFields.indexOf("name") === -1
            ? "false"
            : "true",
        customFilterListOptions: {
          render: (value) => `Name: ${value}`,
        },
        filterList: filterList ? filterList[2] : null,
        searchable: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        display:
          columns && columns.name === false
            ? "false"
            : columns && columns.name === true
            ? "true"
            : defaultBook.formSettings.showFields.indexOf("name") === -1
            ? "false"
            : "true",
        customFilterListOptions: {
          render: (value) => `Email: ${value}`,
        },
        filterList: filterList ? filterList[3] : null,
        searchable: true,
      },
    },
    {
      name: "dateSubmitted",
      label: "Date Submitted",
      options: {
        display: columns && columns.dateSubmitted === false ? "false" : "true",
        customFilterListOptions: {
          render: (value) => `Date Submitted: ${value}`,
        },
        //sortDirection: "asc",
        filterList: filterList ? filterList[4] : null,
        customBodyRenderLite: (dataIndex) =>
          formatTimeString(recipeData[dataIndex].dateSubmitted),
        searchable: true,
      },
    },
    {
      name: "lastEdited",
      label: "Last Edited",
      options: {
        display: columns && columns.lastEdited === false ? "false" : "true",
        customFilterListOptions: {
          render: (value) => `Last Edited: ${value ? value : "N/A"}`,
        },
        filterList: filterList ? filterList[5] : null,
        customBodyRenderLite: (dataIndex) => {
          const lastEdited = recipeData[dataIndex].lastEdited;
          return lastEdited ? (
            formatTimeString(lastEdited)
          ) : (
            <span style={{ color: "rgba(0, 0, 0, 0.26)" }}>N/A</span>
          );
        },
        searchable: true,
      },
    },
    {
      name: "chapter",
      label: "Chapter",
      options: {
        searchable: true,
        display: columns && columns.chapter === false ? "false" : "true",
        customFilterListOptions: {
          render: (value) => `Chapter: ${value}`,
        },
        filterList: filterList
          ? filterList[6]
          : chapterView
          ? [chapterView]
          : null,
        customBodyRender: (value, tableMeta, updateValue) => {
          const dataIndex = tableMeta.rowIndex;
          const row = recipeData[dataIndex];

          return !row.import ? (
            <FormControl disabled={value === "load" || updateLoad}>
              <Select
                value={row.chapter}
                onChange={(e) => {
                  if (deleteView) setDeleteView(false);

                  const oldVal = row.chapter;
                  const newVal = e.target.value;
                  row.chapter = newVal;

                  const chapterId = chapterNameToId[newVal];

                  if (chapterId) {
                    updateValue("load");

                    const recipeDoc = defaultBook.bookDoc
                      .collection("recipes")
                      .doc(row.key);

                    recipeDoc
                      .update({ "recipe.chapter": chapterId })
                      .then(() => {
                        setTimeout(
                          () =>
                            recipesRef
                              .child(row.key)
                              .update({ chapter: chapterId }, (err) => {
                                if (err) {
                                  console.log("realtime");
                                  console.error(err);
                                  errorAlert(
                                    "Unable to change chapter.",
                                    `Error: ${err.message}`
                                  );

                                  updateValue(oldVal);
                                  recipeDoc.update({
                                    "recipe.chapter": chapterNameToId[oldVal],
                                  });
                                } else {
                                  updateValue(newVal);
                                }
                              }),
                          1000
                        );
                      })
                      .catch((err) => {
                        console.log("firestore");
                        console.error(err);
                        row.chapter = oldVal;
                        errorAlert(
                          "Unable to change chapter.",
                          `Error: ${err.message}`
                        );
                        updateValue(oldVal);
                      });
                  } else {
                    errorAlert(
                      "Unable to change chapter.",
                      `Could not fetch chapter ID for ${newVal}, please refresh the page and try again. Contact support@createcookbooks.com for more help`
                    );
                    row.chapter = oldVal;
                    updateValue(oldVal);
                  }
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "0.875rem" }}
              >
                {defaultBook.chaptersArray.map((id, index) => (
                  <MenuItem key={index} value={defaultBook.chapters[id].name}>
                    {defaultBook.chapters[id].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            row.chapter || row.original_chapter
          );
        },
      },
    },
    {
      name: "edit",
      label: "Edit",
      options: {
        searchable: false,
        display: columns && columns.edit === false ? "false" : "true",
        filter: false,
        sort: false,
        empty: true,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          const row = recipeData[dataIndex];
          const imported = row.import === "imported";

          return (
            <Tooltip
              title={
                row.importedSlide
                  ? "Open the imported slide in your book."
                  : imported
                  ? "This recipe has been imported into your book but does not have a link to the slide."
                  : isImporting
                  ? "Import is in progress."
                  : row.import
                  ? "Uncheck import to edit"
                  : "Open the recipe to edit/proofread."
              }
            >
              <div>
                <IconButton
                  disabled={
                    imported && row.importedSlide
                      ? !["owner", "designer"].includes(
                          defaultBook.userBookRole
                        )
                      : imported ||
                        isImporting ||
                        row.import ||
                        updateLoad ||
                        row.images === "loading"
                  }
                  onClick={() => {
                    if (deleteView) setDeleteView(false);
                    imported && row.importedSlide
                      ? openWindowInside("Recipe", row.importedSlide)
                      : setIframe({
                          key: row.key,
                          title: row.title,
                          author: row.name,
                        });
                  }}
                >
                  <LaunchIcon />
                </IconButton>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "images",
      label: "Images",
      options: {
        searchable: true,
        display: columns && columns.images === false ? "false" : "true",
        filter: true,
        sort: false,
        empty: true,
        print: true,
        //download: false,
        customFilterListOptions: {
          //render: (value) => (value === undefined ? "All" : value),
          update: (filterList, filterPos, index) => {
            return filterList;
          },
        },
        filterOptions: {
          names: ["Images", "No Images"],
          logic: (val, filters) => {
            if (filters[0] === "Images") {
              return !val;
            }
            if (filters[0] === "No Images") {
              return !(val === false);
            }

            return false;
          },
        },
        customBodyRenderLite: (dataIndex) => {
          const row = recipeData[dataIndex];

          return (
            <Tooltip
              title={
                row.images === "loading"
                  ? "Images are uploading, please wait."
                  : row.images
                  ? "Open images"
                  : "No images"
              }
            >
              {row.images === "loading" ? (
                <CircularProgress size={25} disableShrink={true} />
              ) : (
                <div>
                  <IconButton
                    disabled={row.images === "loading" || !row.images}
                    onClick={() => {
                      if (
                        imageLightbox &&
                        imageLightbox.index === dataIndex &&
                        imageLightbox.images.length === row.images.length
                      ) {
                        setOpenLightbox(true);
                        return;
                      }

                      setImageLightbox({
                        images: row.images.map((image) => {
                          return {
                            ...image,
                            description: image.caption,
                            downloadUrl: image.src,
                            downloadFilename: image.caption,
                          };
                        }),
                        index: dataIndex,
                      });
                    }}
                  >
                    {row.images ? (
                      <ImageIcon style={{ color: "#00acc1" }} />
                    ) : (
                      <ImageIcon />
                    )}
                  </IconButton>
                </div>
              )}
            </Tooltip>
          );
        },
      },
    },
    {
      name: "import",
      label: "Ready to import",
      options: {
        search: false,
        display: columns && columns.import === false ? "false" : "true",
        filter: true,
        sort: false,
        empty: true,
        print: false,
        //download: false,
        customFilterListOptions: {
          //render: (value) => (value === undefined ? "All" : value),
          update: (filterList, filterPos, index) => {
            console.log("update");
            console.log(filterList, filterPos, index);

            return filterList;
          },
        },
        filterType: "dropdown",
        filterList: filterList
          ? filterList[9]
          : !chapterView &&
            !(
              recipeData &&
              recipeData.every((recipe) => recipe.import === "imported")
            )
          ? ["Not Imported"]
          : null,
        filterOptions: {
          names: ["Imported", "Not Imported", "Ready to Import"],
          logic: (val, filters) => {
            if (val === "load") return false;
            if (filters[0] === "To Import") {
              return val !== true;
            }
            if (filters[0] === "Imported") {
              return val !== "imported";
            }
            if (filters[0] === "Not Imported") {
              return val === "imported";
            }
            return false;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const dataIndex = tableMeta.rowIndex;
          const row = recipeData[dataIndex];

          const imported = row.import === "imported";

          return (
            <Tooltip
              title={
                isImporting
                  ? "Import is in progress."
                  : imported
                  ? "Recipe has been imported."
                  : "Switch recipes to YES when it is ready to import. Click the import button above to start importing recipes into your cookbook."
              }
            >
              <FormControlLabel
                label={
                  imported ? (
                    <CheckIcon />
                  ) : value === "load" ? (
                    <CircularProgress size={25} disableShrink={true} />
                  ) : value ? (
                    "Yes"
                  ) : (
                    "No"
                  )
                }
                value={imported || value === true ? "Yes" : "No"}
                control={
                  <Switch
                    color="primary"
                    disabled={
                      !firebaseUser.admin &&
                      (defaultBook.userBookRole === "contributor" ||
                        row.images === "loading" ||
                        value === "load" ||
                        isImporting ||
                        updateLoad ||
                        imported ||
                        tableMeta.rowData[6] === "load")
                    }
                    checked={imported || !!value || false}
                    value={value ? "Yes" : "No"}
                  />
                }
                onChange={(event) => {
                  if (deleteView) setDeleteView(false);

                  const newVal = event.target.value === "Yes" ? false : true;
                  updateValue("load");

                  recipesRef
                    .child(row.key)
                    .update({ import: newVal }, (err) => {
                      if (err) {
                        errorAlert(
                          "Unable to add to import.",
                          `Error: ${err.message}`
                        );
                        updateValue(row.import);
                      } else {
                        updateValue(newVal);
                      }
                    });
                }}
              />
            </Tooltip>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: deleteView ? "multiple" : "none",
    searchPlaceholder: "Search...",
    responsive: "simple",
    textLabels: {
      selectedRows: {
        text: "Recipe(s) selected",
      },
    },
    downloadOptions: {
      filename: `${defaultBook.title} - Recipe Library.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisaplayedRowsOnly: false,
      },
    },
    selectableRowsHeader: false,
    isRowSelectable: (dataIndex) =>
      !(isImporting || recipeData[dataIndex].import),
    customToolbar: () => (
      <CustomToolbar
        toggleDelete={toggleDelete}
        deleteView={deleteView}
        addRecipe={() => history.push("/recipes/submit")}
      />
    ),
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;

      currentRow.forEach((col, index) => {
        const currentCol = columns[index];

        if (col && currentCol.searchable) {
          if (
            currentCol.name === "images" &&
            col.some(
              (img) =>
                img.caption &&
                img.caption
                  .toString()
                  .toLowerCase()
                  .indexOf(searchQuery.toLowerCase()) >= 0
            )
          ) {
            isFound = true;
            return;
          } else if (
            (currentCol.name === "dateSubmitted" ||
              currentCol.name === "lastEdited") &&
            formatTimeString(col)
              .toString()
              .toLowerCase()
              .indexOf(searchQuery.toLowerCase()) >= 0
          ) {
            isFound = true;
            return;
          } else if (
            col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
          ) {
            isFound = true;
            return;
          }
        }
      });

      return isFound;
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const columnIndex = Object.fromEntries(
        columns.map((col, index) => [col.name, index])
      );

      console.log(data);

      warningAlert(
        "Downloading now...",
        "If you need more data exported please contact support@createcookbooks.com",
        () => hideAlert(),
        { hideCancel: true }
      );
      return (
        buildHead(columns) +
        buildBody(
          data.map((row) => {
            const rowData = row.data;
            if (rowData[columnIndex.images]) {
              rowData[columnIndex.images] = rowData[columnIndex.images].length;
            } else {
              rowData[columnIndex.images] = 0;
            }

            if (rowData[columnIndex.import] === true) {
              rowData[columnIndex.import] = "Yes";
            } else if (rowData[columnIndex.import] === false) {
              rowData[columnIndex.import] = "No";
            } else if (rowData[columnIndex.import] === "imported") {
              rowData[columnIndex.import] = "Imported";
            }

            return { ...row, data: rowData };
          })
        )
      );
    },
    onColumnViewChange: (changedColumn, action) => {
      const key = `columns.${changedColumn}`;
      userDoc.update({
        [key]: action === "add",
      });
    },
    onRowsDelete: (rowsDeleted) => {
      toggleDelete();
      deleteWarningAndConfirmation(rowsDeleted);
    },
    onFilterChange: (changedColumn, filterList, type) =>
      setFilterList(filterList),
  };

  const deleteWarningAndConfirmation = (rows) =>
    warningAlert(
      "Are you sure you want to delete?",
      "Deleting recipes are permanent.",
      () => deleteRows(rows),
      {
        confirmText: "Yes, Delete!",
      }
    );

  const importCount = recipeData
    ? recipeData.filter((recipe) => recipe.import === true).length
    : 0;

  return (
    <div>
      <Lightbox
        plugins={[Captions, Counter, Download, Fullscreen, Thumbnails, Zoom]}
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        slides={imageLightbox?.images || []}
        captions={{ descriptionTextAlign: "center" }}
        carousel={{ finite: true }}
        thumbnails={{ showToggle: true, ref: thumbnailsRef }}
        on={{
          entering: () => thumbnailsRef.current?.hide(),
          view: ({ index }) => setCurrentSlideIndex(index),
        }}
        render={{
          buttonPrev:
            imageLightbox?.images.length <= 1 ? () => null : undefined,
          buttonNext:
            imageLightbox?.images.length <= 1 ? () => null : undefined,
        }}
        toolbar={{
          buttons: [
            false ? (
              <button
                type="button"
                className="yarl__button"
                key={"delete_button"}
                onClick={deleteImageWarning}
              >
                <DeleteIcon className="p-1 yarl__icon" />
              </button>
            ) : null,
            "close",
          ],
        }}
      />
      {iframe ? (
        <EditPaperform
          {...props}
          recipeKey={iframe.key}
          title={`Editing - ${iframe.title} ${
            iframe.author ? `by ${iframe.author}` : ""
          }`}
          onClose={() => {
            setIframe(null);
          }}
        />
      ) : null}
      <Heading
        textAlign="center"
        title={
          defaultBook.userBookRole === "contributor"
            ? "View Submitted Recipes"
            : "Recipe library"
        }
        category={
          <a
            href="javascript:void(0)"
            onClick={() =>
              window.Beacon("article", "6337c96d9f7c1931ee00594f", {
                type: "sidebar",
              })
            }
          >
            Search, filter, edit, delete and flag recipes for import.
          </a>
        }
      />
      {!loading ? (
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <Tooltip title="Select recipes you wish to import below and click here to begin importing recipes into your cookbook.">
            <div style={{ margin: "0 auto", width: "fit-content" }}>
              <Button
                size="lg"
                color="info"
                onClick={() =>
                  history.push(
                    defaultBook.hasTemplate
                      ? "/recipes/import"
                      : "/cookbook/template"
                  )
                }
                disabled={
                  importCount === 0 || defaultBook.userBookRole !== "owner"
                }
              >
                Import {importCount > 0 ? importCount + " " : ""}
                recipe{importCount !== 1 ? "s" : ""}
              </Button>
            </div>
          </Tooltip>
        </div>
      ) : null}
      {loading ? (
        <CustomLinearProgress color="info" />
      ) : (
        <div style={{ paddingBottom: "50px" }}>
          <MUIDataTable
            title={`recipes - ${recipeData.length}`}
            data={recipeData}
            columns={col}
            options={options}
            components={{
              icons: {
                FilterIcon,
              },
              TableFilterList: CustomFilterList,
            }}
          />
        </div>
      )}
    </div>
  );
}
