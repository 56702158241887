import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Menu from "@material-ui/icons/Menu";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";

import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

import logo from "assets/img/logo/logo_whitetrans_x1.png";
import logoSmall from "assets/img/logo/logo_icon_whitetrans_x1.png";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { currentUser, firebaseUser, defaultBook, signOutUser } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  var list = (
    <List className={classes.list}>
      {firebaseUser && firebaseUser.defaultBook && defaultBook ? (
        <ListItem className={classes.listItem}>
          <NavLink
            to={"/dashboard"}
            className={cx(classes.navLink, {
              [classes.navLinkActive]: activeRoute("/dashboard"),
            })}
          >
            <ExitToAppIcon className={classes.listItemIcon} />
            <ListItemText
              primary={"Return to cookbook"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      ) : null}
      {currentUser && firebaseUser ? (
        <>
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/books"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("/books"),
              })}
            >
              <CollectionsBookmarkIcon className={classes.listItemIcon} />
              <ListItemText
                primary={"My books"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/packages"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("/packages"),
              })}
            >
              <CreditCardIcon className={classes.listItemIcon} />
              <ListItemText
                primary={"Packages"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/my-account"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("/my-account"),
              })}
            >
              <AccountBoxIcon className={classes.listItemIcon} />
              <ListItemText
                primary={"My Account"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        </>
      ) : null}

      {currentUser && !firebaseUser ? (
        <ListItem className={classes.listItem}>
          <NavLink
            to={"/packages"}
            className={cx(classes.navLink, {
              [classes.navLinkActive]: activeRoute("/packages"),
            })}
          >
            <Fingerprint className={classes.listItemIcon} />
            <ListItemText
              primary={"Select a package"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      ) : null}

      {currentUser ? (
        <ListItem className={classes.listItem}>
          <a
            href="#signout"
            onClick={signOutUser}
            className={cx(classes.navLink, {
              [classes.navLinkActive]: false,
            })}
          >
            <ListItemText
              primary={"Sign Out"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </a>
        </ListItem>
      ) : (
        <>
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/register"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("/register"),
              })}
            >
              <PersonAdd className={classes.listItemIcon} />
              <ListItemText
                primary={"Register"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/login"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("/login"),
              })}
            >
              <Fingerprint className={classes.listItemIcon} />
              <ListItemText
                primary={"Login"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        </>
      )}
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Button
              href="https://www.createcookbooks.com"
              target="_blank"
              className={classes.title}
              color="transparent"
            >
              <img
                src={logo}
                style={{ height: "48px" }}
                alt="Create Cookbooks Logo"
              />
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Button href="#" className={classes.title} color="transparent">
              <img
                src={logoSmall}
                style={{ height: "48px" }}
                alt="Create Cookbooks Logo (Small)"
              />
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
