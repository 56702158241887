import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import AuthIframe from "react-auth-iframe";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textTransform: "unset",
  },
  titleAbsoluteMiddle: {
    flex: 1,
    textTransform: "unset",
    position: "absolute",
    width: "100%",
    textAlign: "center",
    marginLeft: "-24px",
  },
  iframeContainer: {
    height: "100%",
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

export default function AuthIframePopup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [token, setToken] = React.useState();

  const [innerWindow, setInnerWindow] = React.useState(null);
  const [currentHash, setCurrentHash] = React.useState(null);

  const [pageLeft, setPageLeft] = React.useState(null);
  const [pageRight, setPageRight] = React.useState(null);

  const {
    title,
    url,
    closeOnSecondLoad,
    onClose,
    getToken,
    insidePagesIndex,
  } = props;

  useEffect(() => {
    setOpen(true);

    getToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    if (innerWindow) {
      innerWindow.addEventListener("hashchange", () => {
        setCurrentHash(innerWindow.location.hash);
      });
    }
  }, [innerWindow]);

  useEffect(() => {
    if (currentHash) {
      const hashArr = currentHash.split("/");

      const currentPageNumber = parseInt(hashArr[1]);

      const addIfEven = currentPageNumber % 2 === 0 ? 1 : 0;

      const currentPageLeft =
        currentPageNumber - insidePagesIndex.start - 1 + addIfEven;
      const currentPageRight =
        currentPageNumber - insidePagesIndex.start + addIfEven;

      if (currentPageLeft - 1 > 0 && currentPageLeft < insidePagesIndex.end) {
        setPageLeft(currentPageLeft);
      } else if (pageLeft) {
        setPageLeft(null);
      }

      if (currentPageRight > 0 && currentPageRight < insidePagesIndex.end) {
        setPageRight(currentPageRight);
      } else if (pageRight) {
        setPageRight(null);
      }
    }
  }, [currentHash]);

  const handleClose = () => {
    setOpen(false);
  };

  const onLoad = (e) => {
    setInnerWindow(e.target.contentWindow);
    setCurrentHash(e.target.contentWindow.location.hash);

    if (closeOnSecondLoad && loaded) {
      return handleClose();
    }

    if (!loaded) setLoaded(true);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      onExited={onClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {pageLeft || pageRight ? (
            <Typography variant="h6" className={classes.titleAbsoluteMiddle}>
              Page {pageLeft}
              {pageLeft && pageRight ? " & " : ""}
              {pageRight}
            </Typography>
          ) : null}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!loaded && !token ? <CustomLinearProgress /> : null}
      <div className={classes.iframeContainer}>
        {token ? (
          <AuthIframe
            src={url}
            token={token}
            type="text/html"
            onLoad={onLoad}
            className={classes.iframe}
            title={title}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          />
        ) : null}
      </div>
    </Dialog>
  );
}
