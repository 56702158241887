import React, { useEffect, useState } from "react";

import Paperform from "components/Paperform.js";

import { app } from "base.js";

export default function PhoneAppt(props) {
  const { currentUser, firebaseUser } = props;

  const [prefill, setPrefill] = useState(null);

  const hasGoogle =
    currentUser.providerData.filter(
      (provider) => provider.providerId === "google.com"
    ).length > 0;

  const googleEmail = hasGoogle
    ? currentUser.providerData.filter(
        (provider) => provider.providerId === "google.com"
      )[0].email
    : null;

  useEffect(() => {
    const profileDoc = app
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("public")
      .doc("profile");

    profileDoc.get().then((doc) => {
      let prefill = `name=${currentUser.displayName}&uid=${
        currentUser.uid
      }&email=${currentUser.email}&role=${firebaseUser.role}&phone=${
        currentUser.phoneNumber
          ? encodeURIComponent(currentUser.phoneNumber)
          : ""
      }${hasGoogle ? "&googleEmail=" + googleEmail : ""}`;
      if (doc.exists) {
        const data = doc.data();
        prefill += "&country=" + data.country;
      }

      setPrefill(prefill);
    });
  }, []);

  return (
    <div>
      <Paperform paperFormID="cc-phone" prefill={prefill} hasPrefill={true} />
    </div>
  );
}
