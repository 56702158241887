import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";

import Heading from "components/Heading/Heading.js";

import GridContainer from "components/Grid/GridContainer.js";

import GridItem from "components/Grid/GridItem.js";

import Paperform from "components/Paperform.js";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import ButtonMui from "@material-ui/core/Button";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import { baseAPI } from "base.js";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  success: {
    "& > label": {
      color: "green !important",
    },
    "& > p": {
      color: "green !important",
    },
    "& > div": {
      "&:before": {
        borderBottom: "2px solid green !important",
      },
      "&:after": {
        borderBottomColor: "green !important",
      },
    },
  },
});

export default function PublicForm(props) {
  const classes = useStyles();

  const {
    publicFormData,
    warningAlert,
    successAlert,
    errorAlert,
    loadingAlert,
    hideAlert,
    currentUser,
    bookId,
  } = props;

  const [slug, setSlug] = useState("");
  const [slugError, setSlugError] = useState(false);

  const [slugSuccess, setSlugSuccess] = useState(false);
  const [slugHelperText, setSlugHelperText] = useState("");

  const [slugChecked, setSlugChecked] = useState(false);
  const [slugLoading, setSlugLoading] = useState(false);

  const [changeSlug, setChangeSlug] = useState(false);

  const [handlePublicForm, setHandlePublicForm] = useState(false);
  const [publicFormPrefill, setPublicFormPrefill] = useState(null);

  const [loading, setLoading] = useState(false);

  const [openCopy, setOpenCopy] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedText, setCopiedText] = useState(false);

  const copyURL = () => {
    const tempInput = document.createElement("input");
    tempInput.value = `https://cc.recipes/${publicFormData.slug}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopiedText(true);
    setCopied(true);
  };

  useEffect(() => {
    let timeout;
    if (copied) {
      timeout = setTimeout(() => setCopied(false), 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  useEffect(() => {
    let timeout;
    if (copiedText) {
      setTimeout(() => setCopiedText(false), 3250);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [copiedText]);

  const resetSlug = () => {
    setSlugSuccess(false);
    setSlugError(false);
    setSlugHelperText("");
    setSlugChecked(false);
    setSlugLoading(false);
    setSlug("");
    setChangeSlug(false);
  };

  const resetPublicForm = () => {
    setHandlePublicForm(false);
    setPublicFormPrefill(null);
  };

  const handleSlug = (e) => {
    const val = e.target.value.toLowerCase();

    if (val === "" || val.match(/^[a-z]+-?([a-z0-9]-?)*$/)) {
      setSlug(val);

      setSlugChecked(false);
      setSlugError(false);
      setSlugSuccess(false);
      setSlugHelperText("");
    }
  };

  const checkSlug = () => {
    if (!slug.match(/^[a-z]+(-[a-z0-9]+)*$/)) {
      if (slug[slug.length - 1] === "-") {
        setSlug(slug.slice(0, -1));
      } else {
        setSlugError(true);
        setSlugHelperText("Your URL is invalid.");
        return;
      }
    }

    setSlugLoading(true);

    currentUser.getIdToken(true).then((token) => {
      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);

      fetch(`${baseAPI}/public/slug-exists/${slug}`, {
        headers,
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((result) => {
          if (result) {
            if (slug === result.slug && result.available) {
              setSlugError(false);
              setSlugSuccess(true);
              setSlugChecked(true);
              setSlugHelperText(
                `The URL cc.recipes/${result.slug} is available`
              );
            } else {
              setSlugSuccess(false);
              setSlugError(true);
              setSlugHelperText(
                `The URL cc.recipes/${result.slug} has been taken`
              );
            }
          }

          setSlugLoading(false);
        })
        .catch((err) => {
          setSlugError(true);
          setSlugHelperText("Something went wrong. Please try again.");
          console.error(err);
        });
    });
  };

  const handleNewSlug = () => {
    setSlugSuccess(false);
    setSlugHelperText("");

    if (!slug.match(/^[a-z]+(-[a-z0-9]+)*$/)) {
      if (slug[slug.length - 1] === "-") {
        setSlug(slug.slice(0, -1));
      } else {
        setSlugError(true);
        setSlugHelperText("Your URL is invalid.");
        return;
      }
    }

    if (!publicFormData) {
      setHandlePublicForm(true);
      return;
    }

    setSlugLoading(true);

    currentUser.getIdToken(true).then((token) => {
      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);

      fetch(`${baseAPI}/public/change-slug/${publicFormData.slug}/${slug}`, {
        headers,
      })
        .then((response) => {
          if (response.status === 200) {
            if (!publicFormData) {
              setHandlePublicForm(true);
            } else {
              setSlugError(false);
              setSlugSuccess(true);
              setSlugHelperText("Your URL was successfully changed!");
            }
          } else {
            setSlugSuccess(false);
            setSlugError(true);
            setSlugHelperText("Something went wrong. Please try again.");
          }

          setSlugLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setSlugSuccess(false);
          setSlugError(true);
          setSlugHelperText("Something went wrong. Please try again.");

          setSlugLoading(false);
        });
    });
  };

  const deletePublicForm = () => {
    loadingAlert();

    currentUser.getIdToken(true).then((token) => {
      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);

      fetch(`${baseAPI}/public/delete-public-form/${publicFormData.slug}`, {
        headers,
      }).then((response) => {
        if (response.status === 200) {
          successAlert("Deleted!", "Public Form successfully deleted!");
        } else {
          errorAlert(
            "Something went wrong.",
            "We could not delete your public form. Please try again."
          );
        }
      });
    });
  };

  const handleSubmission = async (submission) => {
    try {
      loadingAlert();
      setLoading(true);

      const token = await currentUser.getIdToken(true);

      const headers = new Headers();

      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-type", "application/json");

      const type = submission.data.find((field) => field.custom_key === "type")
        .value;

      const response = await fetch(`${baseAPI}/public/${type}-public-form`, {
        method: "POST",
        headers,
        body: JSON.stringify(submission),
      });

      if (response.status === 200) {
        setLoading(false);
        hideAlert();
      } else {
        throw new Error("Response status: " + response.status);
      }
    } catch (e) {
      setPublicFormPrefill(null);
      setLoading(false);
      errorAlert(
        "There was a problem submitting...",
        "We have saved your recipe. Please try submitting again. Error ID: " +
          submission.submission_id
      );
      console.error("Something went wrong with the submission");
      console.error(e);
      resetSubmission(submission.data);
    }
  };

  const resetSubmission = (values) => {
    setPublicFormPrefill(
      `${values
        .filter(
          ({ custom_key, type, value }) =>
            !["calculation", "image", "date"].includes(type) && value !== null
        )
        .map(({ custom_key, value }) => {
          return `${custom_key}=${encodeURIComponent(value)}`;
        })
        .join("&")}`
    );
  };

  useEffect(() => {
    if (handlePublicForm) {
      if (publicFormData) {
        setPublicFormPrefill(
          `slug=${publicFormData.slug}&type=update${Object.entries(
            publicFormData
          )
            .filter(([key]) => !["backgroundUrl"].includes(key))
            .map(([key, value]) => {
              if (key === "bookId") {
                return `&bookID=${value}`;
              }

              if (key === "contactCC") {
                return value
                  .split(",")
                  .map(
                    (email, index) =>
                      `&secondary${index}=${encodeURIComponent(email)}`
                  );
              }

              if (["open", "allowEdit", "contact"].includes(key)) {
                return `&${key}=${value ? "Yes" : "No"}`;
              }

              if (key === "colour") {
                return `&${key}=${value.slice(1)}`;
              }
              return `&${key}=${encodeURIComponent(value)}`;
            })
            .join("&")}`
        );
      } else {
        setPublicFormPrefill(
          `slug=${slug}&type=create&bookID=${bookId}&open=Yes&contactEmail=${
            currentUser.email || ""
          }&contactPhone=${currentUser.phoneNumber || ""}`
        );
      }
    } else {
      setPublicFormPrefill(null);
    }
  }, [handlePublicForm]);

  /*useEffect(() => {
    if (publicFormSubmitted) {
      resetPublicForm();
    }
  }, [publicFormData]);*/

  const buttonStyle = {
    width: "170px",
  };

  if (handlePublicForm) {
    return (
      <div style={{ opacity: loading ? 0.1 : 1 }}>
        <Paperform
          paperFormID="cc-public-builder"
          hasPrefill={true}
          prefill={publicFormPrefill}
          onSubmission={handleSubmission}
        />
        <Button
          style={{ marginTop: "20px" }}
          onClick={resetPublicForm}
          disabled={loading}
        >
          Back
        </Button>
      </div>
    );
  }

  if (changeSlug || !publicFormData) {
    return (
      <div>
        <Heading
          title={`${
            changeSlug ? "Change your" : "Choose a"
          } custom public form link`}
          category={
            <span>
              <b>Example:</b> cc.recipes/
              {publicFormData
                ? publicFormData.slug
                : slug ||
                  currentUser.displayName.toLowerCase().split(" ").join("-")}
            </span>
          }
        />

        <GridContainer direction="column">
          <GridItem style={{ marginBottom: "20px" }}>
            <FormControl
              style={{ width: "250px" }}
              variant="filled"
              // fullWidth={true}
              error={slugError}
              margin="dense"
              className={slugSuccess ? classes.success : ""}
            >
              <InputLabel htmlFor="filled-email-adornment">
                cc.recipes/
              </InputLabel>
              <FilledInput
                id="filled-email-adornment"
                type="text"
                value={slug}
                aria-describedby="helper-text"
                onChange={handleSlug}
                onKeyDown={(e) => {
                  if (
                    slugChecked ||
                    slugSuccess ||
                    (publicFormData && publicFormData.slug === slug) ||
                    !slug ||
                    slugLoading ||
                    slugError
                  ) {
                    return;
                  }

                  if (e.key === "Enter") {
                    checkSlug();
                  }
                }}
                disableUnderline={slugLoading}
                endAdornment={
                  <InputAdornment position="end">
                    <ButtonMui
                      type="submit"
                      aria-label="check url"
                      onClick={checkSlug}
                      disabled={
                        slugSuccess ||
                        slugChecked ||
                        (publicFormData && publicFormData.slug === slug) ||
                        !slug ||
                        slugLoading ||
                        slugError
                      }
                    >
                      Check
                    </ButtonMui>
                  </InputAdornment>
                }
              />
              {slugLoading ? (
                <CustomLinearProgress
                  color="info"
                  style={{
                    height: "2px",
                    transform: "scaleX(1)",
                    marginTop: "-2px",
                    marginBottom: "0",
                  }}
                />
              ) : null}
              {slugHelperText ? (
                <FormHelperText id="helper-text">
                  {slugHelperText}
                </FormHelperText>
              ) : null}
            </FormControl>
          </GridItem>

          <GridItem>
            <Button
              color="info"
              disabled={
                !slug ||
                slugLoading ||
                (publicFormData && publicFormData.slug === slug)
              }
              onClick={!slugChecked ? checkSlug : handleNewSlug}
            >
              {!slugChecked && slug
                ? "Check URL is available"
                : changeSlug
                ? "Update Link"
                : "Create Public Form"}
            </Button>
          </GridItem>

          {changeSlug ? (
            <GridItem>
              <Button
                color="danger"
                simple
                disabled={slugLoading}
                onClick={resetSlug}
              >
                Back
              </Button>
            </GridItem>
          ) : null}
        </GridContainer>
      </div>
    );
  }

  if (publicFormData) {
    return (
      <div>
        <Heading
          title={
            <span>
              Share this link:{" "}
              <a
                href={`https://cc.recipes/${publicFormData.slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                cc.recipes/{publicFormData.slug}
              </a>{" "}
              <Tooltip
                open={copied || openCopy}
                onOpen={() => setOpenCopy(true)}
                onClose={() => setOpenCopy(false)}
                title={copiedText ? "Copied!" : "Copy URL"}
                disableHoverListener={copied}
              >
                <IconButton size="small" onClick={copyURL}>
                  <Icon fontSize="small">content_copy</Icon>
                </IconButton>
              </Tooltip>
            </span>
          }
          category="Click link above to view public form"
        />
        <GridContainer justify="center">
          <GridItem>
            <Button
              style={buttonStyle}
              color="info"
              onClick={() => setChangeSlug(true)}
            >
              Change URL
            </Button>
          </GridItem>
          <GridItem>
            <Button
              style={buttonStyle}
              color="info"
              onClick={() => setHandlePublicForm(true)}
            >
              Update Public Form
            </Button>
          </GridItem>

          <GridItem>
            <Button
              style={buttonStyle}
              color="danger"
              onClick={() =>
                warningAlert(
                  "Are you sure?",
                  "Are you sure you want to delete your public form?",
                  deletePublicForm
                )
              }
            >
              Delete Public Form
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return <div>test</div>;
  }
}
