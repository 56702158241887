import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

import Email from "@material-ui/icons/Email";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import LoggedIn from "../../components/Auth/LoggedIn.js";

import GoogleLogin from "components/Auth/GoogleLogin.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import { app } from "base.js";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function Login(props) {
  const { setCurrentUser, currentUser, history, signOutUser } = props;

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);

    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [googleLoad, setGoogleLoad] = useState(false);

  const [email, setEmail] = useState(
    props.location.state && props.location.state.email
      ? props.location.state.email
      : props.params
      ? new URLSearchParams(props.params).get("email")
      : null
  );
  const [emailErr, setEmailErr] = useState(null);

  const [password, setPassword] = useState(null);
  const [passErr, setPassErr] = useState(null);

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (googleLoad) return;
    setLoading(true);

    if (!email || !password) {
      if (!email) setEmailErr("Email address is required.");
      if (!password) setPassErr("Password is required");

      setLoading(false);
      return;
    }

    app
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((providers) => {
        if (providers.length === 1 && providers[0] === "google.com") {
          setEmailErr(
            "Email address is only connected to a Google account. Please use the Google Sign In button above this form to continue."
          );
          setLoading(false);
        } else {
          app
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(({ user }) => {
              setCurrentUser(user, true);
            })
            .catch((err) => {
              setLoading(false);
              if (err.code === "auth/invalid-email") {
                setEmailErr("Email address is invalid.");
              }

              if (err.code === "auth/user-disabled") {
                setEmailErr(
                  "The account tied to this email address has been disabled."
                );
              }

              if (err.code === "auth/user-not-found") {
                setEmailErr(
                  <>
                    No account tied to this email exists.{" "}
                    <Link
                      to={{
                        pathname: "/register",
                        state: {
                          email,
                        },
                      }}
                    >
                      Click here to register an account.
                    </Link>
                  </>
                );
              }

              if (err.code === "auth/wrong-password") {
                setPassErr("Password was incorrect.");
              }
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === "auth/invalid-email") {
          setEmailErr("Email address is invalid.");
        }
      });
  };

  return currentUser && !loading && !googleLoad ? (
    <LoggedIn
      avatar={currentUser.photoURL}
      name={currentUser.displayName}
      continue={() => history.push("/dashboard")}
      signOutUser={signOutUser}
      card={true}
    />
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10}>
          <form onSubmit={onFormSubmit}>
            <Card
              login
              className={classes[cardAnimaton]}
              style={{ width: "100%" }}
            >
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody style={{ marginTop: "10px" }}>
                <GoogleLogin
                  onLoading={setGoogleLoad}
                  setCurrentUser={setCurrentUser}
                />
              </CardBody>
              <CardBody>
                <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                  or
                </Typography>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: (e) => {
                      if (emailErr) setEmailErr(null);
                      setEmail(e.target.value);
                    },
                    disabled: loading || googleLoad,
                    defaultValue: email || "",
                  }}
                  error={!!emailErr}
                  helperText={emailErr}
                />

                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: (e) => {
                      if (passErr) setPassErr(null);
                      setPassword(e.target.value);
                    },
                    disabled: loading || googleLoad,
                  }}
                  error={!!passErr}
                  helperText={passErr}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    color="info"
                    type="submit"
                    disabled={loading || googleLoad}
                  >
                    Log In
                  </Button>
                )}
              </CardFooter>
              <CardFooter className={classes.justifyContentCenter}>
                <Link
                  to={{
                    pathname: "/forgot-password",
                    state: { email },
                  }}
                >
                  Forgot password
                </Link>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
